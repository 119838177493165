import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaTiranteOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import TirantiForm from './TirantiForm';
const TirantiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const mensoleTamponiCampateImpalcatoTableTirantiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable`
  );

  const mensoleTamponiCampateImpalcatoTable = watch(
    `mensoleTamponiCampateImpalcatoTable`
  );
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const mensoleTamponiCampateToCopy = mensoleTamponiCampateImpalcatoTable?.map(
    el => el.tirantiTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsTiranti = item => {
    setValue('tirantiTableValue', [item]);
    setValue('tipologiaTiranteTableValue', item.tipologiaTirante);
  };

  const handleSelectAllClickTiranti = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable`
      );
      setSelected(selectedValue);
      setValue('tirantiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('tirantiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a4_tecnicoRilevatoreTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a4_dataIspezioneTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a4_elementoCriticoStrutturaTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_elementoCriticoSismicaTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaStrutturaTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaSismicaTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_noteGeneraliTiranti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a4_tecnicoRilevatoreTiranti');
    const dataIspezione = getValues('l1t2a4_dataIspezioneTiranti');
    const elementoCriticoStruttura = getValues(
      'l1t2a4_elementoCriticoStrutturaTiranti'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a4_elementoCriticoSismicaTiranti'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a4_condizioneCriticaStrutturaTiranti'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a4_condizioneCriticaSismicaTiranti'
    );
    const noteGenerali = getValues('l1t2a4_noteGeneraliTiranti');
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a4_tecnicoRilevatoreTiranti`, '');
    setValue(`l1t2a4_dataIspezioneTiranti`, null);
    setValue(`l1t2a4_elementoCriticoStrutturaTiranti`, '');
    setValue(`l1t2a4_elementoCriticoSismicaTiranti`, '');
    setValue(`l1t2a4_condizioneCriticaStrutturaTiranti`, '');
    setValue(`l1t2a4_condizioneCriticaSismicaTiranti`, '');
    setValue(`l1t2a4_noteGeneraliTiranti`, '');
  };

  const dataTirantiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaTirante: {
        label: 'Tipologia',
        format: tipologiaTiranteOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaTirante: {
        label: 'Tipologia',
        format: tipologiaTiranteOptions,
      },
    },
  };

  const mensoleTamponiCampateImpalcatoSelezione = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [mensoleTamponiCampateImpalcatoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'tiranti'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoTiranti.drawerTitle}
        drawerText={INFO.impalcatoTiranti.drawerText}
        singleElementTitle={'tirante'}
        tableWatch={mensoleTamponiCampateImpalcatoTableTirantiTableWatch}
        tableWatchName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable`}
        elementForm={
          <TirantiForm
            tirantiFields={
              mensoleTamponiCampateImpalcatoTableTirantiTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            tirantiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].tirantiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a4_tecnicoRilevatoreTiranti'}
            dateName={'l1t2a4_dataIspezioneTiranti'}
            elementoCriticoStrutturaName={
              'l1t2a4_elementoCriticoStrutturaTiranti'
            }
            elementoCriticoSismicaName={'l1t2a4_elementoCriticoSismicaTiranti'}
            condizioneCriticaStrutturaName={
              'l1t2a4_condizioneCriticaStrutturaTiranti'
            }
            condizioneCriticaSismicaName={
              'l1t2a4_condizioneCriticaSismicaTiranti'
            }
            noteGeneraliName={'l1t2a4_noteGeneraliTiranti'}
          />
        }
        tableConfig={dataTirantiConfig}
        editRows={editRowsTiranti}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickTiranti}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaTirante'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
TirantiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default TirantiSection;
