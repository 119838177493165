import { Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line
import useFetch from 'use-http';
import { v4 as uuidv4 } from 'uuid';
import { InfoBox, RHFCheckbox, RHFTextField, TreeViewCustom } from 'components';
import { objectDataTree } from 'components/TreeViewCustom/constant';
import { sortedTreeValues } from 'components/TreeViewCustom/sortedTreeValues';
import { apiBridges, apiExports, apiMedia } from 'constants/api';
import {
  INFO_ASSISTANCE_PATHS,
  PROFILE_AND_SUBSCRIPTION,
} from 'constants/paths';
import {
  SubscriptionStatus,
  SubscriptionStatusMap,
} from 'constants/subscription';
import { UserType, UserTypology, UserTypologyMap } from 'constants/users';
import { useSubscription } from 'hooks';
import { FormProvider } from 'providers';
import { useGlobalSelector } from 'stores';
import { getS3ObjectUrl, handleUpload } from 'utils/upload';
import { demoStructures } from 'utils/utilities';
import {
  ClusterExportType,
  ExportErrorMessageMap,
  ExportErrorMessages,
  ExportOptions,
  ExportResponseStatus,
  LambdaTypeMap,
  selectOptions,
} from './ExportSelect/constants';
import { ExportSelect } from './ExportSelect/ExportSelect';
import { handleDownload } from './ExportSelect/functions';
import {
  BodyContainer,
  BtnContainer,
  ExportMessage,
} from './ExportsPanel.styles';
import useExportCdaFrane from './hooks/useExportCdaFrane';
import useExportCdaIdraulica from './hooks/useExportCdaIdraulica';
import useExportCdaSismica from './hooks/useExportCdaSismica';
import useExportCdaStruttura from './hooks/useExportCdaStruttura';

const ExportsPanel = ({
  exportOverview,
  uploadTempCdAPie,
  structureName,
  pdfName,
  structureInfo,
}) => {
  const { get, post } = useFetch();
  const navigate = useNavigate();
  const { get: exportLambdaUrl, post: postExportLambdaUrl } = useFetch(
    process.env.REACT_APP_EXPORT_LAMBDA_URL
  );
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const id = params.structureId;
  const { showLoader, hideLoader } = useGlobalSelector();
  const { user, subscriptionStatus } = useSubscription();
  const [showMessage, setShowMessage] = useState(true);

  const firstExportDate = structureInfo?.firstExportDate;

  const userTypology = user?.tipologiaUtente;
  const userType = user?.type;
  const isOwner = structureInfo?.owner?.id === user?.id;
  const isParentOwner = structureInfo?.owner?.id === user?.parent?.id;

  const LinkToProfileAndSubscription = () => {
    return (
      <span
        onClick={() => navigate(PROFILE_AND_SUBSCRIPTION)}
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {`Clicca qui`}
      </span>
    );
  };

  const ClusterExportMessage = (
    <Typography variant="h3Bold">
      {`Esportazione avviata con successo. L'esportazione richiederà alcuni minuti, al termine riceverai una notifica via email contenente un link per scaricare il file esportato.
                      Nel corso della prossima ora monitora la casella di posta in arrivo associata al tuo account, qualora non sia arrivata nessuna email verifica anche nella posta indesiderata.`}
    </Typography>
  );

  const FirstExportMessageMap = {
    [UserTypology.UtentePrincipale]: {
      [UserType.Professionista]: {
        [SubscriptionStatus.ND]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 60 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
            Utilizza i Ponti DEMO per avere un esempio di esportazione.
            Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 60 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
              Utilizza i Ponti DEMO per avere un esempio di esportazione.
              Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ),
        },
        [SubscriptionStatus.ATTIVA]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ),
        },
        /* [SubscriptionStatus.SCADUTA]: {
          message: isOwner ? `` : ``,
        }, */
      },
      [UserType.Ente]: {
        [SubscriptionStatus.ND]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 90 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
            Utilizza i Ponti DEMO per avere un esempio di esportazione.
            Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 90 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
Utilizza i Ponti DEMO per avere un esempio di esportazione.
Per non avere limiti e creare il tuo archivio digitale, sottoscrivi. `}
                <LinkToProfileAndSubscription />
              </Typography>
            ),
        },
        [SubscriptionStatus.ATTIVA]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ),
        },
        /* [SubscriptionStatus.SCADUTA]: {
          message: isOwner ? `` : ``,
        }, */
      },
    },
    [UserTypology.CollaboratoreInterno]: {
      [UserType.Professionista]: {
        [SubscriptionStatus.ND]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 60 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
            Utilizza i Ponti DEMO per avere un esempio di esportazione.
            Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 60 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
              Utilizza i Ponti DEMO per avere un esempio di esportazione.
              Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ),
        },
        [SubscriptionStatus.ATTIVA]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ),
        },
        /* [SubscriptionStatus.SCADUTA]: {
          message: isOwner ? `` : ``,
        }, */
      },
      [UserType.Ente]: {
        [SubscriptionStatus.ND]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 90 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
            Utilizza i Ponti DEMO per avere un esempio di esportazione.
            Per non avere limiti e creare il tuo archivio digitale, sottoscrivi.`}
                <LinkToProfileAndSubscription />
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Esporta solo se hai terminato il tuo lavoro. L’accesso gratuito a questo ponte è valido ancora per 90 giorni dall’esportazione, per te e tutti coloro senza sottoscrizione con cui il ponte è condiviso.
Utilizza i Ponti DEMO per avere un esempio di esportazione.
Per non avere limiti e creare il tuo archivio digitale, sottoscrivi. `}
                <LinkToProfileAndSubscription />
              </Typography>
            ),
        },
        [SubscriptionStatus.ATTIVA]: {
          message:
            isOwner || isParentOwner ? (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ) : (
              <Typography variant="h3Bold">
                {`ATTENZIONE! Se procedi con l’esportazione, gli utenti senza sottoscrizione e senza una data di accesso stabilita, con cui è stato condiviso il ponte o una sua attività, avranno ancora 60 giorni per accedere a questa opera.`}
              </Typography>
            ),
        },
        /* [SubscriptionStatus.SCADUTA]: {
          message: isOwner ? `` : ``,
        }, */
      },
    },
  };

  const defaultValues = {
    denominazioneCommittente: '',
    citta: '',
    provincia: '',
    cap: '',
    indirizzo: '',
    saveFile: false,
  };
  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, setValue, getValues, watch } = methods;

  const customCsvMethods = useForm();
  const { setValue: csvSetValue, getValues: csvGetValues } = customCsvMethods;

  const onSubmit = async data => {
    console.log(data);
  };

  const watchSaveFile = watch('saveFile');

  //State
  const [optionSelected, setOptionSelected] = useState(false);
  const [btnExportClicked, setBtnExportClicked] = useState(false);
  const [exportSuccess, setExportSuccess] = useState();
  const [clusterExport, setClusterExport] = useState();
  // eslint-disable-next-line
  const [fileExist, setFileExist] = useState();
  const [fileToDownload, setFileToDownload] = useState();
  const [showForm, setShowForm] = useState(false);

  //State per i file ottenuti dalla chiamata
  // do we have to keep all of them in a separated state?
  const [schedaDifettiFile, setSchedaDifettiFile] = useState();
  const [schedaDifettiMedia, setSchedaDifettiMedia] = useState();
  const [csvL0, setCsvL0] = useState();
  const [tabelleSintesiL1, setTabelleSintesiL1] = useState();
  const [tabelleSintesiL0, setTabelleSintesiL0] = useState();
  const [relazioneTecnicaL0, setRelazioneTecnicaL0] = useState();
  const [schedaIspezioniComplete, setSchedaIspezioniComplete] = useState();
  const [csvL1, setCsvL1] = useState();
  const [csvL2, setCsvL2] = useState();
  const [schedaCensimentoL0, setSchedaCensimentoL0] = useState();
  const [csvAnagraficaBase, setCsvAnagraficaBase] = useState();
  const [csvPontiViadottiStradali, setPontiViadottiStradali] = useState();
  const [docFotoRischioIdraulico, setDocFotoRischioIdraulico] = useState();
  const [docFotoRischioFrane, setDocFotoRischioFrane] = useState();
  const [strutturaSismica, setStrutturaSismica] = useState();
  const [rischioFrane, setRischioFrane] = useState();
  const [rischioIdraulico, setRischioIdraulico] = useState();
  const [relazioneTecnicaCdA, setRelazioneTecnicaCdA] = useState();
  const [relazioneTecnicaCdAFrane, setRelazioneTecnicaCdAFrane] = useState();
  const [, setRelazioneTecnicaCdAStrutturaleSismica] = useState();
  const [, setRelazioneTecnicaCdAIdraulica] = useState();
  const [csvGenerico, setCsvGenerico] = useState();
  const [csvManutenzione, setCsvManutenzione] = useState();
  const [csvAinopL1L2, setCsvAinopL1L2] = useState();

  const intCode = structureInfo?.internalCode;

  useEffect(() => {
    exportSuccess && setShowMessage(false);
  }, [exportSuccess]);

  // hooks for Front-End generated Pdfs
  const { exportCdAFrane, pdfFraneName } = useExportCdaFrane({
    id,
    intCode,
    setExportSuccess,
  });

  const { exportCdAIdraulica, pdfIdraulicaName } = useExportCdaIdraulica({
    id,
    intCode,
    setExportSuccess,
  });

  const { exportCdAStruttura, pdfStrutturaName } = useExportCdaStruttura({
    id,
    intCode,
    setExportSuccess,
  });

  const { exportCdASismica, pdfSismicaName } = useExportCdaSismica({
    id,
    intCode,
    setExportSuccess,
  });

  const isDemoStructure = demoStructures.includes(structureName);

  const errorMessage = message => {
    setExportSuccess(false);
    setErrorExportMessage('');
    return enqueueSnackbar(
      ExportErrorMessageMap[message] || `Errore durante l'esportazione`,
      {
        variant: 'error',
      }
    );
  };

  const ExportEndpointMapper = {
    [ExportOptions.SchedeCensimento]: '/schedaCensimentoL0',
    [ExportOptions.CsvL0]: '/sintesiCensimentiIspezioni/0',
    [ExportOptions.TabelleSintesiL1]: '/tabelle-L1',
    [ExportOptions.TabelleSintesiL0]: '/tabelle-L0',
    [ExportOptions.CsvL1]: '/sintesiCensimentiIspezioni/1',
    [ExportOptions.StrutturaSismica]: '/schedaCensimentoL1/2',
    [ExportOptions.SchedeValutazioneDifetti]: '/schedeDifetti',
    [ExportOptions.RischioFrane]: '/schedaCensimentoL1/3',
    [ExportOptions.DocFotograficaFrane]:
      '/doumentazione-fotografica/rischio-frane',
    [ExportOptions.RischioIdraulico]: '/schedaCensimentoL1/4',
    [ExportOptions.DocFotograficaIdraulica]:
      '/doumentazione-fotografica/rischio-idraulico',
    [ExportOptions.CsvL2]: '/sintesiCensimentiIspezioni/2',
    [ExportOptions.CsvAnagraficaBase]: '/ainop/anagraficheBase',
    [ExportOptions.CsvPontiViadotti]: '/ainop/ponteViadottoStradale',
  };

  const ExportStateSetter = {
    [ExportOptions.SchedeCensimento]: {
      setter: val => setSchedaCensimentoL0(val),
    },
    [ExportOptions.RelazioneTecnicaL0]: {
      setter: val => setRelazioneTecnicaL0(val),
    },
    [ExportOptions.CsvL0]: {
      setter: val => setCsvL0(val),
    },
    [ExportOptions.TabelleSintesiL1]: {
      setter: val => setTabelleSintesiL1(val),
    },
    [ExportOptions.TabelleSintesiL0]: {
      setter: val => setTabelleSintesiL0(val),
    },
    [ExportOptions.SchedeIspezioniComplete]: {
      setter: val => setSchedaIspezioniComplete(val),
    },
    [ExportOptions.CsvL1]: {
      setter: val => setCsvL1(val),
    },
    [ExportOptions.StrutturaSismica]: {
      setter: val => setStrutturaSismica(val),
    },
    [ExportOptions.SchedeValutazioneDifetti]: {
      setter: val => setSchedaDifettiFile(val),
    },
    [ExportOptions.DocFotograficaDifetti]: {
      setter: val => setSchedaDifettiMedia(val),
    },
    [ExportOptions.RischioFrane]: {
      setter: val => setRischioFrane(val),
    },
    [ExportOptions.DocFotograficaFrane]: {
      setter: val => setDocFotoRischioFrane(val),
    },
    [ExportOptions.RischioIdraulico]: {
      setter: val => setRischioIdraulico(val),
    },
    [ExportOptions.DocFotograficaIdraulica]: {
      setter: val => setDocFotoRischioIdraulico(val),
    },
    [ExportOptions.CsvL2]: {
      setter: val => setCsvL2(val),
    },
    [ExportOptions.CsvAnagraficaBase]: {
      setter: val => setCsvAnagraficaBase(val),
    },
    [ExportOptions.CsvPontiViadotti]: {
      setter: val => setPontiViadottiStradali(val),
    },
    [ExportOptions.RelazioneTecnicaCdA]: {
      setter: val => setRelazioneTecnicaCdA(val),
    },
    [ExportOptions.RelazioneTecnicaCdAFrane]: {
      setter: val => setRelazioneTecnicaCdAFrane(val),
    },
    [ExportOptions.RelazioneTecnicaCdAStrutturaleSismica]: {
      setter: val => setRelazioneTecnicaCdAStrutturaleSismica(val),
    },
    [ExportOptions.RelazioneTecnicaCdAIdraulica]: {
      setter: val => setRelazioneTecnicaCdAIdraulica(val),
    },
    [ExportOptions.CsvManutenzione]: {
      setter: val => setCsvManutenzione(val),
    },
    [ExportOptions.CsvAinopL1L2]: {
      setter: val => setCsvAinopL1L2(val),
    },
  };

  const regularDownloads = [
    //ExportOptions.SchedeCensimento,
    // ExportOptions.CsvL0,
    // ExportOptions.CsvL1,
    //ExportOptions.StrutturaSismica,
    // ExportOptions.RischioFrane,
    // ExportOptions.DocFotograficaFrane,
    // ExportOptions.RischioIdraulico,
    // ExportOptions.DocFotograficaIdraulica,
    // ExportOptions.CsvL2,
    // ExportOptions.CsvAnagraficaBase,
    // ExportOptions.CsvPontiViadotti,
    //ExportOptions.TabelleSintesiL1,
    //ExportOptions.TabelleSintesiL0,
  ];

  const relazioniTecnicheDownloads = [
    /* ExportOptions.RelazioneTecnicaL0,
    ExportOptions.RelazioneTecnicaCdAStrutturaleSismica,
    ExportOptions.RelazioneTecnicaCdAFrane,
    ExportOptions.RelazioneTecnicaCdAIdraulica, */
  ];

  const externalLambdaDownloads = [
    ExportOptions.CsvL0,
    ExportOptions.CsvL1,
    ExportOptions.CsvL2,
    ExportOptions.CsvAnagraficaBase,
    ExportOptions.CsvPontiViadotti,
    ExportOptions.SchedeCensimento,
    ExportOptions.TabelleSintesiL0,
    ExportOptions.TabelleSintesiL1,
    ExportOptions.StrutturaSismica,
    ExportOptions.RischioFrane,
    ExportOptions.DocFotograficaFrane,
    ExportOptions.RischioIdraulico,
    ExportOptions.DocFotograficaIdraulica,
    ExportOptions.RelazioneTecnicaCdA,
    ExportOptions.SchedeIspezioniComplete,
    ExportOptions.DocFotograficaDifetti,
    ExportOptions.SchedeValutazioneDifetti,
    ExportOptions.RelazioneTecnicaL0,
    ExportOptions.RelazioneTecnicaCdAStrutturaleSismica,
    ExportOptions.RelazioneTecnicaCdAFrane,
    ExportOptions.RelazioneTecnicaCdAIdraulica,
    ExportOptions.CsvManutenzione,
    ExportOptions.CsvAinopL1L2,
  ];

  const externalLambdaDownloadsWithUploadCDAGraph = [
    ExportOptions.RelazioneTecnicaCdA,
    ExportOptions.RelazioneTecnicaL0,
    ExportOptions.RelazioneTecnicaCdAStrutturaleSismica,
    ExportOptions.RelazioneTecnicaCdAFrane,
    ExportOptions.RelazioneTecnicaCdAIdraulica,
  ];

  const generatePdfBeforeExports = [
    ExportOptions.SintesiCdASismica,
    ExportOptions.SintesiCdAFrane,
    ExportOptions.SintesiCdAIdraulica,
    ExportOptions.SintesiCdAStruttura,
    ExportOptions.SintesiCdAComplessiva,
  ];

  const relazioniTecnicheCdaTypeMap = {
    [ExportOptions.RelazioneTecnicaCdA]: 1,
    [ExportOptions.RelazioneTecnicaL0]: 2,
    [ExportOptions.RelazioneTecnicaCdAStrutturaleSismica]: 3,
    [ExportOptions.RelazioneTecnicaCdAFrane]: 4,
    [ExportOptions.RelazioneTecnicaCdAIdraulica]: 5,
  };

  const generatedPdfBeforeMapper = {
    [ExportOptions.SintesiCdASismica]: {
      uploadPath: `bridges/${id}/exports/sintesi-cda-complessiva`,
      pdfDataFunction: formData => exportCdASismica(formData),
      fileName: pdfSismicaName,
    },
    [ExportOptions.SintesiCdAFrane]: {
      uploadPath: `bridges/${id}/exports/sintesi-cda-frane`,
      pdfDataFunction: formData => exportCdAFrane(formData),
      fileName: pdfFraneName,
    },
    [ExportOptions.SintesiCdAIdraulica]: {
      uploadPath: `bridges/${id}/exports/sintesi-cda-idraulica`,
      pdfDataFunction: formData => exportCdAIdraulica(formData),
      fileName: pdfIdraulicaName,
    },
    [ExportOptions.SintesiCdAStruttura]: {
      uploadPath: `bridges/${id}/exports/sintesi-cda-complessiva`,
      pdfDataFunction: formData => exportCdAStruttura(formData),
      fileName: pdfStrutturaName,
    },
    // for some reason this function and fileName comes as a Prop,
    // should learn about it
    [ExportOptions.SintesiCdAComplessiva]: {
      uploadPath: `bridges/${id}/exports/sintesi-cda-complessiva`,
      pdfDataFunction: formData => exportOverview(formData),
      fileName: pdfName,
    },
  };

  const [errorExportMessage, setErrorExportMessage] = useState();

  const getFileToExport = async fileToDownload => {
    console.log(fileToDownload, 'fileToDownload');
    // case of normal exports
    if (regularDownloads.includes(fileToDownload[0].value)) {
      setBtnExportClicked(true);
      setExportSuccess();
      try {
        showLoader();
        // here the result is the S3 URL of the exported document
        const result = await get(
          `${apiExports.ROOT}/${id}${
            ExportEndpointMapper[fileToDownload[0].value]
          }?saveAsDocument=${watchSaveFile}`
        );
        if (result) {
          ExportStateSetter[fileToDownload[0].value].setter(result);
          setExportSuccess(true);
        } else {
          setExportSuccess(false);
          setErrorExportMessage('');
        }
      } catch (err) {
        console.log(
          'C.LOG ~ file: ExportsPanel.jsx:331 ~ {:',
          err?.message.includes(
            'This bridge has no Confirmed or Archived inspections'
          )
        );
        errorMessage(
          err?.message.includes(
            'This bridge has no Confirmed or Archived inspections'
          )
            ? ExportErrorMessages.MUST_ARCHIVE_OR_CONFIRM_INSPECTION
            : err?.message
        );
      } finally {
        hideLoader();
      }
    }

    // case for external lambda calls (size-expensive files)
    if (externalLambdaDownloads.includes(fileToDownload[0].value)) {
      console.log('external lambda download');
      setBtnExportClicked(true);
      setExportSuccess();
      setClusterExport();
      try {
        showLoader();
        let result;
        let relazioneTecnicaCdA;
        // check before that the type of lamba export should receive the frontend chart pie
        if (
          externalLambdaDownloadsWithUploadCDAGraph.includes(
            fileToDownload[0].value
          )
        ) {
          const graphImg = await uploadTempCdAPie();
          showLoader();
          if (graphImg) {
            const urlImg = encodeURIComponent(getS3ObjectUrl(graphImg));
            relazioneTecnicaCdA = await exportLambdaUrl(
              `/?saveAsDocument=${watchSaveFile}&bridgeId=${id}&type=${
                LambdaTypeMap[fileToDownload[0].value]
              }&cdaImage=${urlImg}&cdaType=${
                relazioniTecnicheCdaTypeMap[fileToDownload[0].value]
              }`
            );
            console.log(relazioneTecnicaCdA, 'relazioneTecnicaCdA');
            if (relazioneTecnicaCdA.errorMessage) {
              setExportSuccess(false);
              const message =
                relazioneTecnicaCdA.errorMessage ===
                'This bridge has no valid inspection'
                  ? "L'esportazione richiesta sarà disponibile solamente quando nel ponte saranno presenti attività archiviate o a te accessibili in modifica."
                  : '';
              setErrorExportMessage(message);
            } else {
              setRelazioneTecnicaCdA(relazioneTecnicaCdA);
              setExportSuccess(true);
            }
          }
        } else {
          console.log('case not graph');

          // here the result is the S3 URL of the exported document
          const hasClusterExportType =
            fileToDownload[0].value === ExportOptions.SchedeValutazioneDifetti;

          result = hasClusterExportType
            ? await exportLambdaUrl(
                `/?saveAsDocument=${watchSaveFile}&bridgeId=${id}&type=${
                  LambdaTypeMap[fileToDownload[0].value]
                }&clusterExportType=${
                  ClusterExportType[fileToDownload[0].value]
                }`
              )
            : await exportLambdaUrl(
                `/?saveAsDocument=${watchSaveFile}&bridgeId=${id}&type=${
                  LambdaTypeMap[fileToDownload[0].value]
                }`
              );

          if (result) {
            /* if (result.errorType) {
                  console.log('error typ');
                  setExportSuccess(false);
                  return errorMessage(result?.errorMessage);
                } */
            if (result !== 'clusterExport') {
              console.log('result QUI');
              if (result.errorMessage) {
                setExportSuccess(false);

                const message =
                  result.errorMessage === 'This bridge has no valid inspection'
                    ? "L'esportazione richiesta sarà disponibile solamente quando nel ponte saranno presenti attività archiviate o a te accessibili in modifica."
                    : '';
                setErrorExportMessage(message);
                /*  enqueueSnackbar(message, {
                      variant: 'error',
                    }); */
              } else {
                ExportStateSetter[fileToDownload[0].value].setter(result);
                setExportSuccess(true);
              }
            }
            if (result === 'clusterExport') {
              setClusterExport(true);
            }
            if (relazioneTecnicaCdA) {
              console.log('relazioneTecnicaCdA QUI');

              setRelazioneTecnicaCdA(relazioneTecnicaCdA);
            }
          } else {
            console.log('else no result?');
            setExportSuccess(false);
            setErrorExportMessage('');
          }
        }
        console.log(result, 'RESULT');
      } catch (error) {
        console.log(error, 'ERROR');
        errorMessage(
          error?.message.includes(
            'This bridge has no Confirmed or Archived inspections'
          )
            ? ExportErrorMessages.MUST_ARCHIVE_OR_CONFIRM_INSPECTION
            : error?.message
        );
      } finally {
        hideLoader();
      }
    }

    // case for relazioni tecniche
    if (relazioniTecnicheDownloads.includes(fileToDownload[0].value)) {
      setBtnExportClicked(true);
      setExportSuccess();

      const graphImg = await uploadTempCdAPie();

      if (graphImg) {
        const urlImg = encodeURIComponent(getS3ObjectUrl(graphImg));

        try {
          showLoader();
          const relazioneTecnicaCdA = await get(
            `${
              apiExports.ROOT
            }/${id}/relazione-tecnica-con-cda-complessiva?saveAsDocument=${watchSaveFile}&cdaImage=${urlImg}&cdaType=${
              relazioniTecnicheCdaTypeMap[fileToDownload[0].value]
            }`
          );
          /* const relazioneTecnicaCdA = exportLambdaUrl(
            `/?saveAsDocument=${watchSaveFile}&bridgeId=${id}&cdaImage=${urlImg}&cdaType=${
              relazioniTecnicheCdaTypeMap[fileToDownload[0].value]
            }&type=${LambdaTypeMap[fileToDownload[0].value]}`
          ); */
          if (relazioneTecnicaCdA) {
            setRelazioneTecnicaCdA(relazioneTecnicaCdA);
            setExportSuccess(true);
          } else {
            setExportSuccess(false);
            setErrorExportMessage('');
          }
        } catch (error) {
          console.log(error);
          errorMessage(error?.message);
        } finally {
          hideLoader();
        }
      }
    }

    // case of Front-End generated PDFs
    if (generatePdfBeforeExports.includes(fileToDownload[0].value)) {
      const validInspection = await get(
        `${apiBridges.ROOT}/${id}${apiBridges.USER_BASED_LAST_VALID_INSPECTION}`
      );
      if (validInspection) {
        const formData = getValues();
        const pdfData = await generatedPdfBeforeMapper[
          fileToDownload[0].value
        ].pdfDataFunction(formData);
        const pdfSize = pdfData?.size;
        const idFile = `${uuidv4()}-${new Date().getTime()}`;

        setClusterExport();
        setBtnExportClicked(true);
        setExportSuccess(true);
        if (watchSaveFile) {
          const res = await handleUpload(
            generatedPdfBeforeMapper[fileToDownload[0].value].uploadPath,
            pdfData,
            idFile,
            `${generatedPdfBeforeMapper[fileToDownload[0].value].fileName}.pdf`
          );

          const postData = {
            url: getS3ObjectUrl(res.s3Path),
            fileName: `${
              generatedPdfBeforeMapper[fileToDownload[0].value].fileName
            }.pdf`,
            type: 2,
            fileDimensions: pdfSize,
            structureId: parseInt(id),
            s3Path: res.s3Path,
            exportType: '3',
            visualName:
              generatedPdfBeforeMapper[fileToDownload[0].value].fileName,
            groups: ',12,',
            date: format(new Date(), 'yyyy-MM-dd'),
          };

          if (res) {
            try {
              showLoader();
              await post(apiMedia.ROOT, postData);
              return enqueueSnackbar('File salvato correttamente', {
                variant: 'success',
              });
            } catch (err) {
              console.error('Error in post pdf data: ', err);
              return enqueueSnackbar(`Si è verificato un errore`, {
                variant: 'error',
              });
            } finally {
              hideLoader();
            }
          }
        }
      } else {
        setBtnExportClicked(true);
        setExportSuccess(false);
        setErrorExportMessage('');
      }
    }

    // case of Csv Personalizzati - TreeView
    if (fileToDownload?.[0]?.value === ExportOptions.CsvPersonalizzati) {
      const csvData = csvGetValues();
      const validCheckboxs = Object.entries(csvData).filter(([k, v]) => {
        if (k.includes('indeterminate')) {
          return false;
        }
        const nodeId = k.split('-').at(-1);

        const node = objectDataTree.find(o => o.id === nodeId);
        const isField = node?.inspectionId !== null;
        const isChecked = v === true;
        return isChecked && isField;
      });
      const payload = validCheckboxs.map(
        c =>
          objectDataTree.find(o => o.id === c[0].split('-').at(-1))
            ?.inspectionId
      );
      // remove any "undefined" elements
      const sanitizedPayload = payload.filter(el => el);
      console.log({ idSets: sanitizedPayload }, 'payload');

      const selectedSet = new Set(sanitizedPayload.flat());
      const sortedSanitizedPayload = sortedTreeValues.filter(idsArray =>
        idsArray.some(id => selectedSet.has(id))
      );

      //console.log(sortedSanitizedPayload, 'sortedSanitizedPayload');
      try {
        showLoader();
        /* const resultCsvGenericExport = await post(
          `${apiExports.ROOT}/${id}${apiExports.CSV_GENERIC_EXPORT}?saveAsDocument=${watchSaveFile}`,
          { idSets: sanitizedPayload }
        ); */
        const resultCsvGenericExport = await postExportLambdaUrl(
          `/?saveAsDocument=${watchSaveFile}&bridgeId=${id}&type=${
            LambdaTypeMap[fileToDownload[0].value]
          }`,
          { idSets: sortedSanitizedPayload }
        );
        if (resultCsvGenericExport) {
          if (resultCsvGenericExport?.status === ExportResponseStatus.ERROR) {
            setExportSuccess(false);
            setErrorExportMessage('');
            return errorMessage(
              resultCsvGenericExport?.exception?.errorMessage
            );
          }
          setCsvGenerico(resultCsvGenericExport?.response);
          setBtnExportClicked(true);
          setExportSuccess(true);
        } else {
          setBtnExportClicked(true);
          setExportSuccess(false);
          setErrorExportMessage('');
        }
      } catch (error) {
        console.log(error);
        errorMessage(error?.message);
      } finally {
        hideLoader();
      }
    }
  };

  const handleSelected = val => {
    val ? setOptionSelected(true) : setOptionSelected(false);
    setBtnExportClicked(false);
  };

  const filesExports = selectOptions;

  const handleFileSelected = val => {
    const foundFile = filesExports.some(file => file.value === val);
    if (foundFile) {
      setFileExist(true);
      setFileToDownload(filesExports.filter(file => file.value === val));
    } else {
      setFileExist(false);
    }
  };

  useEffect(() => {
    if (
      fileToDownload &&
      generatePdfBeforeExports.includes(fileToDownload[0]?.value)
    ) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [fileToDownload]);

  useEffect(() => {
    setValue('denominazioneCommittente', '');
    setValue('provincia', '');
    setValue('citta', '');
    setValue('cap', '');
    setValue('indirizzo', '');
  }, [fileToDownload]);

  const avoidAinopExport =
    ((fileToDownload &&
      fileToDownload[0]?.value === ExportOptions.CsvAnagraficaBase) ||
      (fileToDownload &&
        fileToDownload[0]?.value === ExportOptions.CsvPontiViadotti)) &&
    (subscriptionStatus === SubscriptionStatus.ND ||
      subscriptionStatus === SubscriptionStatus.SCADUTA);

  const handleCsvCheckboxLevel = (level, isCheck) => {
    objectDataTree
      .filter(d => d[level])
      .map(o => csvSetValue(`check-${o.id}`, isCheck));

    csvSetValue('tree-trigger', nanoid());
  };

  const handleChildrenChecked = (nodeID, isChecked) => {
    if (isChecked) {
      objectDataTree
        .filter(o => o.parentId === nodeID)
        .map(node => {
          csvSetValue(`check-${node.id}`, true);
          handleChildrenChecked(node.id, true);
        });
    } else {
      objectDataTree
        .filter(o => o.parentId === nodeID)
        .map(node => {
          csvSetValue(`check-${node.id}`, false);
          handleChildrenChecked(node.id, false);
        });
    }
    csvSetValue('tree-trigger', nanoid());
  };

  return (
    <>
      <BodyContainer>
        <Grid container spacing={1}>
          {process.env.REACT_APP_ENVIRONMENT === 'develop' && (
            <p>
              {UserTypologyMap[userTypology]} - {userType} -{' '}
              {SubscriptionStatusMap[subscriptionStatus]}
            </p>
          )}
          {!isDemoStructure && !firstExportDate && showMessage && (
            <>
              <Grid item xs={12}>
                {FirstExportMessageMap[userTypology][userType][
                  subscriptionStatus
                ] && (
                  <InfoBox
                    color="primary.light"
                    text={
                      FirstExportMessageMap[userTypology][userType][
                        subscriptionStatus
                      ].message
                    }
                  />
                )}
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <ExportSelect
              handleSelected={handleSelected}
              isSelected={optionSelected}
              getFileValue={handleFileSelected}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                marginTop: 22,
                marginLeft: 16,
              }}
            >
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit, console.log)}
              >
                <RHFCheckbox
                  name="saveFile"
                  label="Salva il file nell'area Documenti del ponte"
                  defaultValue={false}
                />
              </FormProvider>
              {/*  <Checkbox />
              <Typography variant="h3">{`Salva il file nell'area Documenti del ponte`}</Typography> */}
            </div>
          </Grid>
        </Grid>
        {showForm && (
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit, console.log)}
          >
            <div style={{ marginLeft: -20 }}>
              <Grid container spacing={2} padding="20px">
                <Grid item sm={6} xs={12}>
                  <RHFTextField
                    name="denominazioneCommittente"
                    label={'Denominazione Committente'}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <RHFTextField
                    name="provincia"
                    label={'Provincia'}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <RHFTextField
                    name="citta"
                    label={'Città'}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <RHFTextField
                    name="cap"
                    label={'Cap'}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <RHFTextField
                    name="indirizzo"
                    label={'Indirizzo'}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            </div>
          </FormProvider>
        )}
        {fileToDownload?.[0]?.value === ExportOptions.CsvPersonalizzati && (
          <div
            style={{
              marginTop: 20,
              backgroundColor: '#ffffff',
              padding: 20,
              borderRadius: 8,
            }}
          >
            <FormProvider
              methods={customCsvMethods}
              onSubmit={handleSubmit(onSubmit, console.log)}
            >
              {/*  <TreeViewCustom data={data} /> */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="h4">
                    Personalizza i singoli campi del ponte che desideri
                    esportare:
                  </Typography>
                  <div style={{ marginTop: 16 }}>
                    <TreeViewCustom
                      data={objectDataTree}
                      handleChildrenChecked={handleChildrenChecked}
                      /* indeterminate={true} */
                    />
                  </div>
                </div>

                <div style={{ textAlign: 'right' }}>
                  <Typography variant="h4">
                    O seleziona in base al tipo di dato:
                  </Typography>
                  <div style={{ marginTop: 16, marginRight: -4 }}>
                    <RHFCheckbox
                      color="default"
                      name={`csvL0`}
                      defaultValue={false}
                      label={'L0'}
                      isTreeView
                      onClick={e =>
                        handleCsvCheckboxLevel('l0', e.target.checked)
                      }
                    />
                    <RHFCheckbox
                      color="default"
                      name={`csvL1`}
                      defaultValue={false}
                      label={'L1'}
                      isTreeView
                      onClick={e =>
                        handleCsvCheckboxLevel('l1', e.target.checked)
                      }
                    />
                    <RHFCheckbox
                      color="default"
                      name={`csvL2`}
                      defaultValue={false}
                      label={'L2'}
                      isTreeView
                      onClick={e =>
                        handleCsvCheckboxLevel('l2', e.target.checked)
                      }
                    />
                    <RHFCheckbox
                      color="default"
                      name={`csvManutenzione`}
                      defaultValue={false}
                      label={'Manutenzione'}
                      isTreeView
                      onClick={e =>
                        handleCsvCheckboxLevel('maintenance', e.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
            </FormProvider>
          </div>
        )}
        <BtnContainer>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
            disabled={!optionSelected || avoidAinopExport}
            onClick={() => {
              getFileToExport(fileToDownload);
            }}
          >
            {fileToDownload?.[0]?.value === ExportOptions.CsvPersonalizzati
              ? `Esporta selezionati`
              : `Esporta`}
          </Button>
        </BtnContainer>
        {btnExportClicked && clusterExport && (
          <div style={{ marginTop: 20 }}>
            <InfoBox color="primary.light" text={ClusterExportMessage} />
          </div>
        )}
        {btnExportClicked && exportSuccess && (
          <>
            <ExportMessage status={'success'}>
              <img src="/icons/green-check.svg" />
              <Typography variant="h3Bold" style={{ marginLeft: 10 }}>
                Esportazione completata con successo
              </Typography>
            </ExportMessage>

            <BtnContainer>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  handleDownload(
                    fileToDownload,
                    setExportSuccess,
                    setBtnExportClicked,
                    exportOverview,
                    schedaDifettiFile,
                    schedaDifettiMedia,
                    csvL0,
                    schedaIspezioniComplete,
                    csvL1,
                    csvL2,
                    schedaCensimentoL0,
                    csvAnagraficaBase,
                    csvPontiViadottiStradali,
                    docFotoRischioIdraulico,
                    docFotoRischioFrane,
                    strutturaSismica,
                    rischioFrane,
                    rischioIdraulico,
                    relazioneTecnicaCdA,
                    csvGenerico,
                    tabelleSintesiL1,
                    tabelleSintesiL0,
                    relazioneTecnicaL0,
                    csvManutenzione,
                    csvAinopL1L2
                  );
                }}
              >
                Download
              </Button>
            </BtnContainer>
          </>
        )}
        {btnExportClicked && exportSuccess === false && (
          <ExportMessage status={'failure'}>
            <img src="/icons/red-close.svg" />{' '}
            <Typography variant="h3Bold" style={{ marginLeft: 10 }}>
              {`Esportazione non disponibile. ${errorExportMessage}`}
            </Typography>
          </ExportMessage>
        )}
        {avoidAinopExport && (
          <>
            <div style={{ marginBottom: 20 }}></div>
            <InfoBox
              color="primary.light"
              text={
                <Typography variant="h3Bold">
                  {`Questa esportazione è disponibile solo per gli utenti con sottoscrizione attiva. Clicca`}{' '}
                  <span>
                    <a href={INFO_ASSISTANCE_PATHS.ROOT}>qui</a>
                  </span>{' '}
                  {`per contattarci e attivare la sottoscrizione.`}
                </Typography>
              }
            />
          </>
        )}
      </BodyContainer>
    </>
  );
};

export { ExportsPanel };

ExportsPanel.propTypes = {
  structureName: PropTypes.string,
  exportOverview: PropTypes.func,
  uploadTempCdAPie: PropTypes.func,
  pdfName: PropTypes.string,
  structureInfo: PropTypes.object,
};
