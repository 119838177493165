import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaAppoggioOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import AppoggiForm from './AppoggiForm';
const AppoggiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const spallePileCollegamentiTableAppoggiTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].appoggiTable`
  );

  const spallePileCollegamentiTable = watch(`spallePileCollegamentiTable`);
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const spallePileToCopy = spallePileCollegamentiTable?.map(
    el => el.appoggiTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsAppoggi = item => {
    setValue('appoggiTableValue', [item]);
    setValue('tipologiaAppoggioTableValue', item.tipologiaAppoggio);
  };

  const handleSelectAllClickAppoggi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable`
      );
      setSelected(selectedValue);
      setValue('appoggiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('appoggiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a3_tecnicoRilevatoreAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a3_dataIspezioneAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a3_elementoCriticoStrutturaAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_elementoCriticoSismicaAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaStrutturaAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaSismicaAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_noteGeneraliAppoggi',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a3_tecnicoRilevatoreAppoggi');
    const dataIspezione = getValues('l1t2a3_dataIspezioneAppoggi');
    const elementoCriticoStruttura = getValues(
      'l1t2a3_elementoCriticoStrutturaAppoggi'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a3_elementoCriticoSismicaAppoggi'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a3_condizioneCriticaStrutturaAppoggi'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a3_condizioneCriticaSismicaAppoggi'
    );
    const noteGenerali = getValues('l1t2a3_noteGeneraliAppoggi');
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].appoggiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a3_tecnicoRilevatoreAppoggi`, '');
    setValue(`l1t2a3_dataIspezioneAppoggi`, null);
    setValue(`l1t2a3_elementoCriticoStrutturaAppoggi`, '');
    setValue(`l1t2a3_elementoCriticoSismicaAppoggi`, '');
    setValue(`l1t2a3_condizioneCriticaStrutturaAppoggi`, '');
    setValue(`l1t2a3_condizioneCriticaSismicaAppoggi`, '');
    setValue(`l1t2a3_noteGeneraliAppoggi`, '');
  };

  const dataAppoggiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaAppoggio: {
        label: 'Tipologia',
        format: tipologiaAppoggioOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaAppoggio: {
        label: 'Tipologia',
        format: tipologiaAppoggioOptions,
      },
    },
  };

  const spallePileCollegamentoSelezione = watch(
    'spallePileCollegamentoSelezione'
  );

  useEffect(() => {
    if (spallePileCollegamentoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [spallePileCollegamentoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'appoggi'}
        infoDrawer={true}
        drawerTitle={INFO.collegamentiAppoggi.drawerTitle}
        drawerText={INFO.collegamentiAppoggi.drawerText}
        singleElementTitle={'appoggio'}
        tableWatch={spallePileCollegamentiTableAppoggiTableWatch}
        tableWatchName={`spallePileCollegamentiTable[${selectedIdx}].appoggiTable`}
        elementForm={
          <AppoggiForm
            appoggiFields={spallePileCollegamentiTableAppoggiTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            appoggiTable={`spallePileCollegamentiTable[${selectedIdx}].appoggiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a3_tecnicoRilevatoreAppoggi'}
            dateName={'l1t2a3_dataIspezioneAppoggi'}
            elementoCriticoStrutturaName={
              'l1t2a3_elementoCriticoStrutturaAppoggi'
            }
            elementoCriticoSismicaName={'l1t2a3_elementoCriticoSismicaAppoggi'}
            condizioneCriticaStrutturaName={
              'l1t2a3_condizioneCriticaStrutturaAppoggi'
            }
            condizioneCriticaSismicaName={
              'l1t2a3_condizioneCriticaSismicaAppoggi'
            }
            noteGeneraliName={'l1t2a3_noteGeneraliAppoggi'}
          />
        }
        tableConfig={dataAppoggiConfig}
        editRows={editRowsAppoggi}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickAppoggi}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaAppoggio'}
        tableForCopy={spallePileToCopy}
      />
    </>
  );
};
AppoggiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default AppoggiSection;
