import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { mensole } from 'constants/inspections';
import { resetObjectSchedaIspezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';

function useCopy() {
  const { getValues, setValue, watch } = useFormContext();

  const watchMensole = watch('mensole');
  const isMensole = watchMensole === mensole.Si;

  const { populateSchedaDifetti } = useSchedaDifetti();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dataMensoleTamponiCampateConfig = {
    columns: {
      campata: { label: 'Codice' },
      soletta: { label: 'SO (n°)' },
      travi: { label: 'TP (n°)' },
      traversi: { label: 'TS (n°)' },
      controventi: { label: 'CV (n°)' },
      archi: { label: 'A (n°)' },
      piedritti: { label: 'PD (n°)' },
      tiranti: { label: 'TN (n°)' },
      elementiExtra: { label: 'EX (n°)' },
    },
  };
  // const mensoleTamponiTableWatch = watch('mensoleTamponiTable');
  // const campataTableWatch = watch('campataStrutturaTable');

  const handleSelectAllMensoleTamponiCampatePaste = event => {
    if (event.target.checked) {
      if (isMensole) {
        const selectedValue = getValues('mensoleTamponiTable').filter(el =>
          checkCompatibility(el)
        );
        setSelectedPaste(selectedValue);
      } else {
        const selectedValue = getValues('campataStrutturaTable').filter(el =>
          checkCompatibility(el)
        );
        setSelectedPaste(selectedValue);
      }
    } else {
      setSelectedPaste([]);
    }
  };

  const checkCompatibility = row => {
    return (
      row.campata != selectedCopy.codice &&
      row.soletta == selectedCopy.soletteTable.length &&
      row.travi == selectedCopy.traviTable.length &&
      row.traversi == selectedCopy.traversiTable.length &&
      row.archi == selectedCopy.archiTable.length &&
      row.tiranti == selectedCopy.tirantiTable.length &&
      row.elementiExtra == selectedCopy.elementiExtraTable.length &&
      row.piedritti == selectedCopy.piedrittiTable.length &&
      row.controventi == selectedCopy.controventiTable?.length
    );
  };

  const handleCopy = () => {
    getValues('mensoleTamponiCampateImpalcatoTable').forEach(
      (x, mensoleTamponiCampateIdx) => {
        if (selectedPaste.some(p => p.campata == x.codice)) {
          const soletteCopy = selectedCopy.soletteTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `SO-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'soletta',
                el.tipologiaSoletta
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });

          const traviCopy = selectedCopy.traviTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `TP-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'trave',
                el.tipologiaTrave
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });
          const traversiCopy = selectedCopy.traversiTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `TS-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'traverso',
                el.tipologiaTraverso
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });
          const controventiCopy = selectedCopy.controventiTable.map(
            (el, idx) => {
              return {
                ...el,
                ...resetObjectSchedaIspezione,
                media: [],
                id: uuidv4(),
                codice: `CV-${x.codice}.${idx + 1}`,
                schedaDifetti: populateSchedaDifetti(
                  'controvento',
                  el.tipologiaControvento
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              };
            }
          );
          const archiCopy = selectedCopy.archiTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `A-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'arco',
                el.tipologiaArco
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });
          const piedrittiCopy = selectedCopy.piedrittiTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `PD-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'piedritto',
                el.tipologiaPiedritto
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });
          const tirantiCopy = selectedCopy.tirantiTable.map((el, idx) => {
            return {
              ...el,
              ...resetObjectSchedaIspezione,
              media: [],
              id: uuidv4(),
              codice: `TN-${x.codice}.${idx + 1}`,
              schedaDifetti: populateSchedaDifetti(
                'tirante',
                el.tipologiaTirante
              ).map(el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }),
            };
          });
          const elementiExtraCopy = selectedCopy.elementiExtraTable.map(
            (el, idx) => {
              return {
                ...el,
                ...resetObjectSchedaIspezione,
                media: [],
                id: uuidv4(),
                codice: `EX-${x.codice}.${idx + 1}`,
                schedaDifetti: populateSchedaDifetti(
                  'elementoExtra',
                  el.tipologiaElementoExtra
                ).map(el => {
                  return {
                    ...el,
                    visto: false,
                    statoDifetto: '',
                    estensioneDifetto: '',
                    intensitaDifetto: '',
                    ps: false,
                    note: '',
                    media: [],
                  };
                }),
              };
            }
          );

          const tmp = {
            ...x,
            soletteTable: soletteCopy,
            traviTable: traviCopy,
            traversiTable: traversiCopy,
            controventiTable: controventiCopy,
            archiTable: archiCopy,
            piedrittiTable: piedrittiCopy,
            tirantiTable: tirantiCopy,
            elementiExtraTable: elementiExtraCopy,
          };
          setValue(
            `mensoleTamponiCampateImpalcatoTable[${mensoleTamponiCampateIdx}]`,
            tmp
          );
        }
      }
    );
    setCopyDrawerOpen(false);
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    dataMensoleTamponiCampateConfig,
    handleSelectAllMensoleTamponiCampatePaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
