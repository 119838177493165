import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  SchemaPonteCommonTableValue,
  SchemaPonteSpartiTrafficoTableValue,
} from 'constants/inspections';
import {
  schemaPonteCommonOptions,
  schemaPonteSpartitrafficoOptions,
} from 'constants/selectOptions';
import { resetObjectScheda20Ispezione } from 'utils/Inspections/L1/resetSchedaIspezione';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';

function useCopy() {
  const { getValues, setValue } = useFormContext();

  const { populateScheda20Difetti } = useSchedaDifetti();

  const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
  const [selectedPaste, setSelectedPaste] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dataConfig = {
    columns: {
      codice: { label: 'Cod.' },
      cordoloSX: { label: 'CO-SX', format: schemaPonteCommonOptions },
      cordoloDX: { label: 'CO-DX', format: schemaPonteCommonOptions },
      marciapiedeSX: { label: 'MA-SX', format: schemaPonteCommonOptions },
      marciapiedeDX: { label: 'MA-DX', format: schemaPonteCommonOptions },
      parapettoSX: { label: 'PP-SX', format: schemaPonteCommonOptions },
      parapettoDX: { label: 'PP-DX', format: schemaPonteCommonOptions },
      guardaviaSX: { label: 'GV-SX', format: schemaPonteCommonOptions },
      guardaviaDX: { label: 'GV-DX', format: schemaPonteCommonOptions },
      spartitraffico: { label: 'ST', format: schemaPonteSpartitrafficoOptions },
      pavimentazione: { label: 'PV', format: schemaPonteCommonOptions },
    },
  };

  const handleSelectAllPaste = event => {
    if (event.target.checked) {
      const selectedValue = getValues('schemaPonteTable').filter(el =>
        checkCompatibility(el)
      );
      setSelectedPaste(selectedValue);
    } else {
      setSelectedPaste([]);
    }
  };

  const isOneElement = el => {
    if (
      el === SchemaPonteCommonTableValue.Uno
      // el === SchemaPonteCommonTableValue.Assente
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const checkCompatibility = row => {
    const totCordoli =
      isOneElement(row.cordoloSX) + isOneElement(row.cordoloDX);
    const totMarciapiedi =
      isOneElement(row.marciapiedeSX) + isOneElement(row.marciapiedeDX);
    const totParapetti =
      isOneElement(row.parapettoSX) + isOneElement(row.parapettoDX);
    const totGuardavie =
      isOneElement(row.guardaviaSX) + isOneElement(row.guardaviaDX);
    let totSpartitraffico = 0;
    if (
      row.spartitraffico === SchemaPonteSpartiTrafficoTableValue.Uno
      // row.spartitraffico === SchemaPonteSpartiTrafficoTableValue.Assente
    ) {
      totSpartitraffico = 1;
    } else if (row.spartitraffico === SchemaPonteSpartiTrafficoTableValue.Due) {
      totSpartitraffico = 2;
    } else if (row.spartitraffico === SchemaPonteSpartiTrafficoTableValue.Tre) {
      totSpartitraffico = 3;
    }

    const totPavimentazione =
      row.pavimentazione == SchemaPonteCommonTableValue.Uno
        ? // row.pavimentazione == SchemaPontePavimentazioneTableValue.No
          1
        : 0;

    return (
      row.codice != selectedCopy.codice &&
      totCordoli ==
        selectedCopy.cordoliTable.filter(
          el => el.presenza == SchemaPonteCommonTableValue.Uno
        ).length &&
      totMarciapiedi ==
        selectedCopy.marciapiediTable.filter(
          el => el.presenza == SchemaPonteCommonTableValue.Uno
        ).length &&
      totParapetti ==
        selectedCopy.parapettiTable.filter(
          el => el.presenza == SchemaPonteCommonTableValue.Uno
        ).length &&
      totGuardavie ==
        selectedCopy.guardavieTable.filter(
          el => el.presenza == SchemaPonteCommonTableValue.Uno
        ).length &&
      totSpartitraffico ==
        selectedCopy.spartitrafficoTable.filter(
          el =>
            el.presenza == SchemaPonteSpartiTrafficoTableValue.Uno ||
            el.presenza == SchemaPonteSpartiTrafficoTableValue.Due ||
            el.presenza == SchemaPonteSpartiTrafficoTableValue.Tre
        ).length &&
      totPavimentazione ==
        selectedCopy.pavimentazioneTable.filter(
          el => el.presenza == SchemaPonteCommonTableValue.Uno
        ).length
    );
  };

  const handleCopy = () => {
    getValues('sovrastrutturaImpalcatoTable').forEach((x, impalcatoIdx) => {
      if (selectedPaste.some(p => p.codice == x.codice)) {
        const cordoliCopy = selectedCopy.cordoliTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            codice: el.codice.replace(
              el.codice.substring(3, el.codice.indexOf('.')),
              `${x.codice}`
            ),
            schedaDifetti: populateScheda20Difetti('cordolo', el.presenza).map(
              el => {
                return {
                  ...el,
                  visto: false,
                  statoDifetto: '',
                  estensioneDifetto: '',
                  intensitaDifetto: '',
                  ps: false,
                  note: '',
                  media: [],
                };
              }
            ),
          };
        });
        const marciapiediCopy = selectedCopy.marciapiediTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            codice: el.codice.replace(
              el.codice.substring(3, el.codice.indexOf('.')),
              `${x.codice}`
            ),
            schedaDifetti: populateScheda20Difetti(
              'marciapiede',
              el.presenza
            ).map(el => {
              return {
                ...el,
                visto: false,
                presente: false,
                statoDifetto: false,
                note: '',
                media: [],
              };
            }),
          };
        });
        const parapettiCopy = selectedCopy.parapettiTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            codice: el.codice.replace(
              el.codice.substring(3, el.codice.indexOf('.')),
              `${x.codice}`
            ),
            schedaDifetti: populateScheda20Difetti(
              'parapetto',
              el.presenza
            ).map(el => {
              return {
                ...el,
                visto: false,
                presente: false,
                statoDifetto: false,
                note: '',
                media: [],
                gravita: null,
                danneggiato: null,
                ossidato: null,
              };
            }),
          };
        });
        const guardavieCopy = selectedCopy.guardavieTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            codice: el.codice.replace(
              el.codice.substring(3, el.codice.indexOf('.')),
              `${x.codice}`
            ),
            schedaDifetti: populateScheda20Difetti(
              'guardavia',
              el.presenza
            ).map(el => {
              return {
                ...el,
                visto: false,
                presente: false,
                statoDifetto: false,
                note: '',
                media: [],
                gravita: null,
                danneggiato: null,
                ossidato: null,
              };
            }),
          };
        });
        const spartitrafficoCopy = selectedCopy.spartitrafficoTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            codice: el.codice.replace(
              el.codice.substring(3, el.codice.indexOf('.')),
              `${x.codice}`
            ),
            schedaDifetti: populateScheda20Difetti(
              'spartitraffico',
              el.presenza
            ).map(el => {
              return {
                ...el,
                visto: false,
                presente: false,
                statoDifetto: false,
                note: '',
                media: [],
              };
            }),
          };
        });
        const pavimentazioneCopy = selectedCopy.pavimentazioneTable.map(el => {
          return {
            ...el,
            ...resetObjectScheda20Ispezione,
            media: [],
            id: uuidv4(),
            // codice: el.codice.replace(el.codice.substring(3, 5), `${x.codice}`),
            codice: el.codice.replace(/C\d+/, x.codice),
            schedaDifetti: populateScheda20Difetti(
              'pavimentazione',
              el.presenza
            ).map(el => {
              return {
                ...el,
                visto: false,
                presente: false,
                statoDifetto: false,
                note: '',
                media: [],
              };
            }),
          };
        });

        const tmp = {
          ...x,
          cordoliTable: cordoliCopy,
          marciapiediTable: marciapiediCopy,
          parapettiTable: parapettiCopy,
          guardavieTable: guardavieCopy,
          spartitrafficoTable: spartitrafficoCopy,
          pavimentazioneTable: pavimentazioneCopy,
        };
        setValue(`sovrastrutturaImpalcatoTable[${impalcatoIdx}]`, tmp);
      }
    });
    setCopyDrawerOpen(false);
    return enqueueSnackbar('Elemento copiato correttamente', {
      variant: 'success',
    });
  };

  return {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    dataConfig,
    handleSelectAllPaste,
    checkCompatibility,
    handleCopy,
  };
}
export default useCopy;
