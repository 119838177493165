import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { DrawerCopy, GridWrap, RHFSelect } from 'components';
import { SelectionPanel } from 'components/SelectionPanel/SelectionPanel';
import {
  SchemaPonteCommonTableValue,
  //SchemaPontePavimentazioneTableValue,
  SchemaPonteSpartiTrafficoTableValue,
} from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import useSchedaDifetti from 'utils/Inspections/L1/schedeDifetti';
import { BtnContainer } from './Accordion2Impalcato.styles';
import useCopy from './hooks/useCopy';
import CordoliSection from './sections/CordoliSection';
import GuardavieSection from './sections/GuardavieSection';
import MarciapiediSection from './sections/MarciapiediSection';
import ParapettiSection from './sections/ParapettiSection';
import PavimentazioneSection from './sections/PavimentazioneSection';
import SpartitrafficoSection from './sections/SpartitrafficoSection';

const Accordion2Impalcato = () => {
  const { watch, control, getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  // SPALLE/PILE Impalcato Table
  const {
    append: addSovrastrutturaImpalcatoTable,
    remove: removeSovrastrutturaImpalcatoTable,
  } = useFieldArray({
    control,
    name: 'sovrastrutturaImpalcatoTable',
  });

  const { populateScheda20Difetti } = useSchedaDifetti();

  const schemaPonteTableWatch = watch('schemaPonteTable');
  const sortedSchemaPonteTable = schemaPonteTableWatch.sort((a, b) => {
    const numA = parseInt(a.codice.substring(1), 10);
    const numB = parseInt(b.codice.substring(1), 10);
    return numA - numB;
  });

  const sovrastrutturaImpalcatoTableWatch = watch(
    'sovrastrutturaImpalcatoTable'
  );

  const handleCommonElementoAccessorioTableRefresh = (
    nameSchedaDifetto,
    schemaPonteEl,
    elementTable,
    elementObj,
    code,
    isSpartitraffico = false
  ) => {
    const schedaDifettiCommon = populateScheda20Difetti(
      nameSchedaDifetto,
      schemaPonteEl
    ).map(el => {
      /* console.log(
        'C.LOG ~ file: Accordion2Impalcato.jsx ~ line 137 ~ schemaPonteEl',
        schemaPonteEl
      ); */
      return {
        ...el,
        visto:
          (!isSpartitraffico &&
            schemaPonteEl === SchemaPonteCommonTableValue.Assente) ||
          (isSpartitraffico &&
            schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Assente)
            ? true
            : false,
        presente:
          (!isSpartitraffico &&
            schemaPonteEl === SchemaPonteCommonTableValue.Assente) ||
          (isSpartitraffico &&
            schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Assente)
            ? true
            : false,
        statoDifetto: null,
        note: '',

        media: [],
      };
    });
    if (!isSpartitraffico) {
      if (schemaPonteEl === SchemaPonteCommonTableValue.Uno) {
        const precCommonEl = elementTable.find(crd => crd.codice === code);
        let changedPresence = false;
        if (precCommonEl?.assente) {
          elementTable.splice(elementTable.indexOf(precCommonEl), 1);
          changedPresence = true;
        }
        if (!precCommonEl || changedPresence) {
          elementTable.push({
            ...elementObj,
            completamentoSchedaDifetti: null,
            nrtotali: null,
            presenza: SchemaPonteCommonTableValue.Uno,
            schedaDifetti: schedaDifettiCommon,
          });
        }
      } else {
        const precCommonEl = elementTable.find(crd => crd.codice === code);
        if (precCommonEl) {
          elementTable.splice(elementTable.indexOf(precCommonEl), 1);
        }
        if (schemaPonteEl === SchemaPonteCommonTableValue.Assente) {
          elementTable.push({
            ...elementObj,
            assente: true,
            completamentoSchedaDifetti: '100%',
            nrtotali: 'n.d.',
            presenza: SchemaPonteCommonTableValue.Assente,
            schedaDifetti: schedaDifettiCommon,
          });
        }
      }
    } else {
      if (
        schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Uno ||
        schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Due ||
        schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Tre
      ) {
        const precCommonEl = elementTable.find(crd => crd.codice === code);
        let changedPresence = false;
        if (precCommonEl?.assente) {
          elementTable.splice(elementTable.indexOf(precCommonEl), 1);
          changedPresence = true;
        }
        if (!precCommonEl || changedPresence) {
          elementTable.push({
            ...elementObj,
            completamentoSchedaDifetti: null,
            nrtotali: null,
            presenza: schemaPonteEl,
            schedaDifetti: schedaDifettiCommon,
          });
        }
      } else {
        const precCommonEl = elementTable.find(crd => crd.codice === code);
        if (precCommonEl) {
          elementTable.splice(elementTable.indexOf(precCommonEl), 1);
        }
        if (schemaPonteEl === SchemaPonteSpartiTrafficoTableValue.Assente) {
          elementTable.push({
            ...elementObj,
            assente: true,
            completamentoSchedaDifetti: '100%',
            nrtotali: 'n.d.',
            presenza: SchemaPonteSpartiTrafficoTableValue.Assente,
            schedaDifetti: schedaDifettiCommon,
          });
        }
      }
    }
  };

  const handlSovrastrutturaImpalcatoTable = table => {
    const hasChangedAnyCordoli = table?.some((el, idx) => {
      return sovrastrutturaImpalcatoTableWatch[idx]?.cordoliTable?.every(
        crd => {
          if (crd.codice?.includes('SX')) {
            return crd.presenza !== el.cordoloSX;
          }
          if (crd.codice?.includes('DX')) {
            return crd.presenza !== el.cordoloDX;
          }
        }
      );
    });

    const hasChangedAnyGuardavie = table?.some((el, idx) => {
      const numGuardavie =
        (el.guardaviaSX === SchemaPonteCommonTableValue.Uno ? 1 : 0) +
        (el.guardaviaDX === SchemaPonteCommonTableValue.Uno ? 1 : 0);
      return (
        numGuardavie !==
        sovrastrutturaImpalcatoTableWatch[idx]?.guardavieTable?.length
      );
    });
    const hasChangedAnyParapetti = table?.some((el, idx) => {
      const numParapetti =
        (el.parapettoSX === SchemaPonteCommonTableValue.Uno ? 1 : 0) +
        (el.parapettoDX === SchemaPonteCommonTableValue.Uno ? 1 : 0);
      return (
        numParapetti !==
        sovrastrutturaImpalcatoTableWatch[idx]?.parapettiTable?.length
      );
    });
    const hasChangedAnyMarciapiedi = true;
    // table?.some((el, idx) => {
    //   return sovrastrutturaImpalcatoTableWatch[idx]?.marciapiediTable?.every(
    //     mrcp => {
    //       if (mrcp.codice?.includes('SX')) {
    //         return mrcp.presenza !== el.marciapiedeSX;
    //       }
    //       if (mrcp.codice?.includes('DX')) {
    //         return mrcp.presenza !== el.marciapiedeDX;
    //       }
    //     }
    //   );
    // });
    const hasChangedPavimentazione = table?.some((el, idx) => {
      const pavimentazione =
        el.pavimentazione === SchemaPonteCommonTableValue.Uno ? 1 : 0;
      return (
        pavimentazione !==
        sovrastrutturaImpalcatoTableWatch[idx]?.pavimentazioneTable?.length
      );
    });
    const hasChangedAnySpartitraffico = table?.some((el, idx) => {
      let numSpartitraffico;
      switch (el.spartitraffico) {
        case SchemaPonteSpartiTrafficoTableValue.Uno:
          numSpartitraffico = 1;
          break;
        case SchemaPonteSpartiTrafficoTableValue.Due:
          numSpartitraffico = 2;
          break;
        case SchemaPonteSpartiTrafficoTableValue.Tre:
          numSpartitraffico = 3;
          break;
        default:
          numSpartitraffico = 0;
          break;
      }

      return (
        numSpartitraffico !==
        sovrastrutturaImpalcatoTableWatch[idx]?.spartitrafficoTable?.length
      );
    });

    if (
      table?.length !== sovrastrutturaImpalcatoTableWatch?.length ||
      hasChangedAnyCordoli ||
      hasChangedAnyGuardavie ||
      hasChangedAnyParapetti ||
      hasChangedAnyMarciapiedi ||
      hasChangedPavimentazione ||
      hasChangedAnySpartitraffico
    ) {
      removeSovrastrutturaImpalcatoTable();
      table.map((el, idx) => {
        // the every method is used for check if all of the sub-elements are of the correct type
        // es. the "el" has the code "M1", so every child element should be like "CO-M1.SX"
        // if even one of this doesn't have the correct "M1" inside, es. "CO-C1.DX", it means that
        // it should be resetted to an empty array (more on https://github.com/nautes-tech/tecnoindagini-issues/issues/273)
        const pavimentazioneTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.pavimentazioneTable?.every(p => p.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.pavimentazioneTable
          : [] || [];
        const cordoliTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.cordoliTable?.every(c => c.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.cordoliTable
          : [] || [];
        const marciapiediTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.marciapiediTable?.every(m => m.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.marciapiediTable
          : [] || [];
        const parapettiTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.parapettiTable?.every(p => p.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.parapettiTable
          : [] || [];
        const guardavieTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.guardavieTable?.every(g => g.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.guardavieTable
          : [] || [];
        const spartitrafficoTable = sovrastrutturaImpalcatoTableWatch[
          idx
        ]?.spartitrafficoTable?.every(s => s.codice.includes(el.codice))
          ? sovrastrutturaImpalcatoTableWatch[idx]?.spartitrafficoTable
          : [] || [];

        // Old Logic for pavimentazione for option {Si: 1, No. 2}
        /* const schedaDifettiPavimentazione = populateScheda20Difetti(
          'pavimentazione',
          el.pavimentazione
        ).map(el => {
          return {
            ...el,
            visto:
              el.pavimentazione === SchemaPontePavimentazioneTableValue.No
                ? true
                : false,
            presente:
              el.pavimentazione === SchemaPontePavimentazioneTableValue.No
                ? true
                : false,
            nr: false,
            note: '',
            media: [],
          };
        });

        if (el.pavimentazione === SchemaPontePavimentazioneTableValue.Si) {
          if (pavimentazioneTable?.length == 1) {
            pavimentazioneTable.pop();
          }
          if (pavimentazioneTable?.length == 0) {
            pavimentazioneTable.push({
              id: uuidv4(),
              codice: `PV-${el.codice}`,
              tipologiaPavimentazione: '',
              tipologiaAltroPavimentazione: '',
              spessorePavimentazione: '',
              superficiePavimentazione: '',
              presenza: SchemaPontePavimentazioneTableValue.Si,
              schedaDifetti: schedaDifettiPavimentazione,
            });
          }
        } else {
          if (pavimentazioneTable?.length != 0) {
            pavimentazioneTable.pop();
          }
          if (el.pavimentazione === SchemaPontePavimentazioneTableValue.No) {
            pavimentazioneTable.push({
              id: uuidv4(),
              codice: `PV-${el.codice}`,
              assente: true,
              presenza: SchemaPontePavimentazioneTableValue.No,
              schedaDifetti: schedaDifettiPavimentazione,
            });
          }
        } */

        // Logic for Pavimentazione
        const pavimentazioneobj = {
          id: uuidv4(),
          codice: `PV-${el.codice}`,
          tipologiaPavimentazione: '',
          tipologiaAltroPavimentazione: '',
          spessorePavimentazione: '',
          superficiePavimentazione: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'pavimentazione',
          el.pavimentazione,
          pavimentazioneTable,
          pavimentazioneobj,
          `PV-${el.codice}`
        );

        // Logic for CordoloSX
        const cordoloSXObj = {
          id: uuidv4(),
          codice: `CO-${el.codice}.SX`,
          tipologiaCordolo: '',
          altezzaCordolo: '',
          profonditaCordolo: '',
          lunghezzaCordolo: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'cordolo',
          el.cordoloSX,
          cordoliTable,
          cordoloSXObj,
          `CO-${el.codice}.SX`
        );

        // Logic for CordoloDX
        const cordoloDXObj = {
          id: uuidv4(),
          codice: `CO-${el.codice}.DX`,
          tipologiaCordolo: '',
          altezzaCordolo: '',
          profonditaCordolo: '',
          lunghezzaCordolo: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'cordolo',
          el.cordoloDX,
          cordoliTable,
          cordoloDXObj,
          `CO-${el.codice}.DX`
        );

        // Logic for MarciapiedeSX
        const marciapiedeSXObj = {
          id: uuidv4(),
          codice: `MA-${el.codice}.SX`,
          tipologiaMarciapiede: '',
          tipologiaAltroMarciapiede: '',
          marciapiedePresente: '',
          sormontabilitaMarciapiede: '',
          larghezzaMarciapiede: '',
          altezzaMarciapiede: '',
          superficieMarciapiede: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'marciapiede',
          el.marciapiedeSX,
          marciapiediTable,
          marciapiedeSXObj,
          `MA-${el.codice}.SX`
        );
        // Logic for MarciapiedeDX
        const marciapiedeDXObj = {
          id: uuidv4(),
          codice: `MA-${el.codice}.DX`,
          tipologiaMarciapiede: '',
          tipologiaAltroMarciapiede: '',
          marciapiedePresente: '',
          sormontabilitaMarciapiede: '',
          larghezzaMarciapiede: '',
          altezzaMarciapiede: '',
          superficieMarciapiede: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'marciapiede',
          el.marciapiedeDX,
          marciapiediTable,
          marciapiedeDXObj,
          `MA-${el.codice}.DX`
        );

        // Logic for ParapettoSX
        const parapettoSX = {
          id: uuidv4(),
          codice: `PP-${el.codice}.SX`,
          tipologiaParapetto: '',
          tipologiaAltroParapetto: '',
          altezzaParapetto: '',
          superficieParapetto: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'parapetto',
          el.parapettoSX,
          parapettiTable,
          parapettoSX,
          `PP-${el.codice}.SX`
        );

        // Logic for ParapettoDX
        const parapettoDX = {
          id: uuidv4(),
          codice: `PP-${el.codice}.DX`,
          tipologiaParapetto: '',
          tipologiaAltroParapetto: '',
          altezzaParapetto: '',
          superficieParapetto: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'parapetto',
          el.parapettoDX,
          parapettiTable,
          parapettoDX,
          `PP-${el.codice}.DX`
        );

        // Logic for GuardaviaSX
        const guardaviaSX = {
          id: uuidv4(),
          codice: `GV-${el.codice}.SX`,
          tipologiaGuardavia: '',
          tipologiaAltroGuardavia: '',
          altezzaGuardavia: '',
          superficieGuardavia: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'guardavia',
          el.guardaviaSX,
          guardavieTable,
          guardaviaSX,
          `GV-${el.codice}.SX`
        );
        // Logic for GuardaviaDX
        const guardaviaDX = {
          id: uuidv4(),
          codice: `GV-${el.codice}.DX`,
          tipologiaGuardavia: '',
          tipologiaAltroGuardavia: '',
          altezzaGuardavia: '',
          superficieGuardavia: '',
        };

        handleCommonElementoAccessorioTableRefresh(
          'guardavia',
          el.guardaviaDX,
          guardavieTable,
          guardaviaDX,
          `GV-${el.codice}.DX`
        );

        const handlePushSpartitrafficoTable = i => {
          const spartitrafficoObj = {
            id: uuidv4(),
            codice: `ST-${el.codice}.${i}`,
            tipologiaSpartitraffico: '',
            tipologiaAltroSpartitraffico: '',
            larghezzaSpartitraffico: '',
            altezzaSpartitraffico: '',
            superficieSpartitraffico: '',
          };
          handleCommonElementoAccessorioTableRefresh(
            'spartitraffico',
            el.spartitraffico,
            spartitrafficoTable,
            spartitrafficoObj,
            `ST-${el.codice}.${i}`,
            true
          );
        };

        switch (el.spartitraffico) {
          case SchemaPonteSpartiTrafficoTableValue.Uno:
            for (let i = 1; i <= 1; i++) {
              const precSpartitraffico = spartitrafficoTable.find(
                sprt => sprt.codice === `ST-${el.codice}.${i}`
              );
              if (!precSpartitraffico) {
                handlePushSpartitrafficoTable(i);
              }
            }
            spartitrafficoTable.splice(1, 2);
            break;
          case SchemaPonteSpartiTrafficoTableValue.Due:
            for (let i = 1; i <= 2; i++) {
              const precSpartitraffico = spartitrafficoTable.find(
                sprt => sprt.codice === `ST-${el.codice}.${i}`
              );
              if (!precSpartitraffico) {
                handlePushSpartitrafficoTable(i);
              }
            }
            spartitrafficoTable.splice(2, 1);
            break;
          case SchemaPonteSpartiTrafficoTableValue.Tre:
            for (let i = 1; i <= 3; i++) {
              const precSpartitraffico = spartitrafficoTable.find(
                sprt => sprt.codice === `ST-${el.codice}.${i}`
              );
              if (!precSpartitraffico) {
                handlePushSpartitrafficoTable(i);
              }
            }
            break;
          case SchemaPonteSpartiTrafficoTableValue.Assente:
            spartitrafficoTable.splice(0, 3);
            handlePushSpartitrafficoTable(1);
            break;
          default:
            spartitrafficoTable.splice(0, 3);
            break;
        }

        // removeSovrastrutturaImpalcatoTable(idx);
        addSovrastrutturaImpalcatoTable({
          id: uuidv4(),
          codice: el.codice,
          pavimentazioneTable,
          cordoliTable,
          marciapiediTable,
          parapettiTable,
          guardavieTable,
          spartitrafficoTable,
        });
      });
    }
  };

  useEffect(() => {
    if (sortedSchemaPonteTable && sovrastrutturaImpalcatoTableWatch) {
      handlSovrastrutturaImpalcatoTable(sortedSchemaPonteTable);
    }
  }, [sortedSchemaPonteTable]);

  const [
    sovrastrutturaImpalcatoSelezioneOptions,
    setSovrastrutturaImpalcatoSelezioneOptions,
  ] = useState([]);

  const [selectedIdx, setSelectedIdx] = useState('');

  useEffect(() => {
    if (sovrastrutturaImpalcatoTableWatch) {
      setSovrastrutturaImpalcatoSelezioneOptions(
        sovrastrutturaImpalcatoTableWatch.map((el, idx) => {
          return { label: el.codice, value: idx };
        })
      );
    }
  }, [sovrastrutturaImpalcatoTableWatch]);

  // watcher for selecting index of which mensola/tampone/campata to modify
  const l0t3a2_sovrastrutturaImpalcatoSelezioneWatch = watch(
    'l0t3a2_sovrastrutturaImpalcatoSelezione'
  );
  useEffect(() => {
    if (l0t3a2_sovrastrutturaImpalcatoSelezioneWatch !== undefined) {
      setSelectedIdx(l0t3a2_sovrastrutturaImpalcatoSelezioneWatch);
    }
  }, [l0t3a2_sovrastrutturaImpalcatoSelezioneWatch]);

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedCopy,
    setSelectedCopy,
    selectedPaste,
    dataConfig,
    handleSelectAllPaste,
    setSelectedPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy();

  return (
    <>
      <GridWrap item xs={12}>
        <SelectionPanel subtitle="Seleziona una campata/mensola/trave/tampone per procedere alla compilazione">
          <GridWrap container spacing={2}>
            <GridWrap item xs={10}>
              <RHFSelect
                name="l0t3a2_sovrastrutturaImpalcatoSelezione"
                label={'Seleziona elemento'}
                defaultValue={''}
                selectOptions={sovrastrutturaImpalcatoSelezioneOptions}
              />
            </GridWrap>

            <GridWrap item xs={2}>
              {selectedIdx !== '' &&
                !inspectionDisabled &&
                !isViewingInspection && (
                  <BtnContainer>
                    <Button
                      onClick={() => {
                        setCopyDrawerOpen(true);
                        setSelectedPaste([]);
                        setSelectedCopy(
                          getValues(
                            `sovrastrutturaImpalcatoTable[${selectedIdx}]`
                          )
                        );
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Copia
                    </Button>
                  </BtnContainer>
                )}
            </GridWrap>
          </GridWrap>
        </SelectionPanel>
      </GridWrap>
      {selectedIdx !== '' && (
        <>
          <PavimentazioneSection selectedIdx={selectedIdx} />
          <CordoliSection selectedIdx={selectedIdx} />
          <MarciapiediSection selectedIdx={selectedIdx} />
          <ParapettiSection selectedIdx={selectedIdx} />
          <GuardavieSection selectedIdx={selectedIdx} />
          <SpartitrafficoSection selectedIdx={selectedIdx} />
        </>
      )}
      <DrawerCopy
        title={`Impalcato / Copia ${getValues(
          `sovrastrutturaImpalcatoTable[${selectedIdx}].codice`
        )}`}
        subtitle={
          'Tutti gli elementi su cui le informazioni saranno copiate saranno sovrascritti. Eventuali media o schede di ispezione a loro associati saranno resettati.'
        }
        open={copyDrawerOpen}
        setOpen={setCopyDrawerOpen}
        tableConfig={dataConfig}
        copyTableData={
          sortedSchemaPonteTable?.filter(
            el => el.codice == selectedCopy.codice
          ) || []
        }
        pasteTableData={
          sortedSchemaPonteTable?.filter(
            el => el.codice != selectedCopy.codice
          ) || []
        }
        selectedCopy={selectedCopy}
        selectedPaste={selectedPaste}
        handleSelectAll={handleSelectAllPaste}
        setSelectedPaste={setSelectedPaste}
        checkCompatibility={checkCompatibility}
        handleCopy={handleCopy}
      />
    </>
  );
};
export default Accordion2Impalcato;
