import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isNullOrUndefinedOrEmptyString } from 'utils/utilities';

function useSpallePileCampate({
  watchNCampate,
  setIsFormVisible,
  setValue,
  getValues,
  watch,
  removeSpallaPila,
  addSpallaPila,
  removeCampate,
  addCampate,
}) {
  useEffect(() => {
    parseInt(watchNCampate) !== 0 &&
    !isNullOrUndefinedOrEmptyString(watchNCampate)
      ? setIsFormVisible(true)
      : setIsFormVisible(false);

    if (
      parseInt(watchNCampate) !== 0 &&
      !isNullOrUndefinedOrEmptyString(watchNCampate)
    ) {
      setValue('nPileStruttura', watchNCampate - 1);
      setValue('nSpalleStruttura', 2);

      const nPile = watchNCampate - 1;
      const nSpalle = 2;
      const nSpallePile = nSpalle + nPile;

      const resultPile = watch('spallePileTable').filter(el => {
        return el.codice.startsWith('P');
      });

      const resultSpalle = watch('spallePileTable').filter(el => {
        return el.codice.startsWith('S');
      });

      if (nSpallePile !== watch('spallePileTable')?.length) {
        removeSpallaPila();
        if (nSpalle !== resultSpalle.length) {
          if (nSpalle < resultSpalle.length) {
            for (let i = resultSpalle.length - 1; i >= nSpalle; i--) {
              resultSpalle.pop();
            }
          } else {
            for (let i = resultSpalle.length + 1; i <= nSpalle; i++) {
              resultSpalle.push({
                id: uuidv4(),
                codice: 'S' + i,
                appoggi: '',
                giunti: '',
                pulvini: '',
                antenne: '',
                dispositiviSismici: '',
              });
            }
          }
        }
        if (nPile !== resultPile.length) {
          if (nPile < resultPile.length) {
            for (let i = resultPile.length - 1; i >= nPile; i--) {
              resultPile.pop();
            }
          } else {
            for (let i = resultPile.length + 1; i <= nPile; i++) {
              resultPile.push({
                id: uuidv4(),
                codice: 'P' + i,
                appoggi: '',
                giunti: '',
                pulvini: '',
                antenne: '',
                dispositiviSismici: '',
              });
            }
          }
        }

        resultPile.map(el => {
          addSpallaPila({
            id: el.id,
            codice: el.codice,
            appoggi: el.appoggi,
            giunti: el.giunti,
            pulvini: el.pulvini,
            antenne: el.antenne,
            dispositiviSismici: el.dispositiviSismici,
          });
        });
        resultSpalle.map(el => {
          addSpallaPila({
            id: el.id,
            codice: el.codice,
            appoggi: el.appoggi,
            giunti: el.giunti,
            pulvini: el.pulvini,
            antenne: el.antenne,
            dispositiviSismici: el.dispositiviSismici,
          });
        });
      }

      const resultCampate = getValues('campataStrutturaTable');

      if (watchNCampate !== watch('campataStrutturaTable')?.length) {
        removeCampate();
        if (watchNCampate !== resultCampate.length) {
          if (watchNCampate < resultCampate.length) {
            for (let i = resultCampate.length - 1; i >= watchNCampate; i--) {
              resultCampate.pop();
            }
          } else {
            for (let i = resultCampate.length + 1; i <= watchNCampate; i++) {
              resultCampate.push({
                id: uuidv4(),
                campata: 'C' + i,
                travi: '',
                traversi: '',
                controventi: '',
                soletta: '',
                archi: '',
                piedritti: '',
                tiranti: '',
                elementiExtra: '',
              });
            }
          }
        }
        resultCampate.map(el => {
          addCampate({
            id: uuidv4(),
            campata: el.campata,
            travi: el.travi,
            traversi: el.traversi,
            controventi: el.controventi,
            soletta: el.soletta,
            archi: el.archi,
            piedritti: el.piedritti,
            tiranti: el.tiranti,
            elementiExtra: el.elementiExtra,
          });
        });
      }
    } else {
      setValue('nPileStruttura', '');
      setValue('nSpalleStruttura', '');
      removeSpallaPila();
      removeCampate();
    }
  }, [watchNCampate]);
}
export default useSpallePileCampate;
