import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaGiuntoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import GiuntiForm from './GiuntiForm';
const GiuntiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const spallePileCollegamentiTableGiuntiTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].giuntiTable`
  );

  const spallePileCollegamentiTable = watch(`spallePileCollegamentiTable`);
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const spallePileToCopy = spallePileCollegamentiTable?.map(
    el => el.giuntiTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsGiunti = item => {
    setValue('giuntiTableValue', [item]);
    setValue('tipologiaGiuntoTableValue', item.tipologiaGiunto);
  };

  const handleSelectAllClickGiunti = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable`
      );
      setSelected(selectedValue);
      setValue('giuntiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('giuntiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a3_tecnicoRilevatoreGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a3_dataIspezioneGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a3_elementoCriticoStrutturaGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_elementoCriticoSismicaGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaStrutturaGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaSismicaGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_noteGeneraliGiunti',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a3_tecnicoRilevatoreGiunti');
    const dataIspezione = getValues('l1t2a3_dataIspezioneGiunti');
    const elementoCriticoStruttura = getValues(
      'l1t2a3_elementoCriticoStrutturaGiunti'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a3_elementoCriticoSismicaGiunti'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a3_condizioneCriticaStrutturaGiunti'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a3_condizioneCriticaSismicaGiunti'
    );
    const noteGenerali = getValues('l1t2a3_noteGeneraliGiunti');
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].giuntiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a3_tecnicoRilevatoreGiunti`, '');
    setValue(`l1t2a3_dataIspezioneGiunti`, null);
    setValue(`l1t2a3_elementoCriticoStrutturaGiunti`, '');
    setValue(`l1t2a3_elementoCriticoSismicaGiunti`, '');
    setValue(`l1t2a3_condizioneCriticaStrutturaGiunti`, '');
    setValue(`l1t2a3_condizioneCriticaSismicaGiunti`, '');
    setValue(`l1t2a3_noteGeneraliGiunti`, '');
  };

  const dataGiuntiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaGiunto: { label: 'Tipologia', format: tipologiaGiuntoOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaGiunto: {
        label: 'Tipologia',
        format: tipologiaGiuntoOptions,
      },
    },
  };

  const spallePileCollegamentoSelezione = watch(
    'spallePileCollegamentoSelezione'
  );

  useEffect(() => {
    if (spallePileCollegamentoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [spallePileCollegamentoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'giunti'}
        infoDrawer={true}
        drawerTitle={INFO.collegamentiGiunti.drawerTitle}
        drawerText={INFO.collegamentiGiunti.drawerText}
        singleElementTitle={'giunto'}
        tableWatch={spallePileCollegamentiTableGiuntiTableWatch}
        tableWatchName={`spallePileCollegamentiTable[${selectedIdx}].giuntiTable`}
        elementForm={
          <GiuntiForm
            giuntiFields={spallePileCollegamentiTableGiuntiTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            giuntiTable={`spallePileCollegamentiTable[${selectedIdx}].giuntiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a3_tecnicoRilevatoreGiunti'}
            dateName={'l1t2a3_dataIspezioneGiunti'}
            elementoCriticoStrutturaName={
              'l1t2a3_elementoCriticoStrutturaGiunti'
            }
            elementoCriticoSismicaName={'l1t2a3_elementoCriticoSismicaGiunti'}
            condizioneCriticaStrutturaName={
              'l1t2a3_condizioneCriticaStrutturaGiunti'
            }
            condizioneCriticaSismicaName={
              'l1t2a3_condizioneCriticaSismicaGiunti'
            }
            noteGeneraliName={'l1t2a3_noteGeneraliGiunti'}
          />
        }
        tableConfig={dataGiuntiConfig}
        editRows={editRowsGiunti}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickGiunti}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaGiunto'}
        tableForCopy={spallePileToCopy}
      />
    </>
  );
};
GiuntiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default GiuntiSection;
