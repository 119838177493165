import { difetti } from 'constants/difetti';
import {
  convogliamentoAcque,
  //ImpostaFondPilaTableValue,
  paliIlluminazione,
  SchemaPonteCommonTableValue,
  SchemaPonteSpartiTrafficoTableValue,
  sottoservizi,
  TipologiaAntennaTableValue,
  TipologiaAppoggioTableValue,
  TipologiaArcoTableValue,
  TipologiaControventoTableValue,
  TipologiaElementoExtraTableValue,
  TipologiaGiuntoTableValue,
  TipologiaPiedrittoTableValue,
  TipologiaPilaTableValue,
  TipologiaPulvinoTableValue,
  TipologiaSolettaTableValue,
  TipologiaSpallaTableValue,
  TipologiaTiranteTableValue,
  TipologiaTraversoTableValue,
  TipologiaTraveTableValue,
} from 'constants/inspections';

const useSchedaDifetti = () => {
  /*
  A seguito dell'evolutiva 8-2025 non esiste più la distinzione tra matriceA e matriceB
  Le schede difetti per spalle e pile sono state unificate in un'unica matrice che non tiene 
  più conto delle imposta tipologie fondazione (81)
*/

  const matrice = {
    spalla: [
      {
        tipologia: TipologiaSpallaTableValue.Muratura,
        scheda: [
          37, 38, 39, 45, 74, 46, 75, 40, 76, 44, 47, 48, 49, 50, 51, 79, 80,
          82, 83, 84, 85, 77,
        ],
      },
      {
        tipologia: TipologiaSpallaTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 74, 3, 75, 4, 5, 6, 7, 8, 9, 10, 11, 12, 76, 79, 80, 82, 83, 84,
          85, 13, 77,
        ],
      },
      {
        tipologia: TipologiaSpallaTableValue.Nonrilevabile,
        scheda: [0],
      },
    ],
    pila: [
      {
        tipologia: TipologiaPilaTableValue.Muratura,
        scheda: [
          37, 38, 39, 45, 74, 46, 75, 40, 76, 44, 47, 48, 49, 50, 51, 79, 80,
          85, 77,
        ],
      },
      {
        tipologia: TipologiaPilaTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 74, 3, 75, 4, 5, 6, 7, 14, 8, 9, 10, 15, 11, 22, 16, 12, 76, 79,
          80, 85, 13, 77,
        ],
      },
      {
        tipologia: TipologiaPilaTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 75, 60, 61, 62, 76, 79, 80, 85,
        ],
      },
      {
        tipologia: TipologiaPilaTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    pulvino: [
      {
        tipologia: TipologiaPulvinoTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaPulvinoTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaPulvinoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    appoggio: [
      {
        tipologia: TipologiaAppoggioTableValue.Acciaio,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.AcciaioTeflon,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.Calcestruzzo,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.Gomma,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.GommaArmata,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.GommaTeflon,
        scheda: [
          86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 1, 2, 3, 5, 6, 8, 9, 10, 13,
          14, 15, 22, 96, 97, 98, 99,
        ],
      },
      {
        tipologia: TipologiaAppoggioTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    antenna: [
      {
        tipologia: TipologiaAntennaTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaAntennaTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 74,
          75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaAntennaTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaAntennaTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    giunto: [
      {
        tipologia: TipologiaGiuntoTableValue.Pettini,
        scheda: [100, 101, 102, 103, 104, 105, 106, 107, 108],
      },
      {
        tipologia: TipologiaGiuntoTableValue.ProfilatiElastomerici,
        scheda: [100, 101, 102, 103, 104, 105, 106, 107, 108],
      },
      {
        tipologia: TipologiaGiuntoTableValue.ReteNellaPavimentazione,
        scheda: [100, 101, 102, 103, 104, 105, 106, 107, 108],
      },
      {
        tipologia: TipologiaGiuntoTableValue.StrisceInGomma,
        scheda: [100, 101, 102, 103, 104, 105, 106, 107, 108],
      },
      {
        tipologia: TipologiaGiuntoTableValue.TamponiOGiuntiSottopavimentazione,
        scheda: [100, 101, 102, 103, 104, 105, 106, 107, 108],
      },
      {
        tipologia: TipologiaGiuntoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    soletta: [
      {
        tipologia: TipologiaSolettaTableValue.CalcestruzzoArmato,
        scheda: [1, 2, 74, 3, 4, 5, 6, 7, 10, 17, 18, 24, 12],
      },
      {
        tipologia: TipologiaSolettaTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 74,
          75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaSolettaTableValue.Legno,
        scheda: [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      },
      {
        tipologia: TipologiaSolettaTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    trave: [
      {
        tipologia: TipologiaTraveTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 74, 3, 20, 75, 78, 4, 5, 6, 14, 7, 10, 18, 17, 21, 15, 22, 12,
          76, 16, 23,
        ],
      },
      {
        tipologia: TipologiaTraveTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 74, 3, 20, 75, 78, 4, 5, 6, 25, 26, 27, 28, 29, 30, 31, 32, 7,
          10, 18, 21, 15, 22, 12, 33, 34, 35, 36, 76, 16, 23,
        ],
      },
      {
        tipologia: TipologiaTraveTableValue.AcciaioOMetallo,
        scheda: [52, 53, 54, 55, 56, 57, 58, 59, 75, 78, 60, 61, 62],
      },
      {
        tipologia: TipologiaTraveTableValue.Legno,
        scheda: [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      },
      {
        tipologia: TipologiaTraveTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    traverso: [
      {
        tipologia: TipologiaTraversoTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 74, 3, 20, 75, 78, 4, 5, 6, 14, 7, 10, 18, 17, 21, 15, 22, 12,
          76, 16, 23,
        ],
      },
      {
        tipologia: TipologiaTraversoTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 74, 3, 20, 75, 78, 4, 5, 6, 25, 26, 27, 28, 29, 30, 31, 32, 7,
          10, 18, 21, 15, 22, 12, 33, 34, 35, 36, 76, 16, 23,
        ],
      },
      {
        tipologia: TipologiaTraversoTableValue.AcciaioOMetallo,
        scheda: [52, 53, 54, 55, 56, 57, 58, 59, 75, 78, 60, 61, 62],
      },
      {
        tipologia: TipologiaTraversoTableValue.Legno,
        scheda: [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      },
      {
        tipologia: TipologiaTraversoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    controvento: [
      {
        tipologia: TipologiaControventoTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia:
          TipologiaControventoTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 74,
          75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaControventoTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaControventoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    arco: [
      {
        tipologia: TipologiaArcoTableValue.Muratura,
        scheda: [
          37, 38, 39, 43, 45, 74, 46, 40, 44, 47, 48, 49, 50, 51, 42, 41,
        ],
      },
      {
        tipologia: TipologiaArcoTableValue.CalcestruzzoArmato,
        scheda: [1, 2, 74, 3, 4, 5, 6, 7, 10, 17, 18, 15, 19, 12, 76],
      },
      {
        tipologia: TipologiaArcoTableValue.Acciaio,
        scheda: [52, 53, 54, 55, 56, 57, 58, 59, 75, 60, 61, 62],
      },
      {
        tipologia: TipologiaArcoTableValue.Legno,
        scheda: [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      },
      {
        tipologia: TipologiaArcoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    piedritto: [
      {
        tipologia: TipologiaPiedrittoTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 3, 74, 4, 5, 6, 7, 8, 9, 13, 10, 15, 11, 22, 16, 12, 76, 79,
        ],
      },
      {
        tipologia: TipologiaPiedrittoTableValue.AcciaioOMetallo,
        scheda: [52, 53, 54, 55, 56, 57, 58, 59, 75, 60, 61, 62, 76, 79],
      },
      {
        tipologia: TipologiaPiedrittoTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    tirante: [
      {
        tipologia: TipologiaTiranteTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 74,
          75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaTiranteTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaTiranteTableValue.NonRilevabile,
        scheda: [0],
      },
    ],
    elementoExtra: [
      {
        tipologia: TipologiaElementoExtraTableValue.Muratura,
        scheda: [
          37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 74, 75,
          76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaElementoExtraTableValue.CalcestruzzoArmato,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia:
          TipologiaElementoExtraTableValue.CalcestruzzoArmatoPrecompresso,
        scheda: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 74,
          75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaElementoExtraTableValue.AcciaioOMetallo,
        scheda: [
          52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 74, 75, 76, 77, 78, 79,
        ],
      },
      {
        tipologia: TipologiaElementoExtraTableValue.Legno,
        scheda: [
          63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
        ],
      },
    ],
  };

  const matriceScheda20 = {
    cordolo: [
      {
        presenza: SchemaPonteCommonTableValue.Uno,
        scheda: [110, 1, 2, 74, 3, 4, 5, 6, 10, 18, 76],
      },
      {
        presenza: SchemaPonteCommonTableValue.Assente,
        scheda: ['assente'],
      },
      {
        presenza: SchemaPonteCommonTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    marciapiede: [
      {
        presenza: SchemaPonteCommonTableValue.Uno,
        scheda: [116],
      },
      {
        presenza: SchemaPonteCommonTableValue.Assente,
        scheda: ['assente'],
      },
      {
        presenza: SchemaPonteCommonTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    pavimentazione: [
      {
        presenza: SchemaPonteCommonTableValue.Uno,
        scheda: ['109.a', '109.b', '109.c'],
      },
      {
        presenza: SchemaPonteCommonTableValue.Assente,
        scheda: ['assente'],
      },
      {
        presenza: SchemaPonteCommonTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    spartitraffico: [
      {
        presenza: SchemaPonteSpartiTrafficoTableValue.Uno,
        scheda: [122],
      },
      {
        presenza: SchemaPonteSpartiTrafficoTableValue.Due,
        scheda: [122],
      },
      {
        presenza: SchemaPonteSpartiTrafficoTableValue.Tre,
        scheda: [122],
      },
      {
        presenza: SchemaPonteSpartiTrafficoTableValue.Assente,
        scheda: ['assente'],
      },
      {
        presenza: SchemaPonteSpartiTrafficoTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    guardavia: [
      {
        presenza: SchemaPonteCommonTableValue.Uno,
        scheda: ['121.a', '121.b', '121.c', '121.d'],
      },
      {
        presenza: SchemaPonteCommonTableValue.Assente,
        scheda: [120],
      },
      {
        presenza: SchemaPonteCommonTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    parapetto: [
      {
        presenza: SchemaPonteCommonTableValue.Uno,
        scheda: ['121.a', '121.b', '121.c', '121.d'],
      },
      {
        presenza: SchemaPonteCommonTableValue.Assente,
        scheda: [120],
      },
      {
        presenza: SchemaPonteCommonTableValue.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    paloIlluminazione: [
      {
        presenza: paliIlluminazione.Presenti,
        scheda: ['117.a', '117.b', 118],
      },
      {
        presenza: paliIlluminazione.Assenti,
        scheda: ['assente'],
      },
      {
        presenza: paliIlluminazione.NonPrevisti,
        scheda: ['nonPrevisto'],
      },
    ],
    convogliamentoAcque: [
      {
        presenza: convogliamentoAcque.Presente,
        scheda: [112, 113, 114, 115],
      },
      {
        presenza: convogliamentoAcque.Assente,
        scheda: [111],
      },
      {
        presenza: convogliamentoAcque.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
    sottoservizio: [
      {
        presenza: sottoservizi.Presente,
        scheda: [119],
      },
      {
        presenza: sottoservizi.Assente,
        scheda: ['assente'],
      },
      {
        presenza: sottoservizi.NonPrevisto,
        scheda: ['nonPrevisto'],
      },
    ],
  };

  const populateSchedaDifetti = (elemento, tipologia) => {
    if (!tipologia) {
      return [{ alert: true }];
    }

    const tmp_arr = [];
    matrice[elemento]
      .find(mtrx => mtrx.tipologia === tipologia)
      ?.scheda.forEach(el => {
        tmp_arr.push(difetti.find(o => o.id == el));
      });
    return tmp_arr;

    // if (!fondazione && (elemento == 'spalla' || elemento == 'pila')) {
    //   return [{ alertFondazione: true }];
    // }
    // if (fondazione === ImpostaFondPilaTableValue.Indiretta) {
    //   const tmp_arr = [];
    //   matriceA[elemento]
    //     .find(mtrx => mtrx.tipologia === tipologia)
    //     .scheda.forEach(el => {
    //       tmp_arr.push(difetti.find(o => o.id == el));
    //     });
    //   return tmp_arr;

    // } else {
    //   const tmp_arr = [];
    //   matriceB[elemento]
    //     .find(mtrx => mtrx.tipologia === tipologia)
    //     .scheda.forEach(el => {
    //       tmp_arr.push(difetti.find(o => o.id == el));
    //     });
    //   return tmp_arr;
    // }
  };

  const populateScheda20Difetti = (elemento, presenza) => {
    const tmp_arr = [];
    if (!presenza) {
      return [{ alert: true }];
    }
    matriceScheda20[elemento]
      .find(mtrx => mtrx.presenza === presenza)
      .scheda.forEach(el => {
        tmp_arr.push(difetti.find(o => o.id == el));
      });
    return tmp_arr;
  };

  return { populateSchedaDifetti, populateScheda20Difetti };
};
export default useSchedaDifetti;
