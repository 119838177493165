export const projectPrefix = process.env.REACT_APP_PROJECT_API_PREFIX;
import { makePath } from 'utils/navigation';

export const COLLABORATORS_ROOT = 'collaborators';
export const INSPECTIONS_ROOT = 'inspections';
export const NOTIFICATIONS_ROOT = 'notifications';
export const ANALYTICS_ROOT = 'analytics';
export const EXPORTS_ROOT = 'exports';
export const DELIVERIES_ROOT = 'deliveries';
export const BRIDGES_ROOT = 'bridges';
export const ANNOTATIONS_ROOT = 'annotations';

export const apiInspections = {
  ROOT: INSPECTIONS_ROOT,
  MY_PERMISSIONS: '/myPermissions',
  ARCHIVE: '/archive',
};

export const apiStructures = {
  ROOT: 'structures',
};

export const apiBridges = {
  ROOT: 'bridges',
  INSPECTIONS: 'bridges/inspections',
  INSPECTIONS_ARCHIVE: '/inspections',
  MAP: 'bridges/map',
  MAP_COMPRESSED: 'bridges/v2/map',
  IMAGES: 'images',
  DOCUMENTS: 'documents',
  MY_PERMISSIONS: '/myPermissions',
  LAST_INSPECTION_STATUS: '/lastInspectionStatus',
  CHANGE_OWNER: makePath(BRIDGES_ROOT, '/change-owner'),
  USER_BASED_LAST_VALID_INSPECTION: '/user-last-valid-inspection',
  ACCESSIBLE: makePath(BRIDGES_ROOT, '/accessible'),
};

export const apiMedia = {
  ROOT: 'medias',
  LIST: 'medias/inspections',
  DOCUMENTS: 'medias/documents',
  IMAGES: 'medias/images',
};

export const apiGeo = {
  ROOT: 'geo/',
  CAPS: 'caps',
  COUNCILS: 'councils',
  PROVINCES: 'provinces',
  REGIONS: 'regions',
};

export const apiUsers = {
  ROOT: 'users',
  DEMO: 'demo/users',
  USERS_ME: 'users/me',
  EDIT: '/edit',
  CREATE: 'users/create',
  DELETIONINFO: 'users/deletionInfo',
  CANCELDELETION: 'users/cancel-deletion',
  RESETPASSWORD: 'users/reset-password',
  TRACK_PASSWORD_SET: 'users/:mail/track-password-set',
};

export const apiContactUsInternal = {
  ROOT: 'ses/contactUsInternal',
};

export const apiCollaborators = {
  ROOT: COLLABORATORS_ROOT,
  STRUCTURE_COLLBAORATORS: makePath(COLLABORATORS_ROOT, '/structures'),
  INSPECTIONS_COLLBAORATORS: makePath(COLLABORATORS_ROOT, '/inspections'),
  CHECK_INVITABILITY: makePath(COLLABORATORS_ROOT, '/checkInvitability'),
  COLLABORATION: makePath(COLLABORATORS_ROOT, '/collaboration'),
  NETWORK_EXTERNAL: makePath(COLLABORATORS_ROOT, '/network-external'),
  REGISTERED_EXTERNAL: '/registeredExternal',
  STRUCTURE_SHARE_INFORMATION: '/structuresShareInformation',
};

export const apiNotifications = {
  ROOT: NOTIFICATIONS_ROOT,
  READ: '/read',
  READALL: '/readAll',
};

export const apiAnalytics = {
  CDA: makePath(ANALYTICS_ROOT, '/istogramma-classi-attenzione'),
  MONITORING: makePath(ANALYTICS_ROOT, '/istogramma-aspetti-da-monitorare'),
  RISK: makePath(ANALYTICS_ROOT, '/risk-and-criticality-histogram'),
  REGION: makePath(ANALYTICS_ROOT, '/ponti-per-regione'),
};

export const apiExports = {
  ROOT: EXPORTS_ROOT,
  SINTESI_CENSIMENTI_MULTIPLE: makePath(
    EXPORTS_ROOT,
    '/sintesiCensimentiIspezioniMultiple'
  ),
  MULTIPLE_CSV_GENERIC_EXPORT: makePath(
    EXPORTS_ROOT,
    '/multiple-generic-export'
  ),
  CSV_GENERIC_EXPORT: '/generic-export',
  ADMIN_BRIDGES: makePath(EXPORTS_ROOT, '/admin-bridges'),
  AINOP_ANAGRAFICA: makePath(EXPORTS_ROOT, '/multiple-ainop-anagrafica-base'),
  AINOP_PONTE_VIADOTTO_STRADALE: makePath(
    EXPORTS_ROOT,
    '/multiple-ainop-ponte-viadotto-stradale'
  ),
};

export const apiDeliveries = {
  ROOT: DELIVERIES_ROOT,
  CHECK_INSPECTION_DELIVERY_DESTINATION: makePath(
    DELIVERIES_ROOT,
    '/check-inspection-delivery-destination'
  ),
  CHECK_BRIDGE_OWNER_AND_SHARE: makePath(
    DELIVERIES_ROOT,
    '/check-bridge-owner-and-share'
  ),
  GET_STATUS: makePath(DELIVERIES_ROOT, '/get-status'),
  CANCEL: makePath(DELIVERIES_ROOT, '/cancel'),
};

export const apiAnnotations = {
  ROOT: ANNOTATIONS_ROOT,
  GET_ANNOTATIONS_STRUCTURE: makePath(ANNOTATIONS_ROOT, '/structure'),
};

export const apiResponseStatus = {
  OK: 1,
  ERROR: 2,
};
