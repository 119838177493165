import { Button, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as MediaIcon } from 'assets/icons/edit-image.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg';
import {
  DrawerCopy,
  DrawerMedia,
  GridWrap,
  InfoWrapper,
  LocalTourWrapper,
  SchedaDifettiElementiAccessori,
  Table,
} from 'components';
import { FieldType, MediaGroup, UploadMedia } from 'constants/inspections';
import { useInspectionPath } from 'hooks';
import useCopy from 'hooks/inspections/L1/superstructures/useCopy';
import { INFO } from 'pages/Inspections/config/info';
import { useInspectionSelector } from 'stores';
import { generateCopySchedaDifettiSteps } from 'utils';
import { populateSchedeDifettiTableActions } from 'utils/Inspections/L1/schedeDifettiTableActions';

const ElementiAccessoriL1SectionWrapper = ({
  elementTitle,
  singleElementTitle,
  tableWatch,
  tableWatchName,
  elementForm,
  tableConfig,
  editRows,
  inspectionInfoForm,
  handleSelectAllClick,
  selected,
  setSelected,
  drawerMediaIndex,
  setDrawerMediaIndex,
  schedaDifettiIndex,
  setSchedaDifettiIndex,
  open,
  setOpen,
  openSchedaDifetti,
  setOpenSchedaDifetti,
  copyTableConfig,
  copyType,
  tableForCopy = [],
}) => {
  const { getValues } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  // console.log(tableWatch, 'tableWatch');
  // console.log(tableForCopy, 'tableForCopy');

  let infoMediaDrawer;
  if (elementTitle) {
    switch (elementTitle) {
      case 'pavimentazione':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoPavimento.drawerTitle,
          drawerText: INFO.impalcatoPavimento.drawerText,
        };
        break;

      case 'cordoli':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoCordoli.drawerTitle,
          drawerText: INFO.impalcatoCordoli.drawerText,
        };
        break;
      case 'marciapiedi':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoMarciapiede.drawerTitle,
          drawerText: INFO.impalcatoMarciapiede.drawerText,
        };
        break;

      case 'spartitraffico':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoSpartitraffico.drawerTitle,
          drawerText: INFO.impalcatoSpartitraffico.drawerText,
        };
        break;

      case 'guardavie':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoGuardavie.drawerTitle,
          drawerText: INFO.impalcatoGuardavie.drawerText,
        };
        break;
      case 'parapetti':
        infoMediaDrawer = {
          drawerTitile: INFO.impalcatoParapetti.drawerTitle,
          drawerText: INFO.impalcatoParapetti.drawerText,
        };
        break;
    }
  }

  const {
    copyDrawerOpen,
    setCopyDrawerOpen,
    selectedPaste,
    setSelectedPaste,
    selectedCopy,
    setSelectedCopy,
    handleSelectAllPaste,
    checkCompatibility,
    handleCopy,
  } = useCopy({
    tableName: tableWatchName,
    type: copyType,
    tableForCopy: tableForCopy,
  });

  const tourId = `l1-elementi-accessori-${singleElementTitle}`;

  return (
    <GridWrap container spacing={2} style={{ marginTop: 55 }}>
      <InfoWrapper
        isDivider={true}
        infoDrawer={infoMediaDrawer ? true : false}
        drawerTitle={infoMediaDrawer?.drawerTitile}
        drawerText={infoMediaDrawer?.drawerText}
        size={12}
      >
        <Divider>{elementTitle}</Divider>
      </InfoWrapper>
      {tableWatch?.length === 0 && (
        <GridWrap item xs={12} marginTop={-10}>
          <Typography>
            Nessun/a {singleElementTitle} previsto in questo elemento
          </Typography>
        </GridWrap>
      )}

      {tableWatch?.length > 0 && (
        <>
          <GridWrap item xs={12} marginTop={-10}>
            {!inspectionDisabled && !isViewingInspection && elementForm}
          </GridWrap>
          <GridWrap item xs={12}>
            <Table
              data={tableWatch || []}
              config={tableConfig}
              hasCheckbox={!inspectionDisabled && !isViewingInspection}
              rowActions={
                !inspectionDisabled && !isViewingInspection
                  ? [
                      {
                        onClick: item => {
                          editRows(item);
                          setSelected([item]);
                        },
                        icon: <EditIcon />,
                      },
                    ]
                  : []
              }
              mediaActions={[
                {
                  onClick: item => {
                    setDrawerMediaIndex(
                      tableWatch.findIndex(el => el.id === item.id)
                    );
                    setOpen(true);
                  },
                  icon: <MediaIcon />,
                },
              ]}
              schedaDifettiActions={populateSchedeDifettiTableActions(
                schedaDifettiIndex,
                tableWatch,
                setOpenSchedaDifetti,
                setSchedaDifettiIndex
              )}
              selected={selected}
              onSelectAllClick={handleSelectAllClick}
              setSelected={setSelected}
            />
          </GridWrap>

          {schedaDifettiIndex !== undefined && openSchedaDifetti && (
            <>
              <InfoWrapper
                isDivider={true}
                size={12}
                infoDrawer={true}
                drawerTitle={INFO.schedaIspezioni20PonteDifettiInfo.drawerTitle}
                drawerText={INFO.schedaIspezioni20PonteDifettiInfo.drawerText}
                button={
                  <>
                    {copyTableConfig &&
                      !inspectionDisabled &&
                      !isViewingInspection && (
                        <>
                          <LocalTourWrapper
                            style={{
                              padding: 5,
                              fontSize: 12,
                              height: 25,
                              marginLeft: 5,
                            }}
                            steps={generateCopySchedaDifettiSteps(tourId)}
                          />
                          <Button
                            style={{
                              padding: 14,
                              fontSize: 14,
                              height: 25,
                              marginLeft: 5,
                            }}
                            className={`${tourId}`}
                            onClick={() => {
                              setCopyDrawerOpen(true);
                              setSelectedPaste([]);
                              setSelectedCopy(
                                getValues(
                                  `${tableWatchName}[${schedaDifettiIndex}]`
                                )
                              );
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Copia scheda ispezione
                          </Button>
                        </>
                      )}
                  </>
                }
              >
                <Divider>
                  {tableWatch[schedaDifettiIndex].codice} - Scheda Ispezione
                  Ponti - Elementi Accessori - {elementTitle}
                </Divider>
              </InfoWrapper>
              {!tableWatch[schedaDifettiIndex].schedaDifetti[0].alert && (
                <>{inspectionInfoForm}</>
              )}
              <GridWrap item xs={12}>
                <SchedaDifettiElementiAccessori
                  key={tableWatch[schedaDifettiIndex].codice}
                  scheda={tableWatch[schedaDifettiIndex].schedaDifetti}
                  name={`${tableWatchName}[${schedaDifettiIndex}]`}
                  containerName={tableWatchName}
                />
              </GridWrap>
            </>
          )}
        </>
      )}
      <DrawerMedia
        open={open}
        setOpen={setOpen}
        title={elementTitle}
        infoPanel="Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3."
        dataType="video/*,image/*"
        uploadType={UploadMedia.media}
        type={FieldType.images}
        formName={`${tableWatchName}[${drawerMediaIndex}].media`}
        mediaGroup={[MediaGroup.Sovrastruttura]}
      />
      <DrawerCopy
        title={`Copia ${getValues(
          `${tableWatchName}[${schedaDifettiIndex}].codice`
        )} - Scheda ispezioni ponti`}
        subtitle={
          'Tutti gli elementi su cui le informazioni saranno copiate saranno sovrascritti. Eventuali media o schede di ispezione a loro associati saranno resettati.'
        }
        open={copyDrawerOpen}
        setOpen={setCopyDrawerOpen}
        tableConfig={copyTableConfig}
        // copyTableData={
        //   tableWatch?.filter(el => el.codice == selectedCopy.codice) || []
        // }
        // pasteTableData={
        //   tableWatch?.filter(el => el.codice != selectedCopy.codice) || []
        // }
        copyTableData={
          tableForCopy
            ?.flat()
            ?.filter(el => el.codice == selectedCopy.codice) || []
        }
        pasteTableData={
          tableForCopy
            ?.flat()
            ?.filter(el => el.codice != selectedCopy.codice) || []
        }
        selectedCopy={selectedCopy}
        selectedPaste={selectedPaste}
        handleSelectAll={handleSelectAllPaste}
        setSelectedPaste={setSelectedPaste}
        checkCompatibility={checkCompatibility}
        handleCopy={handleCopy}
      />
    </GridWrap>
  );
};

ElementiAccessoriL1SectionWrapper.propTypes = {
  selectedIdx: PropTypes.number,
  elementTitle: PropTypes.string,
  infoDrawer: PropTypes.bool,
  drawerTitle: PropTypes.string,
  drawerText: PropTypes.string,
  singleElementTitle: PropTypes.string,
  tableWatch: PropTypes.array,
  tableWatchName: PropTypes.string,
  elementForm: PropTypes.node,
  inspectionInfoForm: PropTypes.node,
  tableConfig: PropTypes.array,
  editRows: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  drawerMediaIndex: PropTypes.number,
  setDrawerMediaIndex: PropTypes.func,
  schedaDifettiIndex: PropTypes.number,
  setSchedaDifettiIndex: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  openSchedaDifetti: PropTypes.bool,
  setOpenSchedaDifetti: PropTypes.func,
  copyTableConfig: PropTypes.object,
  copyType: PropTypes.string,
  tableForCopy: PropTypes.array,
};
export { ElementiAccessoriL1SectionWrapper };
