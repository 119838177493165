import { mappingOutCommon } from './mappingOutCommon';

export const mappingDataOutL0 = data => {
  const diagnosisData = {
    common: { ...mappingOutCommon(data) },
    L0: {
      codiceInternoProprietario: data.codiceInternoProprietario || null,
      classeDiConseguenza: data.classeDiConseguenza || null,
      tipologiaCollegamento: data.tipologiaCollegamento || null,
      estesa: data.estesa || null,
      siglaStradaAppartenenza: data.siglaStradaAppartenenza || null,
      tipologiaEnteScavalcato: data.tipologiaEnteScavalcato || null,
      tipologiaEnteScavalcatoAltro: data.tipologiaEnteScavalcatoAltro || null,
      spiegazioneConseguenza: data.spiegazioneConseguenza || null,
      livelloConoscenza: data.livelloConoscenza || null,
      luceComplessivaSpalla: data.luceComplessivaSpalla || null,
      tracciato: data.tracciato || null,
      altezzaUtileSottoImpalcato: data.altezzaUtileSottoImpalcato || null,
      proprietario: data.proprietario || null,
      concessionario: data.concessionario || null,
      enteVigilante: data.enteVigilante || null,
      autoritaDistrettuale: data.autoritaDistrettuale || null,
      bacinoIdrografico: data.bacinoIdrografico || null,
      tutelaDLgs: data.tutelaDLgs || null,
      provvedimentiDiTutela: data.provvedimentiDiTutela || null,
      autoreDellaProgettazione: data.autoreDellaProgettazione || null,
      inserimentoNeiPianiPaesaggistici:
        data.inserimentoNeiPianiPaesaggistici || null,
      necessitaIspezioniSpecialiRischioFrane:
        data.necessitaIspezioniSpecialiRischioFrane || null,
      risultatiIspezioniSpecialiFrane:
        data.risultatiIspezioniSpecialiFrane || null,
      pericolositaPAIPSAIFrane: data.pericolositaPAIPSAIFrane || null,
      necessitaIspezioniSpecialiRischioIdraulico:
        data.necessitaIspezioniSpecialiRischioIdraulico || null,
      risultatiIspezioniSpecialiRischioIdraulico:
        data.risultatiIspezioniSpecialiRischioIdraulico || null,
      pericolositaPAIPSAIRischioIdraulico:
        data.pericolositaPAIPSAIRischioIdraulico || null,
      progettista: data.progettista || null,
      annoProgettazione: data.annoProgettazione || null,
      dataEffettivaAnnoDiProgettazione:
        data.dataEffettivaAnnoDiProgettazione || null,
      // annoCostruzioneFiltrabile: annoCostruzioneFiltrabile || null,
      normaProgetto: data.normaProgetto || null,
      annoCostruzione: data.annoCostruzione || null,
      dataEffettivaAnnoDiCostruzione:
        data.dataEffettivaAnnoDiCostruzione || null,
      annoDiEntrataInEsercizio: data.annoDiEntrataInEsercizio || null,
      dataDiEntrataInEsercizio: data.dataDiEntrataInEsercizio || null,
      interventiSignificativiManutenzione:
        data.interventiSignificativiManutenzione || null,
      annoInterventiSignificativiRicostruzione:
        data.annoInterventiSignificativiRicostruzione || null,
      interventiSignificativiDataEffettiva:
        data.interventiSignificativiDataEffettiva || null,
      dataPubblicazioneNorma: data.dataPubblicazioneNorma || null,
      enteApprovatore: data.enteApprovatore || null,
      ispezioniPregresse: data.ispezioniPregresse || null,
      segnalazioni: data.segnalazioni || null,
      interventiEseguiti: data.interventiEseguiti || null,
      attivitaMonitoraggioPregresseInCorso:
        data.attivitaMonitoraggioPregresseInCorso || null,
      interventiManutenzione: data.interventiManutenzione || null,
      progettoDiIncrementoDelGradoDiSicurezza:
        data.progettoDiIncrementoDelGradoDiSicurezza || null,
      interventiDiIncrementoDelGradoDiSicurezza:
        data.interventiDiIncrementoDelGradoDiSicurezza || null,
      classificazioneUsoStradale: data.classificazioneUsoStradale || null,
      competenzaInfrastruttura: data.competenzaInfrastruttura || null,
      itinerarioInternazionale: data.itinerarioInternazionale || null,
      reteTEN: data.reteTEN || null,
      itinerarioEmergenza: data.itinerarioEmergenza || null,
      trasportiEccezionali: data.trasportiEccezionali || null,
      disponibilitaStudiTrasportistici:
        data.disponibilitaStudiTrasportistici || null,
      nCarreggiate: data.nCarreggiate || null,
      nCorsieCarreggiata: data.nCorsieCarreggiata || null,
      larghezzaCarreggiata: data.larghezzaCarreggiata || null,
      presenzaCurve: data.presenzaCurve || null,
      veicoliGiornoTGM: data.veicoliGiornoTGM || null,
      valoreEffettivoTGM: data.valoreEffettivoTGM || null,
      veicoliCommercialiGiornoTGM: data.veicoliCommercialiGiornoTGM || null,
      valoreEffettivoCommercialiTGM: data.valoreEffettivoCommercialiTGM || null,
      veicoliCommercialITGMSingolaCorsia:
        data.veicoliCommercialITGMSingolaCorsia || null,
      valoreEffettivoCommercialiCorsiaTGM:
        data.valoreEffettivoCommercialiCorsiaTGM || null,
      durataDeviazioneKm: data.durataDeviazioneKm || null,
      durataDeviazioneMin: data.durataDeviazioneMin || null,
      categoriaPercorsoAlternativoIndividuato:
        data.categoriaPercorsoAlternativoIndividuato || null,
      fotoCopertina: data.fotoCopertina || null,
      unitaFisiografica: data.unitaFisiografica || null,
      aerosolMarini: data.aerosolMarini || null,
      maggioreDegrado: data.maggioreDegrado || null,
      fotoInquadramento: data.fotoInquadramento || null,
      documentiContesto: data.documentiContesto || null,
      sismicitaArea: data.sismicitaArea || null,
      sismicitaValoreEffettivo: data.sismicitaValoreEffettivo || null,
      coordinateCTR: data.coordinateCTR || null,
      scala: data.scala || null,
      numeroToponimo: data.numeroToponimo || null,
      localita: data.localita || null,
      sistemaDiRiferimento: data.sistemaDiRiferimento || null,
      sistemaDiRiferimentoAltro: data.sistemaDiRiferimentoAltro || null,
      ellissoideDiRiferimento: data.ellissoideDiRiferimento || null,
      quotaCentro: data.quotaCentro || null,
      quotaIniziale: data.quotaIniziale || null,
      latitudineIniziale: data.latitudineIniziale || null,
      longitudineIniziale: data.longitudineIniziale || null,
      quotaFinale: data.quotaFinale || null,
      latitudineFinale: data.latitudineFinale || null,
      longitudineFinale: data.longitudineFinale || null,
      documentiAmministrativi: data.documentiAmministrativi || null,
      corrispondenzaEntiTerzi: data.corrispondenzaEntiTerzi || null,
      documentiInerentiCondizioniRischioFrane:
        data.documentiInerentiCondizioniRischioFrane || null,
      fotoInquadramentoFrana: data.fotoInquadramentoFrana || null,
      stralcioMappaPericolositàFranePAI:
        data.stralcioMappaPericolositàFranePAI || null,
      documentazioneIspezioniSpeciali:
        data.documentazioneIspezioniSpeciali || null,
      stralcioCartografico: data.stralcioCartografico || null,
      satellitareFotoAereeStoriche: data.satellitareFotoAereeStoriche || null,
      satellitareFotoAereeAttuale: data.satellitareFotoAereeAttuale || null,
      ripreseFotografiche: data.ripreseFotografiche || null,
      documentazioneIspezioniSpecialiIdraulica:
        data.documentazioneIspezioniSpecialiIdraulica || null,
      stralcioMappaPericolositàIdraulicaPAI:
        data.stralcioMappaPericolositàIdraulicaPAI || null,
      documentiInerentiCondizioniRischioIdraulico:
        data.documentiInerentiCondizioniRischioIdraulico || null,
      cartografiaTematicaAutoritaDistretto:
        data.cartografiaTematicaAutoritaDistretto || null,
      fotoInquadramentoIdraulica: data.fotoInquadramentoIdraulica || null,
      strumentiFinanziamento: data.strumentiFinanziamento || null,
      pianiStrumentiProgrammazione: data.pianiStrumentiProgrammazione || null,
      documentiDescrittivi: data.documentiDescrittivi || null,
      documentiGrafici: data.documentiGrafici || null,
      documentiIterApprovazione: data.documentiIterApprovazione || null,
      documentiDescrittiviDefinitivo:
        data.documentiDescrittiviDefinitivo || null,
      documentiGraficiDefinitivo: data.documentiGraficiDefinitivo || null,
      documentiIterApprovazioneDefinitivo:
        data.documentiIterApprovazioneDefinitivo || null,
      documentiDescrittiviCantinerabile:
        data.documentiDescrittiviCantinerabile || null,
      documentiGraficiCantinerabile: data.documentiGraficiCantinerabile || null,
      documentiIterApprovazioneCantinerabile:
        data.documentiIterApprovazioneCantinerabile || null,
      documentiContabili: data.documentiContabili || null,
      graficiAllegatiContabilitaAppalto:
        data.graficiAllegatiContabilitaAppalto || null,
      documentiContrattuali: data.documentiContrattuali || null,
      documentiContabiliRealizzazione:
        data.documentiContabiliRealizzazione || null,
      documentiDescrittiviVarianti: data.documentiDescrittiviVarianti || null,
      documentiGraficiVarianti: data.documentiGraficiVarianti,
      documentiContrattualiContabili:
        data.documentiContrattualiContabili || null,
      relazioneCollaudo: data.relazioneCollaudo || null,
      allegatiRelazioneCollaudo: data.allegatiRelazioneCollaudo || null,
      documentiIspezione: data.documentiIspezione || null,
      documentiSegnalazione: data.documentiSegnalazione || null,
      documentiInterventoEseguito: data.documentiInterventoEseguito || null,
      documentiAttivitaMonitoraggio: data.documentiAttivitaMonitoraggio || null,
      documentiInterventoManutenzione:
        data.documentiInterventoManutenzione || null,
      documentiDelProgettoDiIncrementoDelGradoDiSicurezza:
        data.documentiDelProgettoDiIncrementoDelGradoDiSicurezza || null,
      documentiDegliInterventiDiIncrementoDelGradoDiSicurezza:
        data.documentiDegliInterventiDiIncrementoDelGradoDiSicurezza || null,
      richiestePermessiTrasportiEccezionali:
        data.richiestePermessiTrasportiEccezionali || null,
      documentazioneStudiTrasportistici:
        data.documentazioneStudiTrasportistici || null,
      documentazioneEffettiCarichiDaTraffico:
        data.documentazioneEffettiCarichiDaTraffico || null,
      necessitaIspezioniSpecialiRischioStruttura:
        data.necessitaIspezioniSpecialiRischioStruttura || null,
      risultatiIspezioniSpeciali: data.risultatiIspezioniSpeciali || null,
      documentiIspezioniSpecialiRischioStruttura:
        data.documentiIspezioniSpecialiRischioStruttura || null,
      sezioneTrasversale: data.sezioneTrasversale || null,
      sezioneLongitudinale: data.sezioneLongitudinale || null,
      pianta: data.pianta || null,
      elaboratiGrafici: data.elaboratiGrafici || null,
      rilievo3D: data.rilievo3D || null,
      descrizioneOpera: data.descrizioneOpera || null,
      fotoDescrizioneOpera: data.fotoDescrizioneOpera || null,
      descrizioneContestoAmbientale: data.descrizioneContestoAmbientale || null,
      fotoContestoAmbientale: data.fotoContestoAmbientale || null,
      descrizioneRischioFrane: data.descrizioneRischioFrane || null,
      fotoRischioFrane: data.fotoRischioFrane || null,
      descrizioneRischioIdraulico: data.descrizioneRischioIdraulico || null,
      fotoRischioIdraulico: data.fotoRischioIdraulico || null,
      descrizioneDocumentazione: data.descrizioneDocumentazione || null,
      fotoDocumentazione: data.fotoDocumentazione || null,
      descrizioneAttivitaPregresse: data.descrizioneAttivitaPregresse || null,
      fotoAttivitaPregresse: data.fotoAttivitaPregresse || null,
      descrizioneStruttura: data.descrizioneStruttura || null,
      fotoDescrizioneStruttura: data.fotoDescrizioneStruttura || null,
      descrizioneSovrastruttura: data.descrizioneSovrastruttura || null,
      fotoDescrizioneSovrastruttura: data.fotoDescrizioneSovrastruttura || null,
      descrizioneServizi: data.descrizioneServizi || null,
      fotoDescrizioneServizi: data.fotoDescrizioneServizi || null,
      normaProgettoEffettivo: data.normaProgettoEffettivo || null,
      normaProgettoPresunto: data.normaProgettoPresunto || null,
      aerosolMarinoEffettivo: data.aerosolMarinoEffettivo || null,
      aerosolMarinoPresunto: data.aerosolMarinoPresunto || null,
      maggioreDegradoAltro: data.maggioreDegradoAltro || null,
      documentiVerificaStatica: data.documentiVerificaStatica || null,
      documentiVerificaSismica: data.documentiVerificaSismica || null,

      //dati default prima ispezione
      classificazioneSismica: data.capCode.value.classificazioneSismica || null,
      comune: data.comune.label || null,
      idComune: data.comune.value.id || null,
      capCode: data.capCode.label || null,
      idCap: data.capCode.value.id || null,
      indirizzo: data.indirizzo || null,
      provincia: data.provincia.label || null,
      idProvincia: data.provincia.value.id || null,
      regione: data.regione.label || null,
      idRegione: data.regione.value.id || null,
      latitudineCentro: data.latitudineCentro || null,
      longitudineCentro: data.longitudineCentro || null,
    },
  };

  const diagnosis = JSON.stringify(diagnosisData);

  return diagnosis;
};
