import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  schemaPonteCommonOptions,
  tipologiaCordoloOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import CordoliForm from './CordoliForm';
const CordoliSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const sovrastrutturaImpalcatoTableCordoliTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`
  );

  const sovrastrutturaImpalcatoTable = watch(`sovrastrutturaImpalcatoTable`);
  //console.log(sovrastrutturaImpalcatoTable, 'sovrastrutturaImpalcatoTable');

  const mensoleTamponiCampateToCopy = sovrastrutturaImpalcatoTable?.map(
    el => el.cordoliTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsCordoli = item => {
    setValue('cordoliTableValue', [item]);
    setValue('tipologiaCordoloTableValue', item.tipologiaCordolo);
  };

  const handleSelectAllClickCordoli = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`
      );
      setSelected(selectedValue);
      setValue('cordoliTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('cordoliTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t3a2_tecnicoRilevatoreCordoli',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t3a2_dataIspezioneCordoli',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t3a2_noteGeneraliCordoli',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t3a2_tecnicoRilevatoreCordoli');
    const dataIspezione = getValues('l1t3a2_dataIspezioneCordoli');

    const noteGenerali = getValues('l1t3a2_noteGeneraliCordoli');
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
  };

  const handleResetInspection = () => {
    setValue(`l1t3a2_tecnicoRilevatoreCordoli`, '');
    setValue(`l1t3a2_dataIspezioneCordoli`, null);
    setValue(`l1t3a2_noteGeneraliCordoli`, '');
  };

  const dataCordoliConfig = {
    columns: {
      codice: { label: 'Cod.' },
      presenza: { label: 'Cordolo', format: schemaPonteCommonOptions },
      tipologiaCordolo: { label: 'Tipologia', format: tipologiaCordoloOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaCordolo: {
        label: 'Tipologia',
        format: tipologiaCordoloOptions,
      },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'cordoli'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoCordoli.drawerTitle}
        drawerText={INFO.impalcatoCordoli.drawerText}
        singleElementTitle={'cordolo'}
        tableWatch={sovrastrutturaImpalcatoTableCordoliTableWatch}
        tableWatchName={`sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`}
        elementForm={
          <CordoliForm
            cordoliFields={sovrastrutturaImpalcatoTableCordoliTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            cordoliTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].cordoliTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t3a2_tecnicoRilevatoreCordoli'}
            dateName={'l1t3a2_dataIspezioneCordoli'}
            noteGeneraliName={'l1t3a2_noteGeneraliCordoli'}
          />
        }
        tableConfig={dataCordoliConfig}
        editRows={editRowsCordoli}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickCordoli}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaCordolo'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
CordoliSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default CordoliSection;
