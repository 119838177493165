import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaPiedrittoOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import PiedrittiForm from './PiedrittiForm';
const PiedrittiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`
  );

  const mensoleTamponiCampateImpalcatoTable = watch(
    `mensoleTamponiCampateImpalcatoTable`
  );
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const mensoleTamponiCampateToCopy = mensoleTamponiCampateImpalcatoTable?.map(
    el => el.piedrittiTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsPiedritti = item => {
    setValue('piedrittiTableValue', [item]);
    setValue('tipologiaPiedrittoTableValue', item.tipologiaPiedritto);
  };

  const handleSelectAllClickPiedritti = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`
      );
      setSelected(selectedValue);
      setValue('piedrittiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('piedrittiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a4_tecnicoRilevatorePiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a4_dataIspezionePiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a4_elementoCriticoStrutturaPiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_elementoCriticoSismicaPiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaStrutturaPiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaSismicaPiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_noteGeneraliPiedritti',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a4_tecnicoRilevatorePiedritti');
    const dataIspezione = getValues('l1t2a4_dataIspezionePiedritti');
    const elementoCriticoStruttura = getValues(
      'l1t2a4_elementoCriticoStrutturaPiedritti'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a4_elementoCriticoSismicaPiedritti'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a4_condizioneCriticaStrutturaPiedritti'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a4_condizioneCriticaSismicaPiedritti'
    );
    const noteGenerali = getValues('l1t2a4_noteGeneraliPiedritti');
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a4_tecnicoRilevatorePiedritti`, '');
    setValue(`l1t2a4_dataIspezionePiedritti`, null);
    setValue(`l1t2a4_elementoCriticoStrutturaPiedritti`, '');
    setValue(`l1t2a4_elementoCriticoSismicaPiedritti`, '');
    setValue(`l1t2a4_condizioneCriticaStrutturaPiedritti`, '');
    setValue(`l1t2a4_condizioneCriticaSismicaPiedritti`, '');
    setValue(`l1t2a4_noteGeneraliPiedritti`, '');
  };

  const dataPiedrittiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaPiedritto: {
        label: 'Tipologia',
        format: tipologiaPiedrittoOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaPiedritto: {
        label: 'Tipologia',
        format: tipologiaPiedrittoOptions,
      },
    },
  };

  const mensoleTamponiCampateImpalcatoSelezione = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [mensoleTamponiCampateImpalcatoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'piedritti'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoPiedritti.drawerTitle}
        drawerText={INFO.impalcatoPiedritti.drawerText}
        singleElementTitle={'piedritto'}
        tableWatch={mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch}
        tableWatchName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`}
        elementForm={
          <PiedrittiForm
            piedrittiFields={
              mensoleTamponiCampateImpalcatoTablePiedrittiTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            piedrittiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].piedrittiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a4_tecnicoRilevatorePiedritti'}
            dateName={'l1t2a4_dataIspezionePiedritti'}
            elementoCriticoStrutturaName={
              'l1t2a4_elementoCriticoStrutturaPiedritti'
            }
            elementoCriticoSismicaName={
              'l1t2a4_elementoCriticoSismicaPiedritti'
            }
            condizioneCriticaStrutturaName={
              'l1t2a4_condizioneCriticaStrutturaPiedritti'
            }
            condizioneCriticaSismicaName={
              'l1t2a4_condizioneCriticaSismicaPiedritti'
            }
            noteGeneraliName={'l1t2a4_noteGeneraliPiedritti'}
          />
        }
        tableConfig={dataPiedrittiConfig}
        editRows={editRowsPiedritti}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickPiedritti}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaPiedritto'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
PiedrittiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default PiedrittiSection;
