export const INFO = {
  codiceInterno: {
    infoMessage: ['Codice identificativo per il gestore/proprietario.'],
  },
  codiceIOP: {
    infoMessage: [
      "Codice Identificativo dell'Opera Pubblica fornito da AINOP.",
    ],
  },
  structureTag: {
    infoMessage: [
      "È possibile definire un tag così da creare dei gruppi (es. Zona 1) utili ai fini della gestione delle opere associate a ogni collaboratore nell'area Collaboratori.",
    ],
  },
  progressivaKmIniziale: {
    drawerTitle: 'Progressiva km iniziale',
    drawerText: [
      {
        title: '',
        description:
          "Per convenzione, tutti gli elementi del ponte sono definiti a partire dalla progressiva chilometrica iniziale rivolti verso la chilometrica finale. In questo stesso modo si definiscono il lato destro e sinistro dell'opera. La successiva numerazione degli elementi sarà quindi progressiva da sinistra verso destra e dalla chilometrica iniziale a quella finale.",
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/7.jpg',
      },
    ],
  },
  progressiveKmFinale: {
    drawerTitle: 'Progressiva km finale',
    drawerText: [
      {
        title: '',
        description:
          "Per convenzione, tutti gli elementi del ponte sono definiti a partire dalla progressiva chilometrica iniziale rivolti verso la chilometrica finale. In questo stesso modo si definiscono il lato destro e sinistro dell'opera. La successiva numerazione degli elementi sarà quindi progressiva da sinistra verso destra e dalla chilometrica iniziale a quella finale.",
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/8.jpg',
      },
    ],
  },
  estesa: {
    infoMessage: ['Differenza tra Progressiva finale e Progressiva iniziale.'],
  },
  caratteristicheClassificazioneEnteScavalcato: {
    drawerTitle: 'Rilevanza ente scavalcato',
    drawerText: [
      {
        title: 'Prevede affollamenti e/o funzioni significative',
        description:
          'Prevede affollamenti significativi e/o ha funzioni pubbliche e sociali essenziali e/o la cui interruzione provochi situazioni di emergenza e/o ha elevato valore naturalistico, economico e sociale (Ferrovia, zona edificata/antropizzata, strade a viabilità primaria, etc.) ',
      },
      {
        title: 'Prevede normali affollamenti, senza funzioni essenziali',
        description:
          'Prevede normali affollamenti, senza funzioni pubbliche e sociali essenziali, la cui interruzione non provochi situazioni di emergenza e/o ha limitato valore naturalistico, economico e sociale (strade a viabilità secondaria, corsi d’acqua, laghi, specchi d’acqua marini, etc.)',
      },
      {
        title: 'Prevede presenza occasionale di persone e privo di valore',
        description: `Prevede presenza occasionale di persone e privo di valore naturalistico, economico e sociale (discontinuità naturali, depressioni del terreno, etc.). In questa categoria rientrano: ruscelli, canali di convogliamento delle acque e corsi d’acqua non predisposti al passaggio di natanti o a stazionamento di persone.`,
      },
    ],
  },
  classeUso: {
    drawerTitle: "Classe d'uso",
    legislationMessage: ['NTC 2018'],
    drawerText: [
      {
        title: 'CLASSE I',
        description:
          'Costruzioni con presenza solo occasionale di persone, edifici agricoli.',
      },
      {
        title: 'CLASSE II',
        description:
          'Costruzioni il cui uso preveda normali affollamenti, senza contenuti pericolosi per l’ambiente e senza funzioni pubbliche e sociali essenziali. Industrie con attività non pericolose per l’ambiente. Ponti, opere infrastrutturali, reti viarie non ricadenti in Classe d’uso III o in Classe d’uso IV, reti ferroviarie la cui interruzione non provochi situazioni di emergenza. Dighe il cui collasso non provochi conseguenze rilevanti.',
      },
      {
        title: 'CLASSE III',
        description:
          'Costruzioni il cui uso preveda affollamenti significativi. Industrie con attività pericolose per l’ambiente. Reti viarie extraurbane non ricadenti in Classe d’uso IV. Ponti e reti ferroviarie la cui interruzione provochi situazioni di emergenza. Dighe rilevanti per le conseguenze di un loro eventuale collasso.',
      },
      {
        title: 'CLASSE IV',
        description:
          'Costruzioni con funzioni pubbliche o strategiche importanti, anche con riferimento alla gestione della protezione civile in caso di calamità. Industrie con attività particolarmente pericolose per l’ambiente. Reti viarie di tipo A o B, di cui al DM 5/11/2001, n. 6792, “Norme funzionali e geometriche per la costruzione delle strade”, e di tipo C quando appartenenti ad itinerari di collegamento tra capoluoghi di provincia non altresì serviti da strade di tipo A o B. Ponti e reti ferroviarie di importanza critica per il mantenimento delle vie di comunicazione, particolarmente dopo un evento sismico. Dighe connesse al funzionamento di acquedotti e a impianti di produzione di energia elettrica.',
      },
    ],
  },
  classeDiConseguenza: {
    drawerTitle: 'Classe di conseguenza',
    legislationMessage: ['EN 1990:2002', 'Linee Guida Ponti'],
    drawerText: [
      {
        title: '',
        description:
          'I ponti, in generale, ricadono nella Classe di Conseguenza 3, CC3, secondo l’EN 1990. In funzione delle caratteristiche della rete stradale, è possibile assegnare all’opera la Classe di Conseguenza CC2 o CC1 dandone necessaria comunicazione alle Autorità Vigilanti ed alle banche dati istituzionali regionali e nazionali. ',
      },
      {
        title: 'CC3',
        description:
          'Elevate conseguenze per perdita di vite umane, o con conseguenze molto gravi in termini economici, sociali o ambientali.',
      },
      {
        title: 'CC2',
        description:
          'Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali.',
      },
      {
        title: 'CC1',
        description:
          'Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali.',
      },
    ],
  },
  livelloConoscenza: {
    infoMessage: [
      `Selezionare LC0 se non si intenda raggiunto nemmeno un LC1.`,
    ],
    legislationMessage: ['NTC 2018'],
    compassMessage: ['Capitolo 6.2.2 e 6.2.3 delle Linee Guida Ponti'],
  },
  autoritaDistrettuale: {
    infoMessage: [
      "Fornire indicazioni in merito all'Autorità distrettuale nel caso in cui sia presente un rischio frana e/o un rischio idraulico.",
    ],
  },
  tutelaAiSensiDelDecretoLegge: {
    legislationMessage: [
      "Decreto Legislativo 22 gennaio 2004, n.42: Codice dei beni culturali e del paesaggio, ai sensi dell'articolo 10 della legge 6 luglio 2002, n. 137",
    ],
  },
  rischioFrana: {
    infoMessage: [
      "Per verificare che il manufatto ricada all'interno di aree a pericolosità da frana, consultare il sito Ispra.",
    ],
    linkMessage: ['https://idrogeo.isprambiente.it/app/'],
  },
  tipologiaInterferenza: {
    infoMessage: [
      'L’interferenza può essere considerata diretta se il movimento franoso comprende in tutto o in parte il volume significativo, mentre può essere considerata indiretta se il movimento franoso potrebbe coinvolgere la struttura solo a seguito della sua eventuale mobilizzazione.',
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  necessitaIspezioniSpecialiRischioFrane: {
    drawerTitle: 'Necessità di ispezioni speciali (rischio frane)',
    compassMessage: ['Capitolo 3.6 delle Linee Guida Ponti'],
    drawerText: [
      {
        title: '',
        description: `Nel caso di ponti ricadenti in aree ad evidenza di fenomeni franosi è necessario procedere alle ispezioni speciali. Tali ispezioni devono consentire di stabilire l’esistenza di uno stato di sofferenza dell’infrastruttura che si possa ricollegare alla interazione con il fenomeno franoso. In particolare, le informazioni acquisibili mediante le ispezioni visive di Livello 1 dovranno essere integrate ad esempio con approfondimenti documentali, con prove in sito o con l’elaborazione di dati satellitari disponibili per l’area. 
          Nel caso in cui questo supplemento di indagine riveli l’esistenza di un significativo stato di sofferenza del ponte nel fenomeno franoso si procede con l’esecuzione diretta di valutazioni appArofondite e di dettaglio di Livello 4. Qualora ciò non si verifichi, si procede, tenendo conto delle informazioni così acquisite, alla valutazione dei parametri primari e secondari relativi al rischio frane per l’assegnazione della CdA frane.`,
      },
    ],
  },
  risultatiIspezioniSpecialiFrane: {
    drawerTitle: 'Risultati ispezioni speciali',
    compassMessage: [
      'Capitolo 3.6 delle Linee Guida Ponti',
      'Allegato D - schede di ispezione speciale',
    ],
    drawerText: [
      {
        title: '',
        description:
          'Riportare i risultati ottenuti a seguito delle ispezioni speciali.',
      },
      {
        title: 'Necessità di valutazioni di Livello 4',
        description:
          'Le indagini hanno evidenziato la necessità di eseguire valutazioni di sicurezza approfondite di Livello 4.',
      },
      {
        title:
          'Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
        description:
          'Le indagini effettuate non  consentono di valutare con adeguata accuratezza i rischi idrogeologici. Sono necessarie, pertanto, valutazioni approfondite di Livello 4.',
      },
      {
        title:
          'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
        description:
          'Le indagini non hanno evidenziato la necessità di valutazioni di sicurezza approfondite di Livello 4. Si procede pertanto ad ispezioni visive di Livello 1 e classificazione di Livello 2.',
      },
      {
        title: 'Ispezione speciale ancora da effettuare',
        description: 'Le indagini devono ancora essere effettuate.',
      },
    ],
  },
  pericolositaPAIPSAIFrane: {
    infoMessage: [
      'Pericolosità secondo il documento di Piano di Assetto Idrogeologico.',
    ],
  },

  stralcioMappaPericolositàFranePAI: {
    drawerTitle: 'Stralcio mappa pericolosità frane PAI',
    drawerText: [
      {
        title: '',
        description:
          "Inserire stralcio immagine dell'area interessata. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3.",
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/69.jpg',
      },
    ],
  },

  rischioIdraulico: {
    legislationMessage: [`Linee Guida Ponti`],
    drawerTitle: 'Rischio idraulico',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Si può ritenere assente il rischio idraulico per strutture che non vadano ad interessare l’alveo (come definito dal § 5.1.2.3. della Circolare 21.01.2019 n.7 del CSLLPP) con le pile e/o con le spalle e sempre che l’impalcato garantisca il rispetto del franco libero così come prescritto nelle vigenti Norme Tecniche per le Costruzioni (D.M. 17.01.2018, § 5.1.2.3). Tali valutazioni possono essere suffragate o dall’evidenza dei luoghi o da idonea “relazione di compatibilità idraulica” (D.M. 17.01.2018). Pertanto, qualora si possa ritenere che il determinarsi di un evento di piena non possa coinvolgere la struttura in esame, non risulta necessario proseguire con la valutazione della CdA idraulica, in quanto non influente ai fini della determinazione della CdA complessiva associata al ponte.
In generale, si può ritenere che il corso d’acqua non interferisca con la struttura, e pertanto dichiarare il rischio idraulico Assente, se il ponte presenta contemporaneamente le seguenti caratteristiche: `}
            </div>
            <ul>
              <li>{`luce > 25 m riferita alla luce libera della sezione idraulica dell’attraversamento, misurata in direzione ortogonale al deflusso della corrente;`}</li>
            </ul>
            <ul>
              <li>{`distanza tra il fondo dell’alveo e la quota minima dell’intradosso dell’impalcato > 15 m;`}</li>
            </ul>
            <ul>
              <li>{`presenza di fondazioni profonde;`}</li>
            </ul>
            <ul>
              <li>{`assenza di notizie di fenomeni erosivi e di scalzamento.`}</li>
            </ul>
          </>
        ),
      },
    ],
  },
  campateSoggetteACollasso: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Campate soggette a collasso`,
    drawerText: [
      {
        title: '',
        description: `Definire il numero di campate coinvolte in un possibile meccanismo di collasso. Conseguentemente, la classe stabilita secondo Tabella 4.6 delle Linee Guida Ponti aumenta di un livello (da Bassa a Medio-Bassa, da Medio-Bassa a Media, e così via) se il numero di campate coinvolte in un possibile meccanismo di collasso è superiore a 3. La classe rimane invariata se il numero di campate coinvolte in un possibile meccanismo di collasso è inferiore o uguale a 3.`,
      },
    ],
  },
  vulnerabilitaStrutturaleBassa: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Vulnerabilità strutturale Bassa`,
    drawerText: [
      {
        title: '',
        description: (
          <>
            {`In relazione alla definizione della classe di vulnerabilità per ponti di recente costruzione progettati con norme tecniche a partire da quella del 2005 (norma di progetto Classe C), e per i quali si riscontrano le seguenti condizioni:`}

            <ul>
              <li>
                {`sono caratterizzati da un livello di difettosità basso o medio-basso;`}
              </li>
              <li>
                {`si ha a disposizione il progetto originario, la relazione a struttura ultimata con i relativi controlli di accettazione, il certificato di collaudo statico ed eventualmente il certificato di collaudo tecnico amministrativo;`}
              </li>
            </ul>
            {`la classe di vulnerabilità può considerarsi bassa.`}
          </>
        ),
      },
    ],
  },
  necessitaIspezioniSpecialiRischioIdraulico: {
    compassMessage: ['Capitolo 3.6 delle Linee Guida Ponti'],
    drawerTitle: 'Necessità di ispezioni speciali (rischio idraulico)',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Ispezioni accurate sono richieste nel caso di strutture collocate in aree coinvolte da accadimenti pregressi (fenomeni di escavazione, allagamenti, modificazioni delle sezioni idriche, ecc.) o riconosciute ad elevato rischio idrogeologico, con evidenze di possibile interferenza con la struttura.
Nello specifico, le ispezioni speciali dei ponti in aree ad evidenza di fenomeni alluvionali ed erosionali riguardano:`}
            </div>
            <ul>
              <li>{`i ponti che hanno subito un evento alluvionale recente (ultimi 5 anni) che, come risultato da informazioni testimoniali e/o da tracce dell’evento di piena sulla struttura o sulle sponde, ha prodotto un franco inferiore a 1 m per strade comunali e provinciali, 2 m per strade statali o superiori, soprattutto se in presenza di materiale vegetale o di sovralluvionamento dell’alveo; `}</li>
            </ul>
            <ul>
              <li>{` i ponti le cui interferenze con i fenomeni di piena dei corsi d’acqua hanno provocato fenomeni erosivi tali da far temere una significativa perdita della capacità portante dell’insieme fondazione/terreno.`}</li>
            </ul>
          </>
        ),
      },
    ],
  },
  risultatiIspezioniSpecialiRischioIdraulico: {
    drawerTitle: 'Risultati ispezioni speciali',
    compassMessage: [
      'Capitolo 3.6 delle Linee Guida Ponti',
      'Allegato D - schede di ispezione speciale',
    ],
    drawerText: [
      {
        title: '',
        description:
          'Riportare i risultati ottenuti a seguito delle ispezioni speciali.',
      },
      {
        title: 'Necessità di valutazioni di Livello 4',
        description:
          'Le indagini hanno evidenziato la necessità di eseguire valutazioni di sicurezza approfondite di Livello 4.',
      },
      {
        title:
          'Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
        description:
          'Le indagini effettuate non  consentono di valutare con adeguata accuratezza i rischi idraulici. Sono necessarie, pertanto, valutazioni approfondite di Livello 4.',
      },
      {
        title:
          'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
        description:
          'Le indagini non hanno evidenziato la necessità di valutazioni di sicurezza approfondite di Livello 4. Si procede pertanto ad ispezioni visive di Livello 1 e classificazione di Livello 2.',
      },
      {
        title: 'Ispezione speciale ancora da effettuare',
        description: 'Le indagini devono ancora essere effettuate.',
      },
    ],
  },
  pericolositaPAIPSAIRischioIdraulico: {
    infoMessage: [
      "Di norma, un ponte su corso d'acqua ricade sempre all'interno di aree ad alta pericolosità P3. Ad ogni modo, riportare le informazioni contenute nel PAI.",
    ],
    linkMessage: ['https://idrogeo.isprambiente.it/app/'],
  },
  presenzaStudioIdraulicoLivelliDefiniti: {
    drawerTitle: 'Presenza studio idraulico con livelli idrici definiti',
    drawerText: [
      {
        title: '',
        description:
          "Con livelli idrici definiti si intende la conoscenza dei livelli per eventi P2 e/o P3 ricavati da PAI o studi di compatibilità idraulica. Per i corsi d'acqua del reticolo principale, tali dati sono normalmente disponibili nei database dell'autorità di bacino di competenza. Per i corsi d'acqua secondari, è possibile, che l'ente competente sia in possesso di uno studio idraulico di dettaglio relativo al ponte.",
      },
    ],
  },
  annoDiCostruzione: {
    infoMessage: ["Anno di ultimazione dell'opera (collaudo)."],
  },
  interventiSignificativiManutenzione: {
    infoMessage: [
      "Interventi manutentivi […] che abbiano limitato in maniera significativa i fenomeni di degrado, riconducendo lo stato di conservazione dell'opera nella pratica alle sue condizioni iniziali.",
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  criteriAntiSismiciInOpera: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: 'Criteri antisismici in opera',
    drawerText: [
      {
        title: '',
        description: `Indicare se l'opera è stata realizzata prendendo in considerazione criteri di progettazione sismica specifici. Qualora l'analisi della documentazione disponibile non consenta di risalire alle informazioni necessarie, si considera il ponte come progettato con criteri "non sismici", per procedere a favore di sicurezza. 
          Con riferimento alla normativa di progettazione, possono considerarsi realizzati secondo criteri di progettazione antisismica i ponti realizzati in accordo con le Normative adottate a partire dal 2003. Possono altresì considerarsi realizzati secondo criteri di progettazione antisismica anche quelle strutture realizzate in data antecedente al 2003, per le quali si dispongano elaborati progettuali che diano contezza di tale assunzione.`,
      },
    ],
  },
  verificaStatica: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Verifica statica`,
    drawerText: [
      {
        title: '',
        description: `Indicare la disponibilità di verifiche di sicurezza di cui al cap. 8.3 delle NTC2018. Nel caso in cui tali verifiche siano presenti, abbiano preso in considerazione lo stato attuale di conservazione dell'opera e tutti gli elementi di degrado e l’opera risulti adeguata dal punto di vista delle condizioni statiche, allora la CdA strutturale fondazionale sarà considerata bassa.`,
      },
    ],
  },
  verificaSismica: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Verifica sismica`,
    drawerText: [
      {
        title: '',
        description: `Indicare la disponibilità di valutazioni accurate relative alle condizioni sismiche eseguite alla luce delle NTC2018 o secondo le presenti LLGG, dalle quali il ponte risulti adeguato sismicamente e che prendano comunque in considerazione lo stato attuale di conservazione dell'opera e tutti gli elementi di degrado. In tali circostane, il ponte è da considerarsi adeguato da un punto di vista delle condizioni sismiche, e la CdA sismica sarà considerata bassa.`,
      },
    ],
  },
  classificazioneUsoStradale: {
    legislationMessage: [
      'Nuovo Codice della strada',
      'DL 30 aprile 1992',
      'n. 285 e successive modificazioni',
    ],
    drawerTitle: `Classificazione d'uso stradale`,
    drawerText: [
      {
        title: '',
        description: `Le strade sono classificate riguardo alle loro caratteristiche costruttive, tecniche e funzionali.`,
      },
      {
        title: 'Autostrada',
        description: `Strada extraurbana o urbana a carreggiate indipendenti o separate da spartitraffico invalicabile, ciascuna con almeno due corsie di marcia, eventuale banchina pavimentata a sinistra e corsia di emergenza o banchina pavimentata a destra, priva di intersezioni a raso e di accessi privati, dotata di recinzione e di sistemi di assistenza all'utente lungo l'intero tracciato, riservata alla circolazione di talune categorie di veicoli a motore e contraddistinta da appositi segnali di inizio e fine; deve essere attrezzata con apposite aree di servizio ed aree di parcheggio entrambe con accessi dotati di corsie di decelerazione e di accelerazione.`,
      },
      {
        title: 'Strada extraurbana principale',
        description: `Strada a carreggiate indipendenti o separate da spartitraffico invalicabile, ciascuna con almeno due corsie di marcia e banchina pavimentata a destra, priva di intersezioni a raso, con accessi alle proprieta' laterali coordinati, contraddistinta dagli appositi segnali di inizio e fine, riservata alla circolazione di talune categorie di veicoli a motore; per eventuali altre categorie di utenti devono essere previsti opportuni spazi. Deve essere attrezzata con apposite aree di servizio, che comprendano spazi per la sosta, con accessi dotati di corsie di decelerazione e di accelerazione.`,
      },
      {
        title: 'Strada extraurbana secondaria',
        description: `Strada ad unica carreggiata con almeno una corsia per senso di marcia e banchine.`,
      },
      {
        title: 'Strada urbana di scorrimento',
        description: `Strada a carreggiate indipendenti o separate da spartitraffico, ciascuna con almeno due corsie di marcia, ed una eventuale corsia riservata ai mezzi pubblici, banchina pavimentata a destra e marciapiedi, con le eventuali intersezioni a raso semaforizzate; per la sosta sono previste apposite aree o fasce laterali estranee alla carreggiata, entrambe con immissioni ed uscite concentrate.`,
      },
      {
        title: 'Strada urbana di quartiere',
        description: `Strada ad unica carreggiata con almeno due corsie, banchine pavimentate e marciapiedi; per la sosta sono previste aree attrezzate con apposita corsia di manovra, esterna alla carreggiata.`,
      },
      {
        title: 'Strada locale',
        description: `Strada urbana od extraurbana opportunamente sistemata ai fini di cui al comma 1 non facente parte degli altri tipi di strade.`,
      },
      {
        title: 'Pedonale',
        description: `Strada locale, urbana, extraurbana o vicinale, destinata prevalentemente alla percorrenza pedonale e ciclabile e caratterizzata da una sicurezza intrinseca a tutela dell'utenza ((vulnerabile)) della strada.`,
      },
    ],
  },
  abbassamentoAccentuatoFondazioni: {
    infoMessage: [
      `In presenza di abbassamento dell'alveo, segnalare se il fenomeno è rilevante in relazione alla profondità di fondazione. `,
    ],
  },
  categoriaPonte: {
    infoMessage: [
      'Nel caso in cui non sia disponibile documentazione sulla categoria di progettazione del ponte e non sia possibile risalire, in alcun modo, ad essa occorre far riferimento ai ponti di II categoria.',
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  reteTEN: {
    infoMessage: [
      "Inserimento dell'infrastruttura all'interno delle reti trans-europee.",
    ],
  },
  itinerarioEmergenza: {
    drawerTitle: `Opera strategica`,
    drawerText: [
      {
        title: ``,
        description: `Le opere considerate di interesse strategico, la cui funzionalità durante gli eventi sismici assume rilievo fondamentale per le finalità di protezione civile, devono avere una più elevata priorità, in quanto è necessario garantirne l’efficienza in caso di emergenza.
In caso di mancanza di differenti specifiche indicazioni, la strategicità dell’opera è valutata con riferimento al Decreto del Capo Dipartimento della Protezione Civile n. 3685 del 21 ottobre 2003.`,
      },
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  trasportiEccezionali: {
    infoMessage: [
      'Si considera eccezionale il veicolo che nella propria configurazione di marcia superi, per specifiche esigenze funzionali, i limiti di sagoma o massa stabiliti negli articoli 61 e 62.',
    ],
    legislationMessage: [
      'Nuovo Codice della strada - DL 30 aprile 1992, n. 285 e successive modificazioni',
    ],
  },
  disponibilitaStudiEffettiCarichiTraffico: {
    infoMessage: [
      'Disponibilità di valutazioni sugli effetti dei carichi da traffico.',
    ],
  },
  TGMVeicoliGiorno: {
    infoMessage: [
      `Livello di Traffico Medio Giornaliero (veicoli/giorno sull'intera carreggiata). Qualora il dato TGM non fosse disponibile per la rete stradale in esame, si può far riferimento al dato TGM noto per tratte limitrofe e/o caratterizzate da simili caratteristiche.`,
    ],
  },
  TGMCommercialiVeicoliGiorno: {
    infoMessage: [
      "Livello di Traffico Medio Giornaliero (veicoli commerciali/giorno sull'intera carreggiata). Per veicoli commerciali si intendono quelli la cui sagoma corrisponde a tipologie con portata superiore a 3,5 t.",
    ],
  },
  TGMCommercialiVeicoliGiornoCorsiaSingola: {
    drawerTitle: `TMG- Commerciali [veicoli/giorno] (singola corsia)`,
    drawerText: [
      {
        title: '',
        description: `In mancanza di dati utili per la determinazione della frequenza di transito di veicoli commerciali per singola corsia di marcia, nelle more di acquisizione degli stessi, si può operare per analogia con dati disponibili riferiti a strade di eguale ordine e ambito territoriale. [...]
Qualora siano disponibili registrazioni del traffico riferite all’intera carreggiata, ovvero al traffico totale in entrambe le direzioni, può adottarsi la condizione più gravosa [...] Ciò è applicabile a tutte le tipologie di strada, indipendentemente dal numero di carreggiate e di corsie presenti e per le quali è disponibile il dato.`,
      },
    ],
  },
  alternativeStradali: {
    infoMessage: [
      'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico. Nel caso in cui non siano disponibili dati sufficienti, si considera il caso di "assenza di alternative" per procedere in via cautelativa al calcolo della Classe di Attenzione.',
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  durataDeviazioneKm: {
    infoMessage: [
      'Indicare la durata del percorso alternativo nel caso di chiusura del ponte, eventualmente ipotizzabile con Google Maps o similari.',
    ],
  },
  durataDeviazioneMin: {
    infoMessage: [
      'Indicare la lunghezza del percorso alternativo nel caso di chiusura del ponte, eventualmente ipotizzabile con Google Maps o similari.',
    ],
  },
  frequentePassaggioPersone: {
    infoMessage: [
      'Indicare se sul ponte è previsto il passaggio frequente di pedoni. ATTENZIONE: questa scelta può condizionare la determinazione della Classe di Attenzione.',
    ],
  },
  trasportoMerciPericolose: {
    infoMessage: [
      'Indicare se sul ponte è previsto il passaggio in modo abituale di merci pericolose (ad esempio per la vicinanza di impianti produttivi di tali sostanze). ATTENZIONE: questo parametro è utilizzato come elemento di discrimine tra ponti appartenenti alla stessa Classe di Attenzione.',
    ],
  },
  unitaFisiografica: {
    infoMessage: [
      'Unità territoriale di riferimento per classificare e cartografare i paesaggi italiani.',
    ],
    quoteMessage: [
      'Istituto Superiore per la Protezione e la Ricerca Ambientale',
    ],
  },
  categoriaTopografica: {
    legislationMessage: ['NTC 2018'],
  },
  categoriaSottosuolo: {
    legislationMessage: ['NTC 2018', 'Linee Guida Ponti'],
    drawerTitle: 'Categoria di sottosuolo',
    drawerText: [
      {
        title: '',
        description:
          'Qualora la categoria di sottosuolo non sia deducibile dalle informazioni disponibili, si assume la peggiore tra le categorie di sottosuolo ragionevolmente prevedibili per quel sito.',
      },
      {
        title: 'A',
        description:
          'Ammassi rocciosi affioranti o terreni molto rigidi caratterizzati da valori di velocità delle onde di taglio superiori a 800 m/s, eventualmente comprendenti in superficie terreni di caratteristiche meccaniche più scadenti con spessore massimo pari a 3 m.',
      },
      {
        title: 'B',
        description:
          'Rocce tenere e depositi di terreni a grana grossa molto addensati o terreni a grana fina molto consistenti, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 360 m/s e 800 m/s.',
      },
      {
        title: 'C',
        description:
          'Depositi di terreni a grana grossa mediamente addensati o terreni a grana fina mediamente consistenti con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 180 m/s e 360 m/s.',
      },
      {
        title: 'D',
        description:
          'Depositi di terreni a grana grossa scarsamente addensati o di terreni a grana fina scarsamente consistenti, con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 100 e 180 m/s.',
      },
      {
        title: 'E',
        description:
          'Terreni con caratteristiche e valori di velocità equivalente riconducibili a quelle definite per le categorie C o D, con profondità del substrato non superiore a 30 m.',
      },
    ],
  },
  aerosolMarini: {
    infoMessage: [
      'L’eventuale esposizione […] a correnti di vento marine (“aerosol marini”) o all’azione aggressiva dei sali antigelo è influente ai fini della classificazione in quanto può determinare una maggiore rapidità di evoluzione del degrado.',
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  creazioneSchemaPonteAccordion10: {
    drawerTitle: 'Caratteristiche geometriche',

    drawerText: [
      {
        title: '',
        description:
          "Definire il numero di campate presenti nell'opera seguendo gli esempi proposti. Di seguito verrà creata una tabella in cui, per ogni campata, andranno compilati i seguenti campi: schema statico, luce campata, ente scavalcato, luce idraulica.",
      },
      {
        title: 'Travata appoggiata',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_travata appoggiata.jpg',
      },
      {
        title: 'Travata gerber',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_travata gerber.jpg',
      },
      {
        title: 'Travata continua',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_travata continua.jpg',
      },
      {
        title: 'Ponte a telaio',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_ponte a telaio.jpg',
      },
      {
        title: 'Arco massiccio',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_ponte ad arco massiccio.jpg',
      },
      {
        title: 'Arco sottile',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_ponte ad arco sottile.jpg',
      },
      {
        title: 'Ponte strallato',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.10_ponte strallato.jpg',
      },
      {
        title: 'Luce idraulica',
        description:
          "Per luce idraulica si intende la luce netta minima fra pile/spalle contigue misurata ortogonalmente al filone principale della corrente. Nello specifico, nel caso in cui il ponte sia inclinato di un angolo α≠90°, la luce idraulica è pari al prodotto tra la distanza fra le pile/spalle e il senα. La luce idraulica va compilata solo nel caso in cui l'interferenza per la campata è un corso d'acqua.",
      },
      {
        title: '',
        description: "Ponte ortogonale al corso d'acqua",
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.11_Luce idraulica_1.jpg',
      },
      {
        title: '',
        description: "Ponte obliquo rispetto al corso d'acqua - tipo 1",
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.11_Luce idraulica_2.jpg',
      },
      {
        title: '',
        description: "Ponte obliquo rispetto al corso d'acqua - tipo 2",
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Contesto.11_Luce idraulica_3.jpg',
      },
    ],
  },
  altezzaUtileSottoImpalcato: {
    infoMessage: [
      `Nel caso di ponti idraulici, segnare l'altezza libera che equivale alla distanza tra intradosso del ponte e fondo dell'alveo.`,
    ],
  },
  siglaStradaAppartenenza: {
    legislationMessage: ['D.M. 430 - 2019'],
    drawerTitle: 'Strada di appartenenza (Sigla)',
    drawerText: [
      {
        title: '',
        description:
          'Indicare il nome della strada di appartenenza, composto da due lettere e il relativo numero. AU0000 = Autostrada; SS0000 = Strada Statale; SR0000 = Strada Regionale; SP0000 = Strada Provinciale; SC0000 = Strada Comunale; SV0000 = Strada vicinale; XXXXXX = Altra infrastruttura stradale. (Es.: Strada Statale n°4 = SS0004)',
      },
    ],
  },
  sismicitaArea: {
    infoMessage: ['Suolo A, TR = 475 anni'],
    legislationMessage: ['Linee Guida Ponti'],
  },
  coordinateCTR: {
    infoMessage: [
      'Numero identificativo della Sezione/Elemento della Carta Tecnica Regionale utilizzata.',
    ],
    legislationMessage: ['O.P.C.M. n.3274/2003 e s.m.i.'],
  },
  scala: {
    infoMessage: ['Scala della Cartografia Tecnica Regionale utilizzata.'],
  },
  numeroToponimo: {
    infoMessage: ['Toponimo della CTR.'],
  },
  sistemaDiRiferimento: {
    infoMessage: [
      'Indicare il sistema di riferimento utilizzato per determinare le coordinate geografiche.',
    ],
  },
  ellissoideDiRiferimento: {
    infoMessage: [
      "Indicare l'ellissoide associato al sistema di riferimento utilizzato.",
    ],
  },
  stralcioMappaPericolositàIdraulicaPAI: {
    drawerTitle: 'Stralcio mappa pericolosità idraulica PAI',
    drawerText: [
      {
        title: '',
        description:
          "Inserire stralcio immagine dell'area interessata. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3.",
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/101.jpg',
      },
    ],
  },
  fotoInquadramentoIdraulica: {
    drawerTitle: 'Foto di inquadramento idraulica',
    drawerText: [
      {
        title: '',
        description:
          "Foto dell'alveo, della sponda destra e della sponda sinistra a monte e a valle del manufatto, foto di prospetto del manufatto da monte e da valle, foto delle strutture in alveo (in particolare l'interazione struttura-fondo alveo/sponda alveo). Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3.",
      },
    ],
  },
  tipologiaStrutturale: {
    compassMessage: ['Capitolo 3.6 e Allegato D delle Linee Guida Ponti'],
    drawerTitle: 'Tipologia strutturale',
    drawerText: [
      {
        title: '',
        description: `Nel caso di ponti in calcestruzzo armato precompresso a cavi post-tesi resi aderenti [...] occorre eseguire delle ispezioni speciali atte a verificare la necessità di procedere con l’esecuzione diretta di valutazioni approfondite e di dettaglio di Livello 4.

          ATTENZIONE: questo dato è utile ai fine della determinazione della CdA. Si evidenzia che, in attuazione alle Linee Guida Ponti, non è possibile determinare la CdA nel caso di "Altro". Alcune combinazioni tra tipologia strutturale e tipologia impalcato sono state aggiunte rispetto alla tabella proposta dalla Linee Guida e sono contrassegnate da sfondo azzurro. 
          Qualora il ponte abbia campate con diverso schema strutturale (ad esempio, campate centrali ad arco e campate di riva con travate appoggiate), si consideri la classe di vulnerabilità più gravosa tra quelle associate ai due differenti schemi statici.`,
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/317.jpg',
      },
    ],
  },
  necessitaIspezioniSpecialiRischioStruttura: {
    infoMessage: [
      'Nel caso di ponti in calcestruzzo armato precompresso a cavi post-tesi resi aderenti [...] occorre eseguire delle ispezioni speciali atte a verificare la necessità di procedere con l’esecuzione diretta di valutazioni approfondite e di dettaglio di Livello 4.',
    ],
    compassMessage: ['Capitolo 3.6 e Allegato D delle Linee Guida Ponti'],
  },
  risultatiIspezioniSpeciali: {
    drawerTitle: 'Risultati ispezioni speciali',
    compassMessage: ['Capitolo 3.6 e Allegato D delle Linee Guida Ponti'],
    drawerText: [
      {
        title: '',
        description:
          'Riportare i risultati ottenuti a seguito delle ispezioni speciali.',
      },
      {
        title: 'Necessità di valutazioni di Livello 4',
        description:
          'I difetti rilevati indicano la necessità di eseguire valutazioni di sicurezza approfondite di Livello 4.',
      },
      {
        title:
          'Ispezione speciale non esaustiva. Necessità di valutazioni di Livello 4',
        description:
          "Le indagini effettuate non consentono di stimare con adeguata accuratezza l'entità dei difetti presenti. Sono necessarie, pertanto, valutazioni approfondite di Livello 4.",
      },
      {
        title:
          'Necessità di ispezioni di Livello 1 e classificazione Livello 2',
        description:
          'Le indagini effettuate hanno dimostrato la presenza di difetti di entità modesta, tali da non richiedere provvedimenti urgenti. Si procede pertanto ad ispezioni visive e compilazione di schede di difettosità di Livello 1 e classificazione di Livello 2.',
      },
      {
        title: 'Ispezione speciale ancora da effettuare',
        description: 'Le ispezioni speciali devono ancora essere effettuate.',
      },
    ],
  },
  creazioneSchemaPonte: {
    drawerTitle: 'Schema ponte',
    drawerText: [
      {
        title: ``,
        description: `Una volta definito il numero delle campate e/o di mensole e travi tampone è automaticamente definito il numero di pile (P) e spalle (S) incluse nel sistema strutturale. Le due tabelle che seguono chiedono all’utente di definire nell’ordine:
        - La tipologia e la quantità degli elementi di collegamento (Appoggi-AP, Giunti-G, Pulvini-PU, Antenne-AN e Altri dispositivi antisismici-DS) associati ad ogni spalla ed ogni pila;
        - La tipologia e la quantità degli elementi di impalcato (Soletta-SO, Travi-TP, Traversi-TS, Controventi-CV, Archi-A, Piedritti-PD, Tiranti-TN, Elementi extra-EX) associati a ciascuna delle Campate, Mensole e Travi tampone che compongono la struttura.
        ATTENZIONE: Nel caso in cui il ponte includa Mensole e Travi tampone, è necessario che l’utente ne definisca le lunghezze (Luce M/T). Si riportano nel seguito alcuni esempi di schema ponte.`,
      },
      {
        title: `Travata appoggiata`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - travata appoggiata.jpg',
      },
      {
        title: `Travata gerber`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - travata gerber.jpg',
      },
      {
        title: `Travata continua`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - travata continua.jpg',
      },
      {
        title: `Ponte a telaio`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - ponte a telaio.jpg',
      },
      {
        title: `Arco massiccio`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - ponte ad arco massiccio.jpg',
      },
      {
        title: `Arco sottile`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - ponte ad arco sottile.jpg',
      },
      {
        title: `Ponte strallato`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328 - ponte strallato.jpg',
      },
      {
        title: `Sezione definizione elementi`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/328_Sezione elementi.jpg',
      },
    ],
  },
  tipologiaSchema: {
    drawerTitle: `Tipologia schema`,
    drawerText: [
      {
        title: '',
        description: `In questo campo è necessario identificare la tipologia dello schema statico considerando il manufatto nella sua interezza.`,
      },
      {
        title: `Schema isostatico`,
        description: `Ponti rientranti nelle categorie a travate appoggiate, a travate Gerber/ponti a stampella con travi tampone e a soletta appoggiata.  Con riferimento ai ponti ad arco sottile si considerino come isostatici i soli ponti ad arco a tre cerniere e ponti ad arco a via inferiore (a spinta eliminata) con impalcato in semplice appoggio su elementi verticali.`,
      },
      {
        title: `Schema iperstatico`,
        description: `Ponti rientranti nelle categorie ponti a travate continue/telaio, ponti ad arco massiccio, e ponti a soletta incastrata.`,
      },
    ],
  },
  tipologiaImpalcato: {
    drawerTitle: 'Tipologia impalcato',
    drawerText: [
      {
        title: '',
        description: `Questo dato è utile ai fine della determinazione della CdA. In caso di dubbio, si consiglia di selezionare il materiale prevalente. ATTENZIONE: si evidenzia che, in attuazione alle Linee Guida Ponti, non è possibile determinare la CdA nel caso di "Altro".
Alcune combinazioni tra tipologia schema e tipologia impalcato sono state aggiunte rispetto alla tabella proposta dalla Linee Guida e sono contrassegnate da sfondo azzurro.`,
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/322.jpg',
      },
    ],
  },
  mensole: {
    drawerTitle: 'Mensole',
    drawerText: [
      {
        title: '',
        description: `Per poter procedere all’ispezione del ponte in ogni sua parte, è lasciata libertà all’utente di schematizzare la struttura secondo una delle logiche di seguito proposte:
        - Il numero degli impalcati coincide con il numero di campate (C);
        - Nel caso di travate gerber o di alcune tipologie di ponti a telaio è possibile distinguere tra mensole (M) e travi tampone (T).
        Selezionando “sì” per questa voce, nei successivi campi sarà richiesto all’utente di specificare il numero totale di mensole e/o travi tampone previsti dallo schema. Si riportano di seguito alcuni esempi di schematizzazione. Gli esempi proposti non sono vincolanti né esaustivi.`,
      },
      {
        title: `Travata appoggiata`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - travata appoggiata.jpg',
      },
      {
        title: `Travata gerber`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - travata gerber.jpg',
      },
      {
        title: `Travata continua`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - travata continua.jpg',
      },
      {
        title: `Ponte a telaio`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - ponte a telaio.jpg',
      },
      {
        title: `Arco massccio`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - ponte ad arco massiccio.jpg',
      },
      {
        title: `Arco sottile`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - ponte ad arco sottile.jpg',
      },
      {
        title: `Ponte strallato`,
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/325 - ponte strallato.jpg',
      },
    ],
  },
  spalleSelezionate: {
    infoMessage: ['Spalle per le quali è valida la seguente descrizione.'],
  },
  superficieSpalle: {
    infoMessage: [
      'Superficie laterale totale della spalla utile, come stima, ai fine della manutenzione.',
    ],
  },
  pileSelezionate: {
    infoMessage: ['Pile per le quali è valida la seguente descrizione.'],
  },
  superficiePile: {
    infoMessage: [
      'Superficie laterale totale della pila utile, come stima, ai fine della manutenzione.',
    ],
  },
  appoggiSelezionati: {
    infoMessage: ['Appoggi per i quali è valida la seguente descrizione.'],
  },
  giuntiSelezionati: {
    infoMessage: ['Giunti per i quali è valida la seguente descrizione.'],
  },
  tipologiaGiunto: {
    quoteMessage: [
      'Manuale Valutazione dello stato valutazione dei ponti - CIAS',
    ],
    drawerTitle: 'Tipologia Giunto',
    drawerText: [
      {
        title: 'Rete nella pavimentazione',
        description:
          'Reti costituite da fibre sintetiche della larghezza di 6-8 metri e poste a cavallo del giunto ed annegate nel manto bituminoso.',
      },
      {
        title: 'Profilati elastomerici',
        description:
          'Piccolo profilato in gomma interposto tra due angolari metallici fissati in modo solidale alla soletta sottostante.',
      },
      {
        title: 'Strisce in gomma',
        description:
          'Opportunamente sagomate, comprensive degli elementi metallici necessari al collegamento con la soletta sottostante.',
      },
      {
        title: 'Pettini',
        description:
          "In acciaio eventualmente rivestiti da neoprene. Possono funzionare a sbalzo in modo indipendente l'uno dall'altro.",
      },
      {
        title: 'Tamponi o giunti sottopavimentazione',
        description:
          "Impiegati per il collegamento di impalcati isostatici. Realizzati mediante l'inserimento sotto la pavimentazione di profili metallici a 'T' muniti di ala superiore interna sagomata.",
      },
    ],
  },
  pulviniSelezionati: {
    infoMessage: ['Pulvini per i quali è valida la seguente descrizione.'],
  },
  superficiePulvino: {
    infoMessage: [
      "Superficie laterale totale dell'antenna utile, come stima, ai fine della manutenzione.",
    ],
  },
  antenneSelezionate: {
    infoMessage: ['Antenne per le quali è valida la seguente descrizione.'],
  },
  superficieAntenna: {
    infoMessage: [
      "Superficie laterale totale dell'antenna utile, come stima, ai fine della manutenzione.",
    ],
  },
  dispositiviSismiciSelezionati: {
    infoMessage: ['Dispositivi per i quali è valida la seguente descrizione.'],
  },
  soletteSelezionate: {
    infoMessage: ['Solette per le quali è valida la seguente descrizione.'],
  },
  traviSelezionate: {
    infoMessage: ['Travi per le quali è valida la seguente descrizione.'],
  },
  superficieTrave: {
    infoMessage: [
      'Superficie laterale totale della trave utile, come stima, ai fine della manutenzione.',
    ],
  },
  traversiSelezionati: {
    infoMessage: ['Traversi per i quali è valida la seguente descrizione.'],
  },
  superficieTraverso: {
    infoMessage: [
      'Superficie laterale totale del traverso utile, come stima, ai fine della manutenzione.',
    ],
  },
  controventiSelezionati: {
    infoMessage: ['Controventi per i quali è valida la seguente descrizione.'],
  },
  superficieControvento: {
    infoMessage: [
      'Superficie laterale totale del controvento utile, come stima, ai fine della manutenzione.',
    ],
  },
  archiSelezionati: {
    infoMessage: ['Archi per i quali è valida la seguente descrizione.'],
  },
  superficieArco: {
    infoMessage: [
      "Superficie laterale totale dell'arco utile, come stima, ai fine della manutenzione.",
    ],
  },
  piedrittiSelezionati: {
    infoMessage: ['Piedritti per i quali è valida la seguente descrizione.'],
  },
  altezzaPiedritto: {
    infoMessage: [
      'Nel caso di piedritti simili rispetto a tutte le altre caratteristiche, si può ragionevolmente considerare un valore medio.',
    ],
  },
  superficiePiedritto: {
    infoMessage: [
      'Superficie laterale totale del piedritto utile, come stima, ai fine della manutenzione.',
    ],
  },
  tirantiSelezionati: {
    infoMessage: ['Tiranti per i quali è valida la seguente descrizione.'],
  },
  lunghezzaTirante: {
    infoMessage: [
      'Nel caso di tiranti simili rispetto a tutte le altre caratteristiche, si può ragionevolmente considerare un valore medio.',
    ],
  },
  elementiExtraSelezionati: {
    infoMessage: ['Elementi per i quali è valida la seguente descrizione.'],
  },
  superficieElementoExtra: {
    infoMessage: [
      "Superficie laterale totale dell'elemento utile, come stima, ai fine della manutenzione.",
    ],
  },

  accessoSottoPonteParziale: {
    infoMessage: ['N° campate completamente ispezionate.'],
  },
  schemaPonteSovrastrutturaAccordion1: {
    drawerTitle: 'Creazione Schema Ponte - Tabella',

    drawerText: [
      {
        title: '',
        description: `Lo schema degli elementi accessori del ponte è creato a partire dal N° campate o alternativamente, se "Mensole"="si", N° Mensole e N° travi tampone. 
        Si crea una tabella che chiede di definire il numero degli elementi di impalcato (Pavimentazione-PV, Cordoli-CO, Marciapiedi-MA, Parapetti-PP, Guardiavia-GV, Spartitraffico-SP), se necessario distinguendoli tra destra (DX) e sinistra (SX), e corrispondenti a ciascuna Campata/Trave tampone/Mensola presente. Si ricorda che se è presente un solo elemento a delimitare l'impalcato questo viene classificato come Guardiavia.`,
      },
      {
        title: 'Sezione definizione elementi',
        description: '',
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/499_Sezione elementi.jpg',
      },
    ],
  },
  prospettiIspezionati: {
    drawerTitle: 'Prospetti ispezionati',
    drawerText: [
      {
        title: '',
        description:
          "Per convenzione, tutti gli elementi del ponte sono definiti a partire dalla progressiva chilometrica iniziale rivolti verso la chilometrica finale. In questo stesso modo si definiscono il lato destro e sinistro dell'opera.",
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/53.jpg',
      },
    ],
  },
  cordoliSelezionati: {
    infoMessage: ['Cordoli per i quali è valida la seguente descrizione.'],
  },
  affidabilitaComplessivaRischioFrane: {
    infoMessage: [
      "In generale, nel caso di frane potenziali, in via cautelativa, si può considerare un'affidabilità limitata dell'indagine.",
    ],
  },
  descrizioneAttivitaIspezione: {
    infoMessage: [
      'Possibilità di inserire commenti o considerazioni legate alle ispezioni eseguite.',
    ],
  },
  marciapiediSelezionati: {
    infoMessage: ['Marciapiedi per i quali è valida la seguente descrizione.'],
  },
  parapettiSelezionati: {
    infoMessage: ['Parapetti per i quali è valida la seguente descrizione.'],
  },
  guardavieSelezionati: {
    infoMessage: ['Guardavie per i quali è valida la seguente descrizione.'],
  },
  spartitrafficoSelezionati: {
    infoMessage: [
      'Spartitraffico per i quali è valida la seguente descrizione.',
    ],
  },
  misureMitigazione: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: 'Misure di mitigazione',
    drawerText: [
      {
        title: 'Fenomeno riconosciuto ma non ancora studiato',
        description:
          'Ponti non ancora studiati per cui le misure di stabilizzazione/monitoraggio risultano assenti.',
      },
      {
        title: 'Fenomeno riconosciuto e studiato',
        description:
          'Ponti studiati per cui le misure di stabilizzazione/monitoraggio risultano assenti.',
      },
      {
        title: 'Fenomeno modellato e oggetto di monitoraggio',
        description:
          'Ponti in cui sono presenti sistemi di monitoraggio atti a controllare l’insorgere di eventuali eventi franosi.',
      },
      {
        title: 'Fenomeno oggetto di opere di mitigazione',
        description:
          'Ponti per cui le misure di mitigazione del rischio definite siano effettivamente attuate.',
      },
    ],
  },
  docMisureMitigazione: {
    infoMessage: [
      'Inserire la documentazione relativa alla tipologia di intervento.',
    ],
  },
  estensioneInterferenzaFrana: {
    infoMessage: [
      `Definire il livello dell'interferenza tra il possibile evento frana e la struttura o parti di essa.
      Si evidenzia che, in attuazione alle Linee Guida Ponti, non è possibile determinare la CdA nel caso di "Altro".`,
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  fenomeno: {
    drawerTitle: 'Fenomeno',
    compassMessage: [`Linee Guida Ponti`],
    drawerText: [
      {
        title: '',
        description: `Il fenomeno franoso è potenziale, ovvero riconosciuto ma non ancora studiato, se è possibile osservare durante il sopralluogo dei deboli segni precursori recenti o è possibile riconoscere evidenti segni precursori come quelli proposti dall’Inventario dei Fenomeni Franosi in Italia (I.F.F.I.) per l’individuazione di frane potenziali, tra cui ad esempio i rigonfiamenti e l’apertura di fratture sulla superficie del terreno.
          Inoltre, nella definizione di fenomeni potenziali dovrebbero essere incluse anche quelle situazioni in cui la configurazione geomorfologica e l’assetto stratigrafico possano essere tali da indurre potenziale instabilità (ad esempio erosione al piede di un argine o di un versante a seguito di una piena eccezionale).`,
      },
    ],
  },
  tipologiaFenomeno: {
    compassMessage: ['Appendice B - Tabella B.1 - Linee Guida Ponti'],
    quoteMessage: ['Progetto IFFI - Inventario dei Fenomeni Franosi in Italia'],
  },
  distribuzioneAttivita: {
    drawerTitle:
      'Distribuzione di attività (se definibile dai dati in possesso)',
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
    drawerText: [
      {
        title: 'Costante',
        description:
          'Il movimento avviene con gradualità e continuità nel tempo senza variazioni nella quantità di volume coinvolto e senza modifiche della superficie di scorrimento.',
      },
      {
        title: 'Retrogressivo',
        description:
          'La superficie di rottura si estende in direzione opposta a quella del movimento del fenomeno franoso.',
      },
      {
        title: 'In allargamento',
        description:
          'La superficie di rottura tende al superamento e coinvolgimento di uno o di entrambi i fianchi della frana.',
      },
      {
        title: 'In avanzamento',
        description:
          'La superficie di rottura si estende propagandosi nella stessa direzione del movimento.',
      },
      {
        title: 'In diminuzione',
        description: 'Il volume di materiale coinvolto diminuisce nel tempo.',
      },
      {
        title: 'Confinato',
        description:
          'La superficie di rottura non è visibile al piede della massa mobilizzata, sono osservabili solo dei rigonfiamenti dovuti alla compressione della massa.',
      },
      {
        title: 'Multidirezionale',
        description:
          'Avviene su più piani di rottura che tendono ad aumentare il volume di materiale coinvolto.',
      },
    ],
  },
  usoSuoloAreaPotezialmenteCoinvolta: {
    drawerTitle: `Uso del suolo dell'area potenzialmente coinvolta`,
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
    drawerText: [
      {
        title: 'Area urbanizzata',
        description:
          'Territorio urbanizzato con caratteri di continuità, comprendente sia gli insediamenti residenziali, pubblici, commerciali e industriali che le infrastrutture (strade, ferrovie, ecc.).',
      },
      {
        title: 'Area estrattiva',
        description: 'Cave o miniere a cielo aperto attive o inattive',
      },
      {
        title: 'Seminativo',
        description: 'Terreni interessati da coltivazioni erbacee avvicendate.',
      },
      {
        title: 'Colture',
        description:
          'Colture arboree con disposizione ordinata (a filari, a quadranti ecc.; spaziatura < 10-15 m) che rappresentano la coltura principale del terreno anche se possono essere associate a colture erbacee (es. oliveti, vigneti, frutteti).',
      },
      {
        title: 'Vegetazione riparia',
        description:
          'Vegetazione arborea sviluppata sugli argini, le ripe e le golene di corsi d’acqua.',
      },
      {
        title: 'Rimboschimento',
        description:
          'Soprassuolo artificiale o naturale caratterizzato dalla giovane età e da un limitato sviluppo delle piante.',
      },
      {
        title: 'Bosco ceduo',
        description: 'Bosco di origine naturale sottoposto a taglio periodico.',
      },
      {
        title: 'Bosco d’alto fusto',
        description:
          'Bosco di origine naturale o artificiale comprendente alberi che hanno superato lo stato di novelleto o di posticcia (rimboschimento).',
      },
      {
        title: 'Incolto nudo',
        description:
          'Aree denudate o di affioramento del terreno o della roccia nuda.',
      },
      {
        title: 'Incolto macchia cespugliato',
        description: 'Aree cespugliate, macchia mediterranea.',
      },
      {
        title: 'Incolto prato pascolo',
        description:
          'Terreni a vegetazione erbacea permanente adibita a pascolo o a sfalcio.',
      },
    ],
  },
  posizioneInstabilitaVersante: {
    infoMessage: ['Si individua la posizione della frana sul versante stesso.'],
  },
  formazioniFrana: {
    drawerTitle: `Formazioni`,
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
    drawerText: [
      {
        title: '',
        description: `E' possibile indicare le caratteristiche geologiche e geologico-tecniche di una, due o più unità eventualmente presenti nel punto di innesco e interessate dall’evento franoso. La prima unità è per convenzione posta a quota più alta, la seconda a quota più bassa rispetto alla prima e così via. Nei campi "Formazioni" devono essere riportati i nomi delle formazioni riferiti alla cartografia geologica ufficiale 1:100.000 o 1:50.000 del Servizio Geologico d’Italia.`,
      },
    ],
  },
  elementoRiferimentoFrana: {
    infoMessage: [
      `Definire l'elemento di riferimento specificando dove è localizzato. Ad esempio mare, terreno, etc.`,
    ],
  },
  quotaOrloSuperiore: {
    infoMessage: [`Definito secondo il livello 0 m individuato.`],
  },
  quotaPonteViadotto: {
    infoMessage: [`Definito secondo il livello 0 m individuato.`],
  },
  profonditaSuperficieDistacco: {
    infoMessage: [
      `Profondità massima ipotizzata o misurata della superficie di rottura del fenomeno franoso.`,
    ],
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
  },
  areaTotaleFrana: {
    infoMessage: [`Area totale coinvolta nella frana.`],
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
  },
  volumeMassaFrana: {
    infoMessage: [`Volume finale (calcolato o stimato) della massa spostata.`],
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
  },
  velocitaMaxSpostamentoFrana: {
    compassMessage: [`Appendice B - Tabella B.2 - Linee Guida Ponti`],
    quoteMessage: [`Progetto IFFI - Inventario dei Fenomeni Franosi in Italia`],
  },
  magnitudoBaseVolumetrica: {
    infoMessage: [
      `Definibile come il volume della massa di terreno instabile. Per frane riconosciute, determinabile sulla base delle informazioni disponibili, integrando i dati cartografici con dati litostratigrafici e litotecnici.`,
    ],
  },
  annotazioniRischioFrane: {
    infoMessage: [
      `Possibilità di inserire commenti o considerazioni legate al rischio frane.`,
    ],
  },
  annotazioniAspettiCriticiRischioStrutturaleSismico: {
    infoMessage: [
      `Possibilità di inserire commenti o considerazioni legate al rischio strutturale e sismico.`,
    ],
  },
  schedaIspezioniPonteDifettiInfo: {
    drawerTitle: 'Scheda Ispezione',
    drawerText: [
      {
        title: '',
        description: `La compilazione della Scheda di valutazione dei difetti è fondamentale per determinare la Difettuosità Strutturale. Per ogni elemento vengono proposte le tipologie di difetto da ricercare.
    Nella compilazione della scheda occore fare ATTENZIONE ai seguenti aspetti:
    - Al termine delle operazioni di ispezione TUTTI I DIFETTI devono essere stati ricercati segnalati come visti;
    - Se viene spuntato NA o NR o NP, k1 e k2 non devono essere quantificati;
    - Solo una tra PS, NA, NR e NP può essere spuntata in corrispondenza dello stesso difetto.`,
      },
      {
        title: 'Elemento Critico',
        description: `Va indicato se l'ELEMENTO oggetto dell'analisi è CRITICO da un punto di vista della Vulnerabilità Strutturale e Fondazionale e/o dal punto di vista della Vulnerabilità Sismica.`,
      },
      {
        title: 'Condizione Critica',
        description: `Va indicato se l'elemento oggetto dell'analisi presenta CONDIZIONI CRITICHE da un punto di vista della Vulnerabilità Strutturale e Fondazionale e/o dal punto di vista della Vulnerabilità Sismica.`,
      },
      {
        title: 'Visto',
        description: `Spuntare quando il difetto è stato cercato ed oggetto di ispezione. Tutti i difetti presenti nella scheda vanno CERCATI in modo da completare TUTTI I FLAG della colonna. `,
      },
      {
        title: 'Non Applicabile (NA)',
        description: `Spuntare se il difetto non è applicabile alla tipologia di manufatto in esame.`,
      },
      {
        title: 'Non rilevabile (NR)',
        description: `Spuntare se il difetto non si può rilevare mediante ispezione visiva (es. per presenza di vegetazione invasiva, zone non accessibili). In questo caso occorre comunque spuntare il difetto come "Visto".`,
      },
      {
        title: 'Non Presente (NP)',
        description: `Spuntare se il difetto non è presente sulla struttura al momento dell’ispezione.`,
      },
      {
        title: 'Gravità difetto (G)',
        description: `Peso associato al tipo di difetto analizzato variabile da 1 (minima gravità) a 5 (massima gravità).`,
      },
      {
        title: 'Estensione K1',
        description: `Coefficiente che definisce la % di estensione del difetto sull'elemento. Valutato secondo i criteri proposti dalle schede difettologiche.  Per alcune tipologie di difetto potrebbe essere fissato a 1.`,
      },
      {
        title: 'Intensità K2',
        description: `Coefficiente che definisce la % di intensità del difetto sull'elemento. Valutato secondo i criteri proposti dalle schede difettologiche.  Per alcune tipologie di difetto potrebbe essere fissato a 1.`,
      },
      {
        title: 'Pregiudica  Statica (PS)',
        description: `Spuntare se si ritiene che la presenza del difetto possa compromettere la statica dell’opera. E’ associato ai soli difetti di gravità 4 e 5.`,
      },
      {
        title: 'Foto',
        description: `Possibilità di inserire una immagine del degrado segnalato. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3. Sarà possibile inserire una Didascalia descrittiva a commento. Tale descrizione comparirà nel report delle schede di ispezione.`,
      },
      {
        title: 'Note',
        description: `Possibilità di inserire una nota legata al difetto analizzato.`,
      },
      {
        title: 'Indice di Condizione (IC)',
        description: `Indice numerico che sintetizza le informazioni relative alla gravità (G), all’ estensione (k1) e all’intensità (k2) dei difetti rilevati sull’opera. Esso può variare nell’intervallo [0%,100%], con 0% = tutti i difetti presenti con massima estensione e intensità e 100%= nessun difetto rilevato.`,
      },
    ],
  },
  strutturaAlveoRischioIdraulico: {
    infoMessage: [
      `Verificare ad ogni modo che eventuali instabilità planimetriche/erosioni spondali dell'alveo non possano coinvolgere la struttura.`,
    ],
  },
  fenomenoErosioneRischioIdraulico: {
    drawerTitle: 'Fenomeni di erosione localizzata e generalizzata',
    drawerText: [
      {
        title: '',
        description: `Se le sottostrutture e i rilevati di accesso non interessano l’alveo anche durante un evento di piena, il fenomeno può essere definito "Assente", a discrezione del professionista. Per sottostrutture si intendono pile e spalle. Verificare ad ogni modo che eventuali instabilità planimetriche/erosioni spondali dell'alveo non possano coinvolgere le sottostrutture e i rilevati di accesso.`,
      },
    ],
  },
  scenariPericolositaRischioIdraulico: {
    drawerTitle: 'Scenari di pericolosità noti',
    drawerText: [
      {
        title: '',
        description: `Indicare quali scenari di pericolosità sono noti. Questo campo attiva i campi relativi alla definizione della quota di pelo libero per la stima della pericolosità da sormonto.`,
      },
      {
        title: `Corsi d'acqua non arginati`,
        description: (
          <>
            <div>
              {`Uno scenario di pericolosità è noto in due condizioni: `}
            </div>
            <ul>
              <li>{`qualora sia reperibile il livello idrico di piena da uno studio idraulico approvato (PGRA/PAI);`}</li>
            </ul>
            <ul>
              <li>{` per i soli corsi d’acqua non arginati, quando è possibile definire la quota massima della fascia di terreno interessata dallo scenario di alluvione P2 o P3.`}</li>
            </ul>
          </>
        ),
      },
      {
        title: `Corsi d'acqua  arginati`,
        description: `Per i corsi d’acqua arginati, selezionando l’opzione “Nessuno” è possibile compilare nella sezione “Sormonto o insufficienza di franco” il campo “Quota pelo libero stimata [m]” calcolandolo attraverso la definizione della quota dell’argine minore incrementata di 20 cm come indicato nelle Linee Guida. Anche se non espressamente dichiarato all’interno delle Linee Guida, è lasciata alla facoltà del professionista di utilizzare i livelli idrici forniti da studi idraulici approvati, selezionando quindi P2 nel campo Scenari di pericolosità. In questo caso, il franco idraulico verrà calcolato tramite la quota del pelo libero riferita allo scenario P2 anche per i corsi d’acqua arginati.`,
      },
    ],
  },
  ricadenteAreaAlluvioniRischioIdraulico: {
    linkMessage: ['https://idrogeo.isprambiente.it/app/'],
  },
  presenzaRilieviRischioIdraulico: {
    drawerTitle: 'Presenza rilievi sezioni fluviali e struttura',
    drawerText: [
      {
        title: '',
        description: `I rilievi delle sezioni fluviali sono normalmente disponibili per i corsi d'acqua del reticolo principale sul database dell'autorità di bacino competente o su database regionali.
        In alternativa, possono essere disponibili rilievi del fondo alveo all'interno degli elaborati grafici relativi al ponte.`,
      },
    ],
  },
  confinamentoAlveo: {
    drawerTitle: 'Confinamento alveo',
    drawerText: [
      {
        title: 'Confinato',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/118_Confinato.jpg',
      },
      {
        title: 'Semiconfinato',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/118_Semiconfinato.jpg',
      },
      {
        title: 'Non confinato',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/118_Non confinato.jpg',
      },
    ],
  },
  morfologiaAlveo: {
    drawerTitle: 'Morfologia alveo',
    drawerText: [
      {
        title: '',
        description: `Per identificare la morfologia dell’alveo, è necessario osservare l’alveo da immagini aeree.`,
      },
      {
        title: 'A canale singolo',
        description: `Rettilineo`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Rettilineo.jpg',
      },
      {
        title: 'A canale singolo',
        description: `Sinuoso`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Sinuoso.jpg',
      },
      {
        title: 'A canale singolo',
        description: `Meandriforme`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Meandriforme.jpg',
      },
      {
        title: 'Intrecciato',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_Intrecciato.jpg',
      },
    ],
  },
  sinuosita: {
    drawerTitle: 'Morfologia alveo',
    drawerText: [
      {
        title: 'Rettilineo',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Rettilineo.jpg',
      },
      {
        title: 'Sinuoso',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Sinuoso.jpg',
      },
      {
        title: 'Meandriforme',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/119_A canale singolo_Meandriforme.jpg',
      },
    ],
  },
  materialeAlveoRischioIdraulico: {
    infoMessage: [
      'Indicare i sedimenti di maggior pezzatura (escluse opere di protezione).',
    ],
  },
  evoluzioneAlveoRischioIdraulico: {
    infoMessage: [
      `Da valutare anche in base al confronto tra ortofoto storiche o evidenze di campo.`,
    ],
  },
  curvaturaAlveoRischioIdraulico: {
    infoMessage: [`Da valutare tramite immagini aeree.`],
  },
  divagazioneAlveoRischioIdraulico: {
    drawerTitle: "Tendenza dell'alveo a divagazione planimetrica",
    drawerText: [
      {
        title: '',
        description: `Solitamente questa caratteristica è evidente dall’osservazione di immagini aeree o da evidenti segni di erosione di sponda.`,
      },
      {
        title: 'Prima',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/126_Prima.jpg',
      },
      {
        title: 'Dopo',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/126_Dopo.jpg',
      },
    ],
  },
  tipologiaCorsoAcquaRischioIdraulico: {
    drawerTitle: "Tipologia corso d'acqua",
    drawerText: [
      {
        title: '',
        description: `La definizione di questo campo è utile ai fini della stima del franco idraulico per la valutazione della pericolosità del fenomeno di sormonto. A seguire, i dati richiesti per la stima del franco idraulico varieranno a seconda del caso selezionato.`,
      },
      {
        title: "Caso I: Corso d'acqua arginato",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/128_Caso I Corso d'acqua arginato.jpg",
      },
      {
        title:
          "Caso II: Corso d'acqua non arginato con possibilità di espansione laterale",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/128_Caso II Corso d'acqua non arginato con possibilità di espansione laterale.jpg",
      },
      {
        title:
          "Caso III: Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/128_Caso III Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte.jpg",
      },
      {
        title:
          "Caso IV: Corso d'acqua non arginato senza possibilità di espansione laterale",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/128_Caso IV Corso d'acqua non arginato senza possibilità di espansione laterale.jpg",
      },
    ],
  },
  elaboratoGraficoSezioneFluviale: {
    infoMessage: [
      "Ove possibile, rilevare le quote del fondo alveo rispetto al sistema di riferimento prescelto, in particolare quelle attorno alle pile e le spalle. Tali informazioni risultano fondamentali nella valutazione dell'evoluzione del fondo alveo nel tempo.",
    ],
  },
  riferimentoZeroMetriRischioIdraulico: {
    infoMessage: [
      "Definire l'elemento di riferimento, utile per definire le successive quote, specificando dove è localizzato. Ad esempio mare, terreno,parapetto, intradosso etc.",
    ],
  },
  quotaMinimaIntradossoRischioIdraulico: {
    drawerTitle: 'Quota minima intradosso del ponte [m]',
    drawerText: [
      {
        title: '',
        description: `Valutare la quota rispetto allo 0 di riferimento prescelto. Non inserendo questo dato, la classe di pericolosità del sormonto non può essere valutata e verrà considerata in automatico "Alta".`,
      },
      {
        title: 'Ponti a travata',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/134_Ponti a travata.jpg',
      },
      {
        title: 'Ponti ad arco',
        description: `Per i ponti ad arco è riferita all'altezza corrispondente ad una corda centrale pari a 2/3 della luce. `,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/134_Ponti a arco.jpg',
      },
    ],
  },
  quotaPeloLiberoP2RischioIdraulico: {
    infoMessage: [
      'Quota del pelo libero relativa a eventi con scenario P2, ovvero con tempo di ritorno compreso tra 100 e 200 anni. Questa quota, in assenza di studio idraulico che lo definisca, equivale alla quota massima della fascia di terreno interessata dallo scenario di alluvione P2.',
    ],
    compassMessage: ['Capitolo 4.5.1 delle Linee Guida Ponti'],
  },
  quotaPeloLiberoP3RischioIdraulico: {
    infoMessage: [
      'Quota del pelo libero relativa a eventi con scenario P3, ovvero con tempo di ritorno compreso tra 20 e 50 anni. Questa quota, in assenza di studio idraulico che lo definisca, equivale alla quota massima della fascia di terreno interessata dallo scenario di alluvione P3.',
    ],
    compassMessage: ['Capitolo 4.5.1 delle Linee Guida Ponti'],
  },
  deposizioneSedimenti: {
    drawerTitle: 'Evidente e accentuata deposizione di sedimenti',
    drawerText: [
      {
        title: '',
        description: `Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni di erosione d'alveo.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/145.jpg',
      },
      {
        title: '',
        description: `Esempio in cui il deposito di sedimenti limita la capacità di deflusso al di sotto di una delle campate `,
      },
    ],
  },
  materialeVegetaleRischioIdraulico: {
    drawerTitle: 'Trasporto di materiale vegetale di notevole dimensione',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Evidenza di trasporto di materiale vegetale di notevole dimensione.
Per notevole dimensione si intende tale da potenzialmente ostruire la sezione idraulica con conseguente riduzione della capacità di deflusso, come nell’immagine d’esempio.`}
            </div>
            <div>
              {`In assenza di notizie di recenti eventi alluvionali che abbiano coinvolto il ponte in esame, nel caso in cui la struttura attraversi corsi d’acqua con bacino imbrifero inferiore a 100 km², qualora si rilevi Bassa pericolosità per sormonto e franco idraulico pari ad almeno il doppio dell’altezza del pelo libero con un valore minimo di 3 m, l’ispettore può valutare se attribuire o meno significatività all’evidenza di trasporto di materiale vegetale di notevole dimensione.`}
            </div>
          </>
        ),
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/147.jpg',
      },
    ],
  },
  quotaArgineMinRischioIdraulico: {
    drawerTitle: 'Quota argine (min fra i due) [m]',
    drawerText: [
      {
        title: '',
        description: `Valutare la quota rispetto allo 0 di riferimento prescelto.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/135.jpg',
      },
    ],
  },
  quotaPeloLiberoStimaRischioIdraulico: {
    drawerTitle: 'Quota pelo libero stimata [m]',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div style={{ lineHeight: 1.5 }}>
              {`Identificare e rilevare le quote del terreno durante le fasi ispettive di campo, a seconda dei casi, può risultare non immediato. In questi situazioni si suggerisce di identificare le quote tramite rilievi digitali del terreno (dtm).`}
            </div>
            <div
              style={{ lineHeight: 1.5 }}
            >{`Non inserendo questo dato, e in assenza della quota per scenario P2, la classe di pericolosità del sormonto verrà considerata in automatico "Alta", a meno di evidenze di campo per cui il franco può ritenersi rispettato (voce "Franco >2 m per evidenze di campo").`}</div>
          </>
        ),
      },
      {
        title: "Caso I: Corso d'acqua arginato",
        description: `Quota pelo libero stimata = Quota argine (min fra i due) + 20 cm`,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/140_Caso I Corso d'acqua arginato.jpg",
      },
      {
        title:
          "Caso II: Corso d'acqua non arginato con possibilità di espansione laterale",
        description: `Quota pelo libero stimata = Quota sponda (max fra le due) + 50 cm`,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/140_Caso II Corso d'acqua non arginato con possibilità di espansione laterale.jpg",
      },
      {
        title:
          "Caso III: Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte",
        description: `Quota pelo libero stimata = Quota fondo alveo + 15 m`,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/140_Caso III Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte.jpg",
      },
      {
        title:
          "Caso IV: Corso d'acqua non arginato senza possibilità di espansione laterale",
        description: `Quota pelo libero stimata = Calcolabile solamente tramite studio idraulico.`,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/140_Caso IV Corso d'acqua non arginato senza possibilità di espansione laterale.jpg",
      },
    ],
  },
  francoIdraulicoStimato: {
    drawerTitle: 'Franco Idraulico stimato [m]',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Identificare e rilevare le quote del terreno durante le fasi ispettive di campo, a seconda dei casi, può risultare non immediato. In questi situazioni si suggerisce di identificare le quote tramite rilievi digitali del terreno (dtm).`}
            </div>
            <div>{`Non inserendo questo dato, e in assenza della quota per scenario P2, la classe di pericolosità del sormonto verrà considerata in automatico "Alta", a meno di evidenze di campo per cui il franco può ritenersi rispettato (voce "Franco > 2 m per evidenze di campo").`}</div>
          </>
        ),
      },
      {
        title: "Caso I: Corso d'acqua arginato",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/143_Caso I Corso d'acqua arginato.jpg",
      },
      {
        title:
          "Caso II: Corso d'acqua non arginato con possibilità di espansione laterale",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/143_Caso II Corso d'acqua non arginato con possibilità di espansione laterale.jpg",
      },
      {
        title:
          "Caso III: Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/143_Caso III Corso d'acqua non arginato senza possibilità di espansione laterale e pile molto alte.jpg",
      },
      {
        title:
          "Caso IV: Corso d'acqua non arginato senza possibilità di espansione laterale",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/143_Caso IV Corso d'acqua non arginato senza possibilità di espansione laterale.jpg",
      },
    ],
  },
  francoIdraulicoSuperiore: {
    drawerTitle: `Franco > 2 m per evidenze di campo`,
    drawerText: [
      {
        title: '',
        description: `In assenza di studio idraulico e qualora le grandezze dei punti precedenti non siano facilmente definibili, si può assumere,per evidenze di campo, che il franco sia maggiore di 2.00 metri (per es. se impalcato molto alto rispetto al corso d'acqua e al terreno circostante). In questo caso, la pericolosità da sormonto verrà considerata Bassa. Diversamente, essa verrà considerata Alta`,
      },
    ],
  },
  quotaSpondaMaxRischioIdraulico: {
    drawerTitle: 'Quota sponda (max fra le due) (Caso II) [m]',
    drawerText: [
      {
        title: '',
        description: `Valutare la quota rispetto allo 0 di riferimento prescelto.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/136.jpg',
      },
    ],
  },
  quotaFondoAlveoRischioIdraulico: {
    drawerTitle: 'Quota fondo alveo (Caso III) [m]',
    drawerText: [
      {
        title: '',
        description: `Valutare la quota rispetto allo 0 di riferimento prescelto.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/137.jpg',
      },
    ],
  },
  larghezzaAlveoPileSpalle: {
    drawerTitle: (
      <>
        W<sub>a,l</sub> [m]
      </>
    ),
    drawerText: [
      {
        title: '',
        description: (
          <>
            Larghezza complessiva alveo inciso occupata da pile e spalle, W
            <sub>a,l</sub> = ∑ W<sub>a,l(i)</sub>
          </>
        ),
      },
      {
        title: 'Corso d’acqua in assenza di area golenale',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/150_Corso d’acqua in assenza di area golenale.jpg',
      },
      {
        title: 'Corso d’acqua in presenza di area golenale',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/150_Corso d’acqua in presenza di area golenale.jpg',
      },
      {
        title:
          "Corso d'acqua non arginato senza possibilità di espansione laterale (Caso III e IV)",
        description: ``,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/150_Corso d'acqua non arginato senza possibilità di espansione laterale (Caso III e IV).jpg",
      },
    ],
  },
  larghezzaAlveoPonte: {
    drawerTitle: 'Wₐ [m]',
    drawerText: [
      {
        title: '',
        description: `Larghezza complessiva dell'alveo inciso a monte del ponte, Wₐ`,
      },
      {
        title: 'Corso d’acqua in assenza di area golenale',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/151_Corso d’acqua in assenza di area golenale.jpg',
      },
      {
        title: 'Corso d’acqua in presenza di area golenale',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/151_Corso d’acqua in presenza di area golenale.jpg',
      },
      {
        title:
          "Corso d'acqua non arginato senza possibilità di espansione laterale (Caso III e IV)",
        description: `In questo caso la larghezza dell'alveo inciso Wₐ dipende dalla quota del pelo libero stimata.`,
        image:
          "https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/151_Corso d'acqua non arginato senza possibilità di espansione laterale (Caso III e IV).jpg",
      },
    ],
  },
  fattoreRestringimentoAlveo: {
    infoMessage: [
      <>
        Pari al rapporto tra W<sub>a,l</sub> e W<sub>a</sub>
      </>,
    ],
  },
  larghezzaComplessivaGolene: {
    drawerTitle: (
      <>
        W<sub>g,l</sub> [m]
      </>
    ),
    drawerText: [
      {
        title: 'Corso d’acqua in presenza di area golenale',
        description: '',
      },
      {
        title: '',
        description: (
          <>
            Larghezza complessiva delle golene occupata dai rilevati di accesso,
            dalle spalle e dalle pile, W<sub>g,l</sub> = ∑ W<sub>g,l(i)</sub>
          </>
        ),
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/153_Corso d’acqua in presenza di area golenale.jpg',
      },
      // {
      //   title: '',
      //   description: (
      //     <>
      //       Larghezza complessiva delle golene occupata dai rilevati di accesso,
      //       dalle spalle e dalle pile, W<sub>g,l</sub> = ∑ W<sub>g,l(i)</sub>
      //     </>
      //   ),
      //   image:
      //     'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/153_Corso d’acqua in presenza di area golenale.jpg',
      // },
    ],
  },
  larghezzaComplessivaGolenePonte: {
    drawerTitle: (
      <>
        W<sub>g</sub> [m]
      </>
    ),
    drawerText: [
      {
        title: 'Corso d’acqua in presenza di area golenale',
        description: ``,
      },
      {
        title: '',
        description: (
          <>
            Larghezza complessiva delle golene a monte del ponte W<sub>g</sub> =
            W<sub>g1</sub> + W<sub>g2</sub>
          </>
        ),
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/153_Corso d’acqua in presenza di area golenale.jpg',
      },
    ],
  },
  fattoreRestringimentoAreeGolenali: {
    infoMessage: [
      <>
        Pari al rapporto tra W<sub>g,l</sub> e W<sub>g</sub>
      </>,
    ],
  },
  larghezzaPilaOAggettoSpalla: {
    drawerTitle: 'Larghezza pila o aggetto spalla a [m]',
    drawerText: [
      {
        title: '',
        description: `Nel caso in cui il fenomeno erosivo sia già in atto, è necessario rilevare l'impronta della struttura che materialmente ostacola il deflusso.
        Inserire 0 m se le pile e le spalle non interferiscono con il corso d'acqua in caso di piena.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/157.jpg',
      },
    ],
  },
  profonditaScavoMax: {
    infoMessage: [
      "Per le pile, considerare il doppio della larghezza pila; per le spalle, considerare quattro volte l'aggetto spalla. In caso di fondazione superficiale impostata su roccia compatta, considerare ds come profondità del substrato roccioso compatto rispetto al fondo alveo.",
    ],
  },
  profonditaFondazioneRispettoAlveo: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: (
      <>
        Profondità fondazione rispetto all&apos;alveo d<sub>f</sub> [m]
      </>
    ),
    drawerText: [
      {
        title: '',
        description: (
          <div>
            Nel caso di fondazioni superficiali la profondità del piano di posa
            di fondazione è definita dalla profondità della superficie di
            appoggio della fondazione rispetto al piano di campagna. In assenza
            di evidenze sulle fondazioni, impostare df pari a 2 metri. Ancorché
            non sia possibile stimare con precisione la profondità del piano di
            posa di fondazione, ma le evidenze di campo suggeriscano che sia
            molto ridotta, sarà necessaria una stima seppur approssimata del suo
            valore. Per fondazioni profonde, considerare la metà della lunghezza
            del palo o la metà della profondità del pozzo dal piano di posa. Se
            la profondità dei pali o del pozzo non è nota, impostare d
            <sub>f</sub> pari a 4 metri. Nel caso in cui non sia possibile
            stabilire con sufficiente affidabilità se la fondazione sia di tipo
            profondo si suggerisce di assumere cautelativamente che la
            fondazione sia di tipo superficiale, con profondità di riferimento
            almeno pari a 2.00 m.
          </div>
        ),
      },
    ],
  },
  fattoreIEL: {
    infoMessage: [
      <>
        Indice di Erosione Localizzata, definito dal rapporto tra d<sub>s</sub>{' '}
        e d<sub>f</sub>
      </>,
    ],
  },
  evidenzeProfonditaDelleFondazioni: {
    drawerTitle: 'Evidenze sulla profondità delle fondazioni',
    drawerText: [
      {
        title: '',
        description: `Nel caso in cui non sia possibile stabilire con sufficiente
              affidabilità la tipologia di fondazioni, scegliere l’opzione
              “Nessuna evidenza”. In questo caso la fondazione verrà considerata
              cautelativamente come superficiale nel calcolo della vulnerabilità
              legata ai fenomeni erosivi. Le fondazioni superficiali a struttura
              monolitica di calcestruzzo impostate su roccia compatta o
              debolmente fratturata possono essere equiparate a fondazioni
              profonde.`,
      },
      {
        title: 'Evidenza di fondazioni profonde',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/164_Profonde.jpg',
      },
      {
        title: 'Evidenza di fondazioni superficiali',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/164_Superficiali.jpg',
      },
    ],
  },
  evoluzioneEventualeDelFondo: {
    infoMessage: [
      'Segnalare eventuale innalzamento/abbassamento nel tempo del fondo alveo rispetto alla pila.',
    ],
  },
  abbassamentoGeneralizzatoAlveo: {
    drawerTitle: "Abbassamento generalizzato dell'alveo",
    drawerText: [
      {
        title: '',
        description: `Evidenza di fenomeni di abbassamento generalizzato dell'alveo a monte e a valle del ponte.
        E’ possibile identificare evidenze di abbassamento dell’alveo osservando il livello dei basamenti delle pile o protezioni del fondo danneggiate.`,
      },
      {
        title: 'Alveo di grandi dimensioni',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/161_1.jpg',
      },
      {
        title: 'Alveo di modeste dimensioni',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/161_2.jpg',
      },
    ],
  },
  accumuliDetritiAMontePila: {
    drawerTitle: 'Accumuli di detriti o materiale flottante a monte della pila',
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/166.jpg',
      },
    ],
  },
  opereProtezioneSpondale: {
    drawerTitle: 'Opere di protezione spondale',
    drawerText: [
      {
        title: 'Pennelli',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_1.jpg',
      },
      {
        title: 'Scogliera',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_2.jpg',
      },
      {
        title: 'Argini',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_3.jpg',
      },
      {
        title: 'Gabbioni',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_4.jpg',
      },
      {
        title: 'Muro di sponda',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_5.jpg',
      },
      {
        title: 'Altre opere',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/168_6.jpg',
      },
    ],
  },
  protezioneAlPiedePileSpalle: {
    drawerTitle: 'Protezione al piede delle pile e delle spalle del ponte',
    drawerText: [
      {
        title: 'Rip-Rap',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/171_Rip-Rap.jpg',
      },
      {
        title: 'Palancole',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/171_Palancole.jpg',
      },
    ],
  },
  statoConservazioneProtezionePiede: {
    drawerTitle: 'Stato di conservazione e adeguatezza opera',
    drawerText: [
      {
        title: '',
        description: `E’ importante segnalare eventuali danneggiamenti o inadeguatezze delle opere di protezione, come nell’esempio figura.`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/172.jpg',
      },
    ],
  },
  brigliaProtezioneValleDelPonte: {
    drawerTitle: 'Briglia di protezione immediatamente a valle del ponte',
    drawerText: [
      {
        title: '',
        description: `Tra le opere di protezione sono comprese soglie di fondo, traverse, etc. 
        E’ importante segnalare eventuali danneggiamenti o inadeguatezze delle opere di protezione.`,
      },
      {
        title: 'Briglia',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/174_Briglia.PNG',
      },
      {
        title: 'Soglia di fondo',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/174_Soglia.jpg',
      },
    ],
  },

  elementiCriticiVulnerabilitaStrutturale: {
    compassMessage: ['Capitolo 3.3 delle Linee Guida Ponti'],
    drawerTitle: 'Elementi critici – Vulnerabilità strutturale e fondazionale',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Si definisce elemento critico, un elemento che presenta particolari caratteristiche di potenziale fragilità e la cui crisi può comportare la crisi dell’intera struttura o di una sua porzione, oppure la perdita di funzionalità dell’opera stessa (ad esempio selle Gerber, cavi di acciaio ad alto carbonio, etc…).`}
            </div>
            <div>
              {`A quelli sovra citati, si aggiungono tutti gli elementi che, secondo il tecnico incaricato dell’ispezione, necessitano di particolare attenzione e richiedono provvedimenti immediati.
La presenza di elementi critici con stato di degrado avanzato (presenza di G=4, G=5) comporta un livello di difettosità attuale alto.`}
            </div>
          </>
        ),
      },
    ],
  },
  definizioneElementiSottostrutturaSovrastruttura: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Definizione elementi di sottostruttura e sovrastruttura`,
    drawerText: [
      {
        title: '',
        description: (
          <>
            {`Ai fini del processo di determinazione del livello di difettosità, sia strutturale che sismico, dell'intera opera i diversi elementi strutturali e di connessione possono essere raggruppati all’interno delle seguenti maggiori
categorie:`}

            <ul>
              <li>
                {`Sovrastruttura: raggruppa tutti gli elementi e le strutture orizzontali del ponte che costituiscono l’impalcato. Può essere costituita da più campate.`}
              </li>
              <li>
                {`Sottostruttura: raggruppa le pile, le spalle, le antenne, le fondazioni del ponte. Ai fini della determinazione del livello di difettosità, si associano ad ogni pila i rispettivi apparecchi di appoggio.`}
              </li>
            </ul>
            {`Si riscontra quindi la necessità, nel caso di schema Mensole/Travi tampone, di associare ogni mensola e trave tampone alla sovrastruttura corrispondente alla campata.`}
          </>
        ),
      },
    ],
  },
  elementiCriticiVulnerabilitaSismica: {
    compassMessage: ['Capitolo 3.3 delle Linee Guida Ponti'],
    drawerTitle: 'Elementi critici – Vulnerabilità sismica',
    drawerText: [
      {
        title: '',
        description: (
          <>
            <div>
              {`Si definisce elemento critico nell’ambito del rischio sismico, un elemento che presenta particolari caratteristiche di fragilità e la cui crisi può comportare la crisi dell’intera struttura o di una sua porzione, oppure la perdita di funzionalità dell’opera stessa in caso di accadimento di un evento sismico (ad esempio apparecchi di appoggio, sezioni di estremità delle pile, etc…). `}
            </div>
            <div>
              {`A quelli sovra citati, si aggiungono tutti gli elementi che, secondo il tecnico incaricato dell’ispezione, necessitano di particolare attenzione e richiedono provvedimenti immediati.
La presenza di elementi critici con stato di degrado avanzato (presenza di G=4, G=5) comporta un livello di difettosità attuale alto.`}
            </div>
          </>
        ),
      },
    ],
  },
  condizioniCriticheVulnerabilitaStrutturale: {
    drawerTitle:
      'Condizioni critiche - Vulnerabilità strutturale e fondazionale',
    drawerText: [
      {
        title: '',
        description: `Si definisce condizione critica, una condizione di possibile collasso generata dalla presenza difetti di gravità alta o medioalta (G=5 o G=4) e di intensità ed estensione elevata su un insieme significativo di elementi per numero e/o per posizione, ma anche quadri fessurativi molto estesi ed intensi, meccanismi di incipiente perdita di appoggio o cinematismi in atto, giunzioni di elementi chiave per la staticità del ponte.`,
      },
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  condizioniCriticheSismica: {
    drawerTitle: 'Condizioni critiche - Vulnerabilità sismica',
    drawerText: [
      {
        title: '',
        description: `Nell’ambito del rischio sismico, si definisce condizione critica, una condizione di possibile collasso in caso di accadimento di un evento sismico, generata dalla presenza difetti di gravità alta o medio-alta (G=5 o G=4) e di intensità ed estensione elevata su un insieme significativo di elementi per numero e/o per posizione, ma anche quadri fessurativi molto estesi ed intensi, meccanismi di incipiente perdita di appoggio o cinematismi in atto, giunzioni di elementi chiave per la staticità del ponte.`,
      },
    ],
    legislationMessage: ['Linee Guida Ponti'],
  },
  elementiVulnerabilitaSismica: {
    compassMessage: ['Capitolo 4.3.3 delle Linee Guida Ponti'],
    drawerTitle: 'Elementi di vulnerabilità sismica',
    drawerText: [
      {
        title: '',
        description:
          "Elementi influenti sul comportamento sismico, quali impalcati sghembi o in curva, pile a singola colonna o con altezza molto disuniforme, presenza di appoggi particolarmente soggetti a degrado (quali appoggi a pendolo interamente in metallo, tipicamente soggetti ad elevata corrosione) o, in generale, situazioni che determinano concentrazioni di sforzo, moti rotazionali o quant'altro costituisce un'aggravante alla vulnerabilità del ponte. La presenza di elementi di vulnerabilità sismica comporta l'aumento di un livello rispetto a quello definito dalla classe di vulnerabilità data dallo schema statico, luce e materiale della Tabella 4.13 delle Linee Guida Ponti.",
      },
    ],
  },
  normaProgetto: {
    drawerTitle: 'Classe di progetto',
    drawerText: [
      {
        title: '',
        description: `Indicare la Classe di riferimento per la norma di progettazione.
La tabella sottostante riporta la corrispondente classificazione della norma di progetto sulla base delle Linee Guida.
Attenzione: la Circolare 820/1952 è stata emanata da ANAS ed è valida per i soli ponti di strade statali e autostradali.
Nel caso in cui la categoria di intervento sia stata di adeguamento (interventi atti ad aumentare la sicurezza strutturale preesistente conseguendo i livelli di sicurezza fissati al § 8.4.3 delle NTC2018), l’opera è da considerarsi di Classe C.
Nel caso di interventi di miglioramento e riparazione locale occorre valutare se tali interventi hanno influito sulla capacità globale dell’opera (§ 8.4 delle NTC2018); in mancanza di specifica documentazione che attesti il livello di sicurezza raggiunto, si dovrebbe far riferimento alla classe riferita all’anno di progettazione.
Nel caso siano state eseguite valutazioni di sicurezza con esito positivo nei confronti dei carichi da traffico in accordo con le norme pubblicate dal 2005 in poi, l’opera è da considerarsi di Classe C.`,
      },
    ],
    drawerImages: [
      {
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/193.jpg',
      },
    ],
  },
  condizioneStrutturaleCriticaInfo: {
    legislationMessage: [`Linee Guida Ponti`],
    drawerTitle: `Condizione critica - Vulnerabilità strutturale e fondazionale`,
    drawerText: [
      {
        title: '',
        description: `Si definisce condizione critica, una condizione di possibile collasso generata dalla presenza difetti di gravità alta o medioalta (G=5 o G=4) e di intensità ed estensione elevata su un insieme significativo di elementi per numero e/o per posizione, ma anche quadri fessurativi molto estesi ed intensi, meccanismi di incipiente perdita di appoggio o cinematismi in atto, giunzioni di elementi chiave per la staticità del ponte.`,
      },
    ],
  },
  condizioneSismicaCriticaInfo: {
    legislationMessage: ['Linee Guida Ponti'],
    drawerTitle: `Condizione critica - Vulnerabilità sismica`,
    drawerText: [
      {
        title: '',
        description: `Nell’ambito del rischio sismico, si definisce condizione critica, una condizione di possibile collasso in caso di accadimento di un evento sismico, generata dalla presenza difetti di gravità alta o medio-alta (G=5 o G=4) e di intensità ed estensione elevata su un insieme significativo di elementi per numero e/o per posizione, ma anche quadri fessurativi molto estesi ed intensi, meccanismi di incipiente perdita di appoggio o cinematismi in atto, giunzioni di elementi chiave per la staticità del ponte.`,
      },
    ],
  },
  elementoStrutturaleCriticoInfo: {
    compassMessage: ['Capitolo 3.3 delle Linee Guida Ponti'],
    drawerTitle: 'Elemento Critico - Vulnerabilità strutturale e fondazionale',
    drawerText: [
      {
        title: '',
        description: `Si definisce elemento critico, un elemento che presenta particolari caratteristiche di potenziale fragilità e la cui crisi può comportare la crisi dell’intera struttura o di una sua porzione, oppure la perdita di funzionalità dell’opera stessa (ad esempio selle Gerber, cavi di acciaio ad alto carbonio, etc…). 
        A quelli sovra citati, si aggiungono tutti gli elementi che, secondo il tecnico incaricato dell’ispezione, necessitano di particolare attenzione e richiedono provvedimenti immediati.
        La presenza di elementi critici con stato di degrado avanzato (presenza di G=4, G=5) comporta un livello di difettosità attuale alto.`,
      },
    ],
  },
  elementoSismicoCriticoInfo: {
    compassMessage: ['Capitolo 3.3 delle Linee Guida Ponti'],
    drawerTitle: 'Elemento Critico - Sismica',
    drawerText: [
      {
        title: '',
        description: `Si definisce elemento critico nell’ambito del rischio sismico, un elemento che presenta particolari caratteristiche di fragilità e la cui crisi può comportare la crisi dell’intera struttura o di una sua porzione, oppure la perdita di funzionalità dell’opera stessa in caso di accadimento di un evento sismico (ad esempio apparecchi di appoggio, sezioni di estremità delle pile, etc…). 
        A quelli sovra citati, si aggiungono tutti gli elementi che, secondo il tecnico incaricato dell’ispezione, necessitano di particolare attenzione e richiedono provvedimenti immediati.
        La presenza di elementi critici con stato di degrado avanzato (presenza di G=4, G=5) comporta un livello di difettosità attuale alto.`,
      },
    ],
  },
  schedaIspezioni20PonteDifettiInfo: {
    drawerTitle: 'Scheda Ispezione',
    drawerText: [
      {
        title: '',
        description: `La compilazione della Scheda di valutazione dei difetti è fondamentale per valutare i difetti degli elementi accessori. Per ogni elemento vengono proposte le tipologie di difetto da ricercare.
        Nella compilazione della scheda occore fare ATTENZIONE ai seguenti aspetti:
        - Al termine delle operazioni di ispezione TUTTI I DIFETTI devono essere stati ricercati e segnalati come "Visto";
        - Solo una tra NR e "Presente" può essere spuntata in corrispondenza dello stesso difetto.`,
      },

      {
        title: 'Visto',
        description: `Spuntare quando il difetto è stato cercato ed oggetto di ispezione. Tutti i difetti presenti nella scheda vanno CERCATI in modo da completare TUTTI I FLAG della colonna. `,
      },
      {
        title: 'Gravità difetto (G)',
        description: `Peso associato al tipo di difetto analizzato variabile da 1 (minima gravità) a 5 (massima gravità).`,
      },
      {
        title: 'Presente',
        description: `Spuntare se il difetto è effettivamente presente.`,
      },
      {
        title: 'Non rilevabile (NR)',
        description: `Spuntare se il difetto non si può rilevare mediante ispezione visiva (es. per presenza di vegetazione invasiva, zone non accessibili). In questo caso occorre comunque spuntare il difetto come "Visto".`,
      },
      {
        title: 'Foto',
        description: `Possibilità di inserire una immagine del degrado segnalato. Per un'ottimizzazione nel report, si consiglia di eseguire foto in orizzontale in rapporto 4:3. Sarà possibile inserire una Didascalia descrittiva a commento. Tale descrizione comparirà nel report delle schede di ispezione.`,
      },
      {
        title: 'Note',
        description: `Possibilità di inserire una nota legata al difetto analizzato.`,
      },
    ],
  },
  elevazioneSpalle: {
    drawerTitle: 'Elevazione / Spalle (S)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Spalle 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Spalle rilievo.jpg',
      },
    ],
  },
  elevazionePile: {
    drawerTitle: 'Elevazione / Pile (P)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A parete piena`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_1.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A doppia parete`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_2.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A cassoni`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_3.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A cassoni separati`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_4.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A telaio`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_5.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A colonna`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_6.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A più colonne`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_7.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `A doppia lama`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pile rilievo_8.jpg',
      },
      {
        // title: '',
        // description: ``,
      },
    ],
  },
  collegamentiAppoggi: {
    drawerTitle: 'Collegamenti / Appoggi (AP)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Appoggio 3D.jpg',
      },
    ],
  },
  collegamentiGiunti: {
    drawerTitle: 'Collegamenti / Giunti (G)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Giunti 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Giunto rilievo.jpg',
      },
    ],
  },
  collegamentiPulvini: {
    drawerTitle: 'Collegamenti / Pulvini (PU)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pulvini 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pulvini rilievo.jpg',
      },
    ],
  },
  collegamentiAntenne: {
    drawerTitle: 'Collegamenti / Antenne (AN)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Antenna 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Antenna rilievo.jpg',
      },
    ],
  },
  collegamentiAltriDispositiviSismici: {
    drawerTitle: 'Collegamenti / Altri dispositivi sismici (DS)',
    drawerText: [
      {
        title: '',
        description: `In questa sezione possono essere descritte altre tipologie di dispositivi antisismici presenti sul ponte, definedone il nome e inserendone un'immagine. ATTENZIONE: di questi elementi non sarà compilata la scheda dei difetti e non entreranno nella definizione del Livello di Difettosità.`,
      },
    ],
  },
  impalcatoSolette: {
    drawerTitle: 'Impalcato / Soletta (SO)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Soletta 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Soletta rilievo.jpg',
      },
    ],
  },
  impalcatoTravi: {
    drawerTitle: 'Impalcato / Travi (TP)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Travi 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `Trave semplice`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Travi rilievo_1.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `Trave a cassone`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Travi rilievo_2.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `Trave prefabbricata`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Travi rilievo_3.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: `Trave sagomata`,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Travi rilievo_4.jpg',
      },
      {},
    ],
  },
  impalcatoTraversi: {
    drawerTitle: 'Impalcato / Traversi (TS)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Traversi 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Traversi rilievo.jpg',
      },
    ],
  },
  impalcatoControventi: {
    drawerTitle: 'Impalcato / Controventi (CV)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Controventi 3D.jpg',
      },
    ],
  },
  impalcatoArchi: {
    drawerTitle: 'Impalcato / Archi (A)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Archi 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Archi rilievo.jpg',
      },
    ],
  },
  impalcatoPiedritti: {
    drawerTitle: 'Impalcato / Piedritti (PD)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Piedritti 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Piedritti rilievo.jpg',
      },
    ],
  },
  impalcatoTiranti: {
    drawerTitle: 'Impalcato / Tiranti (TN)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Tiranti 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Tiranti rilievo.jpg',
      },
    ],
  },
  impalcatoElementiExtra: {
    drawerTitle: 'Impalcato / Elementi extra (EX)',
    drawerText: [
      {
        title: '',
        description: `In questa sezione possono essere descritti altri elementi extra presenti sul ponte, definedone il nome, la tipologia e la geometria e inserendone un'immagine. ATTENZIONE: di questi elementi sarà compilata la scheda dei difetti utilizzando le schede corrispondenti alla tipologia di materiale e entreranno nella definizione del Livello di Difettosità.`,
      },
    ],
  },
  impalcatoPavimento: {
    drawerTitle: 'Impalcato / Pavimentazione (PV)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pavimento 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Pavimento rilievo.jpg',
      },
    ],
  },
  impalcatoCordoli: {
    drawerTitle: 'Impalcato / Cordolo (CO)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Cordolo 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Cordolo rilievo.jpg',
      },
    ],
  },
  impalcatoMarciapiede: {
    drawerTitle: 'Impalcato / Marciapiede (MA)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Marciapiede 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Marciapiede rilievo.jpg',
      },
    ],
  },
  impalcatoParapetti: {
    drawerTitle: 'Impalcato / Parapetto (PP)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Parapetto 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Parapetto rilievo.jpg',
      },
    ],
  },
  impalcatoGuardavie: {
    drawerTitle: 'Impalcato / Guardavia (GV)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Guardavia 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Guardavia rilievo.jpg',
      },
    ],
  },
  impalcatoSpartitraffico: {
    drawerTitle: 'Impalcato / Spartitraffico (ST)',
    drawerText: [
      {
        title: 'Logica numerazione elementi',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Spartitraffico 3D.jpg',
      },
      {
        title: 'Dettaglio del rilievo geometrico',
        description: ``,
        image:
          'https://tecnoindaginistack-develop-content.s3.eu-west-1.amazonaws.com/info_images/Spartitraffico rilievo.jpg',
      },
    ],
  },
  superficiePavimentazione: {
    infoMessage: [
      `Superficie laterale totale della pavimentazione dell'impalcato utile, come stima, ai fine della manutenzione.`,
    ],
  },
  superficieMarciapiede: {
    infoMessage: [
      `Superficie del marciapiede dell'impalcato utile, come stima, ai fine della manutenzione.`,
    ],
  },
  superficieParapetto: {
    infoMessage: [
      `Superficie laterale totale del parapetto dell'impalcato utile, come stima, ai fine della manutenzione.`,
    ],
  },
  superficieGuardavia: {
    infoMessage: [
      `Superficie laterale totale del guardavia dell'impalcato utile, come stima, ai fine della manutenzione.`,
    ],
  },
  superficieSpartitraffico: {
    infoMessage: [
      `Superficie laterale totale dello spartitraffico dell'impalcato utile, come stima, ai fine della manutenzione.`,
    ],
  },
  collaborationType: {
    infoMessage: [
      "Se inviti un collaboratore interno quest'ultimo sarà legato alla tua sottoscrizione e i ponti da lui creati andranno a consumare il numero di opere della tua sottoscrizione. Se inviti un collaboratore esterno questo si dovrà iscrivere in autonomia ad INBEE e potrà lavorare sui ponti che condividi con lui.",
    ],
  },
  accessExpirationSharing: {
    infoMessage: [
      "Se non imposti la data di scadenza dell'accesso l'utente invitato potrà accedere al ponte solo fino a 60 giorni dalla data di prima esportazione.",
    ],
  },
};
