// L'ordinamento di questo array definisce l'attuale rappresentazione
// dell'albero per l'esportazione personalizzata  (Personalizzata - campi selezionabili (.csv))

// Questa rappresentazione è necessaria al fine che i campi selezionati nell'albero non
// vengano rimandati al BE in ordine di selezione, ma mantenendo sempre l'ordine originario.

export const sortedTreeValues = [
  // CONTESTO
  // CONTESTO > Informazioni generali
  ['2b'], // -------- condice interno proprietario
  ['3'], // --------- codice IOP
  ['4'], // --------- tipologia collegamento
  ['5'], // --------- nome opera
  ['6'], // --------- strada di appartenenza (Nome)
  ['7'], // --------- progressiva km iniziale
  ['8'], // --------- progressiva km finale
  ['9'], // --------- estesa (km)
  ['10'], // -------- tipologia di ente scavalcato
  ['11'], // -------- rilevanza ente scavalcato
  ['12'], // -------- nome ente scavalcato
  ['13'], // -------- classe d'uso
  ['14'], // -------- classe di conseguenza
  ['16'], // -------- stato dell'opera
  ['17'], // -------- livello di conoscenza
  ['tag'], // ------- tag

  // CONTESTO > Dati amministrativi
  // CONTESTO > Dati amministrativi > Dati generali
  ['21'], // -------- proprietario
  ['22'], // -------- concessionario
  ['23'], // -------- ente vigilante
  ['24'], // -------- autorità distrettuale
  ['25'], // -------- bacino idrografico

  // CONTESTO > Dati amministrativi > Provvedimenti di tutela
  ['28'], // -------- tutela ai sensi del D.lgs. 42/2004
  ['29'], // -------- provvedimenti di tutela
  ['30'], // -------- autore della progettazione
  ['31'], // -------- inserimento nei Piani Paesaggistici vigenti/adottati

  // CONTESTO > Localizzazione
  // CONTESTO > Localizzazione > Dati generali
  ['32'], // -------- regione
  ['33'], // -------- provincia
  ['34'], // -------- comune
  ['35'], // -------- località
  ['35b'], // ------- cap
  ['35c'], // ------- indirizzo
  ['36'], // -------- strada di appartenenza (Sigla)
  ['38', '39'], // -- sismicità dell'area [ag/g]
  ['40'], // -------- classificazione sismica
  ['41'], // -------- coordinate CTR
  ['42'], // -------- scala
  ['43'], // -------- numero toponimo
  ['44'], // -------- sistema di riferimento
  ['45'], // -------- ellissoide di riferimento

  // CONTESTO > Localizzazione > Coordinate geografiche (centro)
  ['46a'], // ------- quota s.l.m [m] (centro)
  ['46b'], // ------- latitudine (centro)
  ['46c'], // ------- longitudine (centro)

  // CONTESTO > Localizzazione > Coordinate geografiche (iniziale)
  ['47a'], // ------- quota s.l.m [m] (iniziale)
  ['47b'], // ------- latitudine (iniziale)
  ['47c'], // ------- longitudine (iniziale)

  // CONTESTO > Localizzazione > Coordinate geografiche (finale)
  ['48a'], // ------- quota s.l.m [m] (finale)
  ['48b'], // ------- latitudine (finale)
  ['48c'], // ------- longitudine (finale)

  // CONTESTO > Informazioni di ispezione
  // CONTESTO > Informazioni di ispezione > Rischio strutturale e sismico
  ['49'], // -------- rilevatore rischio strutturale e sismico
  ['50'], // -------- data ispezione rischio strutturale e sismico
  ['51'], // -------- possibilità di accedere al di sotto del ponte
  ['52'], // -------- n° campate ispezionate
  ['53'], // -------- prospetti ispezionati

  // CONTESTO > Informazioni di ispezione > Rischio frane
  ['55'], // -------- affidabilità complessivo rischio frana
  ['56'], // -------- rilevatore rischio frane
  ['57'], // -------- data ispezione rischio frana

  // CONTESTO > Informazioni di ispezione > Rischio idraulico
  ['59'], // -------- affidabilità complessiva rischio idraulico
  ['60'], // -------- rilevatore rischio idraulico
  ['61'], // -------- data ispezione rischio idraulico
  ['61b'], // ------- possibilità verfica scalzamento fondazioni

  // CONTESTO > Riscio frane
  // CONTESTO > Riscio frane > Dati generali
  ['63'], // -------- rischio frana
  ['64'], // -------- tipologia di interferenza
  ['65'], // -------- necessità di ispezioni speciali (rischio frane)
  ['66'], // -------- risultati ispezioni speciali
  ['68'], // -------- pericolosità PAI/PSAI frane
  ['71'], // -------- misure di mitigazione
  ['73'], // -------- estensione di interferenza

  // CONTESTO > Riscio frane > Tipologia fenomeno
  ['75'], // -------- fenomeno
  ['76'], // -------- tipologia di fenomeno
  ['77'], // -------- distrubuzione di attività (se definibile dai dati in possesso)
  ['79'], // -------- posizione instabilità sul versante
  ['78'], // -------- uso del suolo dell'area potenzialmente coinvolta

  // CONTESTO > Riscio frane > Contesto geologico
  ['80'], // -------- formazioni
  ['81'], // -------- elemento livello 0 m di riferimento - rischio frane
  ['82'], // -------- quota orlo superiore zona distacco [m]
  ['83'], // -------- quota ponte o viadotto [m]
  ['84'], // -------- profondità superficie di distacco [m]
  ['85'], // -------- area totale [m2]
  ['86'], // -------- volumen massa [m3]

  // CONTESTO > Riscio frane > Parametri frana
  ['89'], // -------- parametro dello stato di attività
  ['90'], // -------- frane attive
  ['91'], // -------- parametro del grado di criticità
  ['92'], // -------- parametro della massima velocità potenziale di spostamento
  ['93'], // -------- parametro della magnitudo su base volumetrica [m3]

  // CONTESTO > Rischio idraulico
  // CONTESTO > Rischio idraulico > Dati generali
  ['96'], // -------- rischio idraulico
  ['97'], // -------- necessità di ispezioni speciali (rischio idraulico)
  ['98'], // -------- risultati ispezioni speciali
  ['100'], // ------- pericolosità PAI/PSAI/PGRA - idraulico
  ['108'], // ------- parti della struttura che interessano l'alveo
  ['105'], // ------- fenomeno di sormonto o insufficienza di franco
  ['104'], // ------- fenomeni di erosione localizzata e generalizzata
  ['102'], // ------- presenza di studio idraulico con livelli idrici definiti
  ['111'], // ------- scenari di pericolità noti
  ['106'], // ------- area riconosciuta pericolosa
  ['112'], // -------  presenza rilievi sezioni fluviali e struttura
  ['114', '115'], // - superficile bacino idrografico S
  ['109'], // ------- tipologia reticolo
  ['110'], // ------- ricandente in area mappata ai sensi della direttiva alluvioni per ...

  // CONTESTO > Rischio idraulico > Caratteristiche alveo
  ['118'], // -------- confinamento alveo
  ['119'], // -------- morfologia alveo
  ['120'], // -------- sinuosità
  ['122'], // -------- tipologia fondo alveo
  ['123'], // -------- natura materiale alveo
  ['125'], // -------- sensibile accumulo di detriti trasportati dalla corrente
  ['121'], // -------- evoluzione alveo
  ['116'], // -------- alveo avente sensibile curvatura in corrispondenza del ponte
  ['126'], // -------- tendenza dell'alveo a divagazione planimetrica
  ['128'], // -------- tipologia corso d'acqua

  // CONTESTO > Rischio idraulico > Sormonto o insufficienza di franco
  ['133'], // -------- elemento livello 0m di riferimento
  ['134'], // -------- quota minima intradosso del ponte [m]
  ['138'], // -------- quota del pelo libero (scenario P2) [m]
  ['141'], // -------- franco idraulico FP2[m]
  ['139'], // -------- quota del pelo libero (scenario P3) [m]
  ['142'], // -------- franco idraulico FP3[m]
  ['135'], // -------- quota margine (min) (Caso I) [m]
  ['136'], // -------- quota sponda (max) (Caso II) [m]
  ['137'], // -------- quota fondo alveo (Caso III) [m]
  ['140'], // -------- quota pelo libero stimata [m]
  ['143'], // -------- franco idraulico stimato [m]
  ['144'], // -------- franco > 2 m per evidenze di campo
  ['145'], // -------- evidente e accertata deposizione di sedimenti
  ['147'], // -------- trasporto di materiale vegetale di notevole dimensione

  // CONTESTO > Rischio idraulico > Erosione
  ['150'], // -------- Wal [m]
  ['151'], // -------- Wa [m]
  ['152'], // -------- fattore di restringimento alveo inciso Ca [%]
  ['149'], // -------- aree golenali assenti
  ['153'], // -------- Wgl [m]
  ['154'], // -------- Wg  [m]
  ['155'], // -------- fattore di restringimento delle aree golenali Cg [%]
  ['155a'], // -------- geometria sez. trasversale pile
  ['155b'], // -------- dimensione sez. trasversale pile
  ['155c'], // -------- stato conservazione pile
  ['155d'], // -------- geometria sez. traversale spalle
  ['155e'], // -------- dimensione sez. trasversale spalle
  ['155f'], // -------- stato conservazione spalle
  ['157'], // -------- larghezza pila o aggetto spalla a [m]
  ['158'], // -------- massima prodondità di scavo ds [m]
  ['159'], // -------- profondità fondazione rispetto all'alveo df [m]
  ['160'], // -------- fattore IEL
  ['164'], // -------- evidenze sulla profondità delle fondazioni
  ['156'], // -------- evoluzione eventuale del fondo alveo rispetto alle pile
  ['161'], // -------- abbassamento generalizzato dell'alveo
  ['163'], // -------- abbassamento molto accentuato anche in relazione alle fondazioni
  ['166'], // -------- accumuli di detriti o materiale flottante a monte della pila

  // CONTESTO > Rischio idraulico > Opere di protezione
  ['168'], // -------- opere di protezione spondale
  ['169'], // -------- stato di conservazione e adeguatezza opera spondale
  ['171'], // -------- protezione al piede delle pile e delle splalle del ponte
  ['172'], // -------- stato di conservazione e edeguatezza protezione al piede
  ['174'], // -------- briglia di protezione immediatamente a valle del ponte
  ['175'], // -------- stato di conservazione e adeguatezza birglia

  // CONTESTO > Rischio idraulico > Opere di mitigazione e monitoraggio
  ['177'], // -------- opere di arginatura, di alterazion delle portate liquide e solide
  ['179'], // -------- stato di conservazione e adeguatezza opere di arginatura e alterazione
  ['178'], // -------- tipologia opere di arginatura e alterazione
  ['186'], // -------- tipologia aree di possibile allegamento
  ['180'], // -------- misure/opere di laminazione e mitigazione
  ['182'], // -------- stato di conservazione e adeguatezza opere di laminazione
  ['181'], // -------- tipologia opere di laminazione
  ['183'], // -------- sistemi di monitoraggio
  ['184'], // -------- tipologia sistemi di monitoraggio
  ['185'], // -------- stato di conservazione sistemi di monitoraggio

  // CONTESTO > Dati di progetto
  // CONTESTO > Dati di progetto > Dati generali
  ['189'], // -------- progettista
  ['191', '192'], // - anno di progettagione
  ['194'], // -------- norma di progetto
  ['193'], // -------- classe di progettazione
  ['196', '197'], // - anno di costruzione
  ['199'], // -------- anno di entrata in esercizio
  ['205'], // -------- ente approvatore
  ['200'], // -------- interventi significativi di manutenzione
  ['202', '203'], // - anno interventi significativi di manutenzione
  ['204'], // -------- criteri antisismici in opera

  // CONTESTO > Dati di progetto > Documentazione iniziale
  ['206'], // -------- strumenti del finanziamento
  ['207'], // -------- piani e strumento di programmazione

  // CONTESTO > Dati di progetto > Progetto preliminare/di massima
  ['208'], // -------- documenti descrittivi progetto preliminare/di massima
  ['209'], // -------- documenti grafici progetto preliminare/di massima
  ['210'], // -------- documenti inerenti l'iter di approvazione progetto preliminare/di massima

  // CONTESTO > Dati di progetto > Progetto definitivo/esecutivo
  ['211'], // -------- documenti descrittivi progetto definitivo/esecutivo
  ['212'], // -------- documenti grafici progetto definitivo/esecutivo
  ['213'], // -------- documenti inerenti l'iter di approvazione progetto definitivo/esecutivo

  // CONTESTO > Dati di progetto > Progetto esecutivo/cantierabile
  ['214'], // -------- documenti descrittivi progetto esecutivo/cantierabile
  ['215'], // -------- documenti grafici progetto esecutivo/cantierabile
  ['216'], // -------- documenti inerenti l'iter di approvazione progetto esecutivo/cantierabile

  // CONTESTO > Dati di progetto > Direzione lavori
  ['217'], // -------- documenti contabile direzione lavori
  ['218'], // -------- grafici allegati alla contabilità dell'appalto Direzione lavori

  // CONTESTO > Dati di progetto > Realizzazione
  ['219'], // -------- documenti contrattuali realizzazione
  ['220'], // -------- documenti contabili realizzazione

  // CONTESTO > Dati di progetto > Varianti in corso d'opera
  ['221'], // -------- documenti descrittivi Varianti in corso d'opera
  ['222'], // -------- documenti grafici Varianti in corso d'opera
  ['223'], // -------- documenti contrattuali e contabili Varianti in corso d'opera

  // CONTESTO > Dati di progetto > Collaudo
  ['224'], // -------- relazione di collaudo
  ['225'], // -------- allegati alla relazione di collaudo

  // CONTESTO > Attività pregresse
  ['226a'], // -------- verifica statica
  ['226c'], // -------- verifica sismica
  ['226'], // --------- uspezioni pregresse
  ['228'], // --------- segnalazioni
  ['230'], // --------- interventi eseguiti
  ['232'], // --------- attività di monitoraggio pregresse o in corso
  ['234'], // --------- interventi di manutenzione

  // CONTESTO > Collegamento e rete stradale
  ['236'], // --------- classificazione d'uso stradale
  ['236b'], // --------- competenza infrastruttura
  ['237'], // --------- categoria ponte
  ['238'], // --------- itinerario internazionale
  ['239'], // --------- rete TEN
  ['240'], // --------- opera strategica
  ['241'], // --------- trasporti eccezionali
  ['243'], // --------- disponibilità di studi trasportistici specifici
  ['245'], // --------- disponibilità di studi sugli effetti dei carichi da traffico
  ['248'], // --------- n° carreggiate
  ['249'], // --------- n° corsie/carreggiata
  ['250'], // --------- larghezza carreggiata [m]
  ['251'], // --------- presenza di curve
  ['253', '254'], // -- TGM [veicoli/giorno] (carreggiata)
  ['256', '257'], // -- TGM commerciali [veicoli/giorno] (carreggiata)
  ['259', '260'], // -- TGM commerciali [veicoli/giorno] (singola corsia)
  ['261'], // --------- limitazioni di carico
  ['261b'], // --------- valore limitazione (t)
  ['266'], // --------- frequente passaggio di persone
  ['267'], // --------- trasporto merci pericolose
  ['262'], // --------- alternative stradali
  ['263'], // --------- durata deviazione [km]
  ['264'], // --------- durata deviazione [min]
  ['265'], // --------- categoria del percorso alternativo individuato

  // CONTESTO > Dati geomorfici ed esposizione
  ['268'], // --------- unità fisiografica
  ['269'], // --------- morfologia/categoria topografica
  ['270'], // --------- categoria di sottosuolo
  ['270b'], // --------- categoria di sottosuolo presunta
  ['271'], // --------- aerosol marini e sali antigelo
  ['272'], // --------- tali da determinare una maggiore rapidità del degrado
  ['273'], // --------- stralcio cartografico
  ['274'], // --------- satellitare/foto aeree storiche
  ['275'], // --------- satellitare/foto aeree attuale
  ['276'], // --------- riprese fotografiche

  // STRUTTURA
  // STRUTTURA > Caratteristiche geometriche
  ['277'], // --------- luce complessiva (estesa) [m]
  ['278'], // --------- luce complessiva (spalla-spalla) [m]
  ['279'], // --------- tracciato
  ['280'], // --------- altezza utile sotto impalcato [m]
  ['281'], // --------- larghezza totale impalcato [m]
  ['282'], // --------- n° campate
  ['283'], // --------- luce media campate [m]
  ['284'], // --------- luce massima campate [m]

  // STRUTTURA > Caratteristiche strutturali
  // STRUTTURA > Caratteristiche strutturali > Dati generali
  ['317'], // --------- tipologia strutturale
  ['318'], // --------- necessità di ispezioni speciali (rischio struttura)
  ['319'], // --------- risultati ispezioni speciali (rischio struttura)
  ['321'], // --------- tipologia schema
  ['322'], // --------- tipologia impalcato
  ['324'], // --------- n° spalle
  ['323'], // --------- n° pile
  ['325'], // --------- mensole
  ['326'], // --------- n° mensole
  ['327'], // --------- n° travi tampone

  // STRUTTURA > Elementi strutturali
  ['328b'], // --------- appoggi (n°)
  ['328c'], // --------- giunti (n°)
  ['328d'], // --------- pulvini (n°)
  ['328e'], // --------- antenne (n°)
  ['328f'], // --------- altri dispositivi antisismici (n°)
  ['328i'], // --------- travi (n°)
  ['328l'], // --------- traversi (n°)
  ['328m'], // --------- controvento (n°)
  ['328n'], // --------- solette (n°)
  ['328o'], // --------- archi (n°)
  ['328p'], // --------- piedritti (n°)
  ['328q'], // --------- tiranti (n°)
  ['328r'], // --------- elementi extra (n°)

  // STRUTTURA > Schemi geometrici
  ['329'], // --------- sezione trasversale
  ['330'], // --------- sezione longitudinale
  ['331'], // --------- pianta

  // STRUTTURA > Elevazione
  // STRUTTURA > Elevazione > Spalle-S
  ['335'], // --------- tipologia spalle
  ['342'], // --------- classificazione delle vie di attacco
  ['343'], // --------- imposta fondazione spalle

  // STRUTTURA > Elevazione > Pile-S
  ['348'], // --------- tipologia pila
  ['349'], // --------- tipologia sezione
  ['350'], // --------- geometria sezione
  ['351'], // --------- pila in alveo
  ['354'], // --------- altezza massima (HM) [m]
  ['359'], // --------- n° fondazioni pila
  ['360'], // --------- imposta fondazioni pila

  // STRUTTURA > Collegamenti
  // STRUTTURA > Collegamenti > Appoggi-AP
  ['365'], // --------- tipologia appoggio
  ['366'], // --------- appoggi antisismici

  // STRUTTURA > Collegamenti > Giunti-G
  ['370'], // --------- tipologia giunto
  ['371'], // --------- larghezza (L) [m]

  // STRUTTURA > Collegamenti > Pulvini-PU
  ['376'], // --------- tipologia pulvino

  // STRUTTURA > Collegamenti > Antenne-AN
  ['385'], // --------- tipologia antenna

  // STRUTTURA > Impalcato
  // STRUTTURA > Impalcato > Soletta-SO
  ['396'], // --------- tipologia soletta
  ['398'], // --------- lunghezza sbalzo soletta (ls) [m]

  // STRUTTURA > Impalcato > Travi-TP
  ['402'], // --------- tipologia travi

  // STRUTTURA > Impalcato > Traversi-TS
  ['411'], // --------- tipologia traversi

  // STRUTTURA > Impalcato > Controventi-CV
  ['419'], // --------- tipologia controventi

  // STRUTTURA > Impalcato > Archi-A
  ['429'], // --------- tipologia archi

  // STRUTTURA > Impalcato > Piedritti-PD
  ['439'], // --------- tipologia piedritti

  // STRUTTURA > Impalcato > Tiranti-TN
  ['447'], // --------- tipologia tiranti

  // STRUTTURA > Impalcato > Elementi extra - EX
  ['457'], // --------- tipologia elementi extra

  // STRUTTURA > Aspetti critici
  ['maint10'], // ------ aspetti critici struttura

  // STRUTTURA > Aspetti critici > Elementi di vulnerabilità sismica (vedi 4.3.3 delle linee guida)
  ['480'], // --------- elementi di vulnerabilità sismica

  // STRUTTURA > Aspetti critici > Elementi critici - Vulnerabilità strutturale e fondazionale (vedi 4.3.3 delle linee guida)
  ['464'], // --------- elementi critici - vulnerabilità strutturale e fondazionale
  ['466'], // --------- tipologia elemento

  // STRUTTURA > Aspetti critici > Elementi critici - Vulnerabilità sismica (vedi 4.3.3 delle linee guida)
  ['469'], // --------- elementi critici - vulnerabilità sismica
  ['471'], // --------- tipologia elemento

  // STRUTTURA > Aspetti critici > Condizioni critiche - Vulnerabilità strutturale e fondazionale (vedi 4.3.3 delle linee guida)
  ['474'], // --------- condizioni critiche - vulnerabilità strutturale e fondazionale
  ['475'], // --------- tipologia

  // STRUTTURA > Aspetti critici > Condizioni critiche - Vulnerabilità sismica (vedi 4.3.3 delle linee guida)
  ['477'], // --------- condizioni critiche - vulnerabilità sismica
  ['478'], // --------- tipologia

  // ELEMENTI ACCESSORI
  ['499b+c'], // ------- cordoli
  ['maint1'], // ------- interventi su cordoli
  ['499d+e'], // ------- marciapiedi
  ['maint2'], // ------- interventi su marciapiedi
  ['499f+g'], // ------- parapetti
  ['maint3'], // ------- interventi su parapetti
  ['499h+i'], // ------- guardavia
  ['maint4'], // ------- interventi su guardavie
  ['499l'], // --------- spartitraffico
  ['maint6'], // ------- interventi su spartitraffico
  ['499m'], // --------- pavimentazione
  ['maint5'], // ------- interventi su pavimentazione
  ['i1'], // ----------- interventi su elementi accessori

  // SERVIZI
  ['550'], // --------- pali di illuminazione
  ['maint7'], // ------ interventi su pali di illuminazione
  ['556'], // --------- convogliamento acque
  ['maint8'], // ------ interventi su convolgliamento acque
  ['560'], // --------- sottoservizi
  ['maint9'], // ------ interventi su sottoservizi
  ['i2'], // ---------- interventi su Servizi

  // LIVELLO 2
  // LIVELLO 2 > Rischio complessivo
  // ['cda-1'], // ------- data prossima ispezione
  ['575'], // ------- data prossima ispezione ordinaria
  ['575b'], // ------- tipologia data prossima ispezione ordinaria
  ['576'], // ------- data prossima ispezione straordinaria
  ['576b'], // ------- tipologia data prossima ispezione straordinaria
  ['cda-2'], // ------- cda complessiva

  // LIVELLO 2 > Rischio strutt. fond.
  ['cda-3'], // ------- G5
  ['cda-4'], // ------- PS
  ['cda-5'], // ------- NR
  ['cda-6'], // ------- livello difettosità strutt. fond.
  ['cda-7'], // ------- vulnerabilità strutt. fond.
  ['cda-8'], // ------- percolosità strutt. fond.
  ['cda-9'], // ------- esposizione strutt. fond.
  ['cda-10'], // ------ cda strutt. fond

  // LIVELLO 2 > Rischio sismico
  ['cda-11'], // ------ livello difettosità sismica
  ['cda-12'], // ------ vulnerabilità sismica
  ['cda-13'], // ------ pericolosità sismica
  ['cda-14'], // ------ esposizione sismica
  ['cda-15'], // ------ cda sismica

  // LIVELLO 2 > Rischio frane
  ['cda-16'], // ------ vulnerabilità frane
  ['cda-17'], // ------ suscettibilità frane
  ['cda-18'], // ------ esposizione frane
  ['cda-19'], // ------ cda frane

  // LIVELLO 2 > Rischio idraulico
  // LIVELLO 2 > Rischio idraulico > Sormonto
  ['cda-20'], // ------ vulnerabiiità sormonto
  ['cda-21'], // ------ pericolosità sormonto
  ['cda-22'], // ------ esposizione sormonto
  ['cda-23'], // ------ cda sormonto

  // LIVELLO 2 > Rischio idraulico > Erosione generalizzata
  ['cda-24'], // ------ vulnerabilità erosione generalizzata
  ['cda-25'], // ------ pericolosità erosione generalizzata
  ['cda-26'], // ------ esposizione erosione generalizzata
  ['cda-27'], // ------ cda erosione generalizzata

  // LIVELLO 2 > Rischio idraulico > Erosione localizzata
  ['cda-28'], // ------ vulnerabilità erosione localizzata
  ['cda-29'], // ------ pericolosità erosione localizzata
  ['cda-30'], // ------ esposizione erosione localizzata
  ['cda-31'], // ------ cda erosione localizzata

  // LIVELLO 2 > Rischio idraulico
  ['cda-32'], // ------ cda idraulica
];
