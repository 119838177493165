import {
  EffettivoPresunto,
  RilevabileNonRilevabile,
  YesNo,
} from 'constants/inspections';
import {
  convertfromInttoArray,
  patchAffidabilitaComplessivaValutazioneRischioIdraulicoValue,
  refreshScheda20,
} from './mappingMiddleware';

export const mappingInAll = result => {
  const diagnosis = JSON.parse(result.diagnosis);
  const data = {
    // START Common
    codiceIOP: diagnosis?.common?.codiceIOP ?? undefined,
    nomeOpera: diagnosis?.common?.nomeOpera ?? undefined,
    stradaAppartenenza: diagnosis?.common?.stradaAppartenenza ?? undefined,
    progressivaKmIniziale:
      diagnosis?.common?.progressivaKmIniziale ?? undefined,
    progressiveKmFinale: diagnosis?.common?.progressiveKmFinale ?? undefined,
    nomeEnteScavalcato: diagnosis?.common?.nomeEnteScavalcato ?? undefined,
    caratteristicheClassificazioneEnteScavalcato:
      diagnosis?.common?.caratteristicheClassificazioneEnteScavalcato ??
      undefined,
    classeUso: diagnosis?.common?.classeUso ?? undefined,
    statoOpera: diagnosis?.common?.statoOpera ?? undefined,
    luceComplessivaEstesa:
      diagnosis?.common?.luceComplessivaEstesa ?? undefined,
    larghezzaTotaleImpalcato:
      diagnosis?.common?.larghezzaTotaleImpalcato ?? undefined,
    numeroCampate: diagnosis?.common?.numeroCampate ?? undefined,
    luceMediaCampate: diagnosis?.common?.luceMediaCampate ?? undefined,
    luceMassimaCampate: diagnosis?.common?.luceMassimaCampate ?? undefined,
    rischioFrana: diagnosis?.common?.rischioFrana ?? undefined,
    rischioIdraulico: diagnosis?.common?.rischioIdraulico ?? undefined,
    rischioPresente: diagnosis?.common?.rischioPresente ?? undefined,
    presenzaStudioIdraulicoLivelliDefiniti:
      diagnosis?.common?.presenzaStudioIdraulicoLivelliDefiniti ?? undefined,
    dataApprossimativaAnnoDiProgettazione:
      diagnosis?.common?.dataApprossimativaAnnoDiProgettazione ?? undefined,
    annoDiCostruzioneApprossimativo:
      diagnosis?.common?.annoDiCostruzioneApprossimativo ?? undefined,
    criteriSismiciInOpera:
      diagnosis?.common?.criteriSismiciInOpera ?? undefined,
    categoriaPonte: diagnosis?.common?.categoriaPonte ?? undefined,
    disponibilitaStudiEffettiCarichiTraffico:
      diagnosis?.common?.disponibilitaStudiEffettiCarichiTraffico ?? undefined,
    valutazioneEffettiCarichiTraffico:
      diagnosis?.common?.valutazioneEffettiCarichiTraffico ?? undefined,
    valoreApprossimativoTGM:
      diagnosis?.common?.valoreApprossimativoTGM ?? undefined,
    valoreApprossimativoCommercialiTGM:
      diagnosis?.common?.valoreApprossimativoCommercialiTGM ?? undefined,
    valoreApprossimativoCommercialiCorsiaTGM:
      diagnosis?.common?.valoreApprossimativoCommercialiCorsiaTGM ?? undefined,
    limiteCaricoCarreggiataSingolaCorsia:
      diagnosis?.common?.limiteCaricoCarreggiataSingolaCorsia ?? undefined,
    alternativeStradali: diagnosis?.common?.alternativeStradali ?? undefined,
    frequentePassaggioPersone:
      diagnosis?.common?.frequentePassaggioPersone ?? undefined,
    trasportoMerciPericolose:
      diagnosis?.common?.trasportoMerciPericolose ?? undefined,
    categoriaTopografica: diagnosis?.common?.categoriaTopografica ?? undefined,
    categoriaSottosuolo: diagnosis?.common?.categoriaSottosuolo ?? undefined,
    sismicitaValoreApprossimativo:
      diagnosis?.common?.sismicitaValoreApprossimativo ?? undefined,
    tipologiaStrutturale: diagnosis?.common?.tipologiaStrutturale ?? undefined,
    tipologiaStrutturaleSpecificareAltro:
      diagnosis?.common?.tipologiaStrutturaleSpecificareAltro ?? undefined,
    tipologiaSchema: diagnosis?.common?.tipologiaSchema ?? undefined,
    tipologiaImpalcato: diagnosis?.common?.tipologiaImpalcato ?? undefined,
    nPileStruttura: diagnosis?.common?.nPileStruttura ?? undefined,
    nSpalleStruttura: diagnosis?.common?.nSpalleStruttura ?? undefined,
    mensole: diagnosis?.common?.mensole ?? undefined,
    nMensoleStruttura: diagnosis?.common?.nMensoleStruttura ?? undefined,
    nTamponiStruttura: diagnosis?.common?.nTamponiStruttura ?? undefined,
    spalleTable: diagnosis?.common?.spalleTable || [],
    pileTable: diagnosis?.common?.pileTable || [],
    paliTable: diagnosis?.common?.paliTable || [],
    spallePileTable: diagnosis?.common?.spallePileTable || [],
    campataStrutturaTable: diagnosis?.common?.campataStrutturaTable || [],
    mensoleTamponiTable: diagnosis?.common?.mensoleTamponiTable || [],
    spallePileCollegamentiTable:
      diagnosis?.common?.spallePileCollegamentiTable || [],
    convogliamentoAcqueTable: diagnosis?.common?.convogliamentoAcqueTable || [],
    sottoserviziTable: diagnosis?.common?.sottoserviziTable || [],
    mensoleTamponiCampateImpalcatoTable:
      diagnosis?.common?.mensoleTamponiCampateImpalcatoTable || [],

    catagoriaPonteEffettiva:
      diagnosis?.common?.categoriaPonteAltro === YesNo.SI ? true : false,
    categoriaPontePresunta:
      diagnosis?.common?.categoriaPonteAltro === YesNo.NO ? true : false,
    categoriaPonteAltro: diagnosis?.common?.categoriaPonteAltro ?? undefined,

    interventiSignificativiDataApprossimativa:
      diagnosis?.common?.interventiSignificativiDataApprossimativa ?? undefined,

    affidabilitaComplessivaRischioFrane:
      diagnosis?.common?.affidabilitaComplessivaRischioFrane ?? undefined,
    schemaPonteTable: diagnosis?.common?.schemaPonteTable || [],
    sovrastrutturaImpalcatoTable: diagnosis?.common
      ?.sovrastrutturaImpalcatoTable
      ? refreshScheda20(diagnosis?.common?.sovrastrutturaImpalcatoTable)
      : [],
    misureMitigazione: diagnosis?.common?.misureMitigazione ?? undefined,
    estensioneInterferenzaFrana:
      diagnosis?.common?.estensioneInterferenzaFrana ?? undefined,
    estensioneInterferenzaFranaAltro:
      diagnosis?.common?.estensioneInterferenzaFranaAltro ?? undefined,
    statoAttivitaFrana: diagnosis?.common?.statoAttivitaFrana ?? undefined,
    gradoCriticitaFrana: diagnosis?.common?.gradoCriticitaFrana ?? undefined,
    velocitaMaxSpostamentoFrana:
      diagnosis?.common?.velocitaMaxSpostamentoFrana ?? undefined,
    /**
     * START Dati geomorfologici L0 - Effettivi Presunti
     */
    categoriaSottosuoloAltro:
      diagnosis?.common?.categoriaSottosuoloAltro ?? undefined,
    catagoriaSottosuoloEffettiva: diagnosis?.common?.categoriaSottosuoloAltro
      ? diagnosis?.common?.categoriaSottosuoloAltro ===
        EffettivoPresunto.Effettivo
      : undefined,
    categoriaSottosuoloPresunta: diagnosis?.common?.categoriaSottosuoloAltro
      ? diagnosis?.common?.categoriaSottosuoloAltro ===
        EffettivoPresunto.Presunto
      : undefined,

    /**
     * END Dati geomorfologici L0 - Effettivi Presunti
     */
    /**
     * START Dati Di Progetto L0 - Effettivi Presunti
     */
    criteriSismiciInOperaAltro:
      diagnosis?.common?.criteriSismiciInOperaAltro ?? undefined,
    criteriSismiciEffettivo: diagnosis?.common?.criteriSismiciInOperaAltro
      ? diagnosis?.common?.criteriSismiciInOperaAltro ===
        EffettivoPresunto.Effettivo
      : undefined,
    criteriSismiciPresunto: diagnosis?.common?.criteriSismiciInOperaAltro
      ? diagnosis?.common?.criteriSismiciInOperaAltro ===
        EffettivoPresunto.Presunto
      : undefined,
    /**
     * END Dati di progetto L0 - Effettivi Presunti
     */
    // START Rischio Frane L0 L1 - Effettivi e Presunti
    velocitaMaxSpostamentoFranaFlag:
      diagnosis?.common?.velocitaMaxSpostamentoFranaFlag ?? undefined,
    velocitaMaxSpostamentoFranaEffettivo: diagnosis?.common
      ?.velocitaMaxSpostamentoFranaFlag
      ? diagnosis?.common?.velocitaMaxSpostamentoFranaFlag ===
        EffettivoPresunto.Effettivo
      : undefined,
    velocitaMaxSpostamentoFranaPresunto: diagnosis?.common
      ?.velocitaMaxSpostamentoFranaFlag
      ? diagnosis?.common?.velocitaMaxSpostamentoFranaFlag ===
        EffettivoPresunto.Presunto
      : undefined,
    magnitudoBaseVolumetricaFlag:
      diagnosis?.common?.magnitudoBaseVolumetricaFlag ?? undefined,
    magnitudoBaseVolumetricaEffettivo: diagnosis?.common
      ?.magnitudoBaseVolumetricaFlag
      ? diagnosis?.common?.magnitudoBaseVolumetricaFlag ===
        EffettivoPresunto.Effettivo
      : undefined,
    magnitudoBaseVolumetricaPresunto: diagnosis?.common
      ?.magnitudoBaseVolumetricaFlag
      ? diagnosis?.common?.magnitudoBaseVolumetricaFlag ===
        EffettivoPresunto.Presunto
      : undefined,
    // END Rischio Frane L0 L1 - Effettivi e Presunti
    magnitudoBaseVolumetrica:
      diagnosis?.common?.magnitudoBaseVolumetrica ?? undefined,
    campataTable: diagnosis?.common?.campataTable || [],
    bacinoIdrograficoPresuntoRischioIdraulico:
      diagnosis?.common?.bacinoIdrograficoPresuntoRischioIdraulico ?? undefined,
    curvaturaAlveoRischioIdraulico:
      diagnosis?.common?.curvaturaAlveoRischioIdraulico ?? undefined,
    divagazioneAlveoRischioIdraulico:
      diagnosis?.common?.divagazioneAlveoRischioIdraulico ?? undefined,
    /*  francoIdraulicoFP2: diagnosis?.common?.francoIdraulicoFP2 ?? undefined, */
    francoIdraulicoFP2:
      diagnosis?.common?.francoIdraulicoFP2 === 0
        ? '0'
        : diagnosis?.common?.francoIdraulicoFP2 ?? undefined,
    /* francoIdraulicoFP3: diagnosis?.common?.francoIdraulicoFP3 ?? undefined, */
    francoIdraulicoFP3:
      diagnosis?.common?.francoIdraulicoFP3 === 0
        ? '0'
        : diagnosis?.common?.francoIdraulicoFP3 ?? undefined,
    francoIdraulicoStimato:
      diagnosis?.common?.francoIdraulicoStimato === 0
        ? '0'
        : diagnosis?.common?.francoIdraulicoStimato ?? undefined,
    francoIdraulicoSuperiore:
      diagnosis?.common?.francoIdraulicoSuperiore ?? undefined,
    deposizioneSedimenti: diagnosis?.common?.deposizioneSedimenti ?? undefined,
    materialeVegetaleRischioIdraulico:
      diagnosis?.common?.materialeVegetaleRischioIdraulico ?? undefined,
    fattoreRestringimentoAlveo:
      diagnosis?.common?.fattoreRestringimentoAlveo === 0
        ? '0'
        : diagnosis?.common?.fattoreRestringimentoAlveo ?? undefined,
    fattoreRestringimentoAreeGolenali:
      diagnosis?.common?.fattoreRestringimentoAreeGolenali === 0
        ? '0'
        : diagnosis?.common?.fattoreRestringimentoAreeGolenali ?? undefined,
    fattoreIEL:
      diagnosis?.common?.fattoreIEL === 0
        ? '0'
        : diagnosis?.common?.fattoreIEL ?? undefined,
    evidenzeProfonditaDelleFondazioni:
      diagnosis?.common?.evidenzeProfonditaDelleFondazioni ?? undefined,
    abbassamentoGeneralizzatoAlveo:
      diagnosis?.common?.abbassamentoGeneralizzatoAlveo ?? undefined,
    accumuliDetritiAMontePila:
      diagnosis?.common?.accumuliDetritiAMontePila ?? undefined,
    protezioneAlPiedePileSpalle:
      diagnosis?.common?.protezioneAlPiedePileSpalle ?? undefined,
    brigliaProtezioneValleDelPonte:
      diagnosis?.common?.brigliaProtezioneValleDelPonte ?? undefined,
    valoreLimitazione: diagnosis?.common?.valoreLimitazione ?? undefined,
    elementiCriticiVulnerabilitaStrutturaleFondazionale:
      diagnosis?.common?.elementiCriticiVulnerabilitaStrutturaleFondazionale ??
      undefined,
    elementiCriticiVulnerabilitaSismica:
      diagnosis?.common?.elementiCriticiVulnerabilitaSismica ?? undefined,
    condizioniCriticheVulnerabilitaStrutturale:
      diagnosis?.common?.condizioniCriticheVulnerabilitaStrutturale ??
      undefined,
    condizioniCriticheVulnerabilitaSismica:
      diagnosis?.common?.condizioniCriticheVulnerabilitaSismica ?? undefined,
    elementiVulnerabilitaSismica:
      diagnosis?.common?.elementiVulnerabilitaSismica ?? undefined,
    tipologiaImpalcatoSpecificareAltro:
      diagnosis?.common?.tipologiaImpalcatoSpecificareAltro ?? undefined,
    riepilogoStrutturaTable: diagnosis?.common?.riepilogoStrutturaTable || [],
    verificaStatica: diagnosis?.common?.verificaStatica ?? undefined,
    verificaSismica: diagnosis?.common?.verificaSismica ?? undefined,
    campateSoggetteACollasso:
      diagnosis?.common?.campateSoggetteACollasso ?? undefined,
    vulnerabilitaStrutturaleBassa:
      diagnosis?.common?.vulnerabilitaStrutturaleBassa ?? undefined,

    // fields for upload images
    rilevatoreRischioIdraulicoMedia:
      diagnosis?.L1?.rilevatoreRischioIdraulico ?? undefined,
    rilevatoreRischioFraneMedia:
      diagnosis?.L1?.rilevatoreRischioFrane ?? undefined,
    rilevatoreRischioStrutturaleMedia:
      diagnosis?.L1?.rilevatoreRischioStrutturale ?? undefined,
    dataIspezioneRischioStrutturaleMedia:
      diagnosis?.L1?.dataIspezioneRischioStrutturale ?? undefined,
    dataIspezioneRischioFraneMedia:
      diagnosis?.L1?.dataIspezioneRischioFrane ?? undefined,
    dataIspezioneRischioIdraulicoMedia:
      diagnosis?.L1?.dataIspezioneRischioIdraulico ?? undefined,
    // END Common

    // START L0
    codiceInterno: result.structure.internalCode ?? undefined,
    codiceInternoProprietario:
      diagnosis.L0?.codiceInternoProprietario ?? undefined,
    classeDiConseguenza: diagnosis?.L0?.classeDiConseguenza ?? undefined,
    tipologiaCollegamento: diagnosis?.L0?.tipologiaCollegamento ?? undefined,
    estesa: diagnosis?.L0?.estesa ?? undefined,
    tipologiaEnteScavalcato:
      diagnosis?.L0?.tipologiaEnteScavalcato ?? undefined,
    tipologiaEnteScavalcatoAltro:
      diagnosis?.L0?.tipologiaEnteScavalcatoAltro ?? undefined,
    spiegazioneConseguenza: diagnosis?.L0?.spiegazioneConseguenza ?? undefined,
    livelloConoscenza: diagnosis?.L0?.livelloConoscenza ?? undefined,
    luceComplessivaSpalla: diagnosis?.L0?.luceComplessivaSpalla ?? undefined,
    tracciato: diagnosis?.L0?.tracciato ?? undefined,
    altezzaUtileSottoImpalcato:
      diagnosis?.L0?.altezzaUtileSottoImpalcato ?? undefined,
    proprietario: diagnosis?.L0?.proprietario ?? undefined,
    concessionario: diagnosis?.L0?.concessionario ?? undefined,
    enteVigilante: diagnosis?.L0?.enteVigilante ?? undefined,
    autoritaDistrettuale: diagnosis?.L0?.autoritaDistrettuale ?? undefined,
    bacinoIdrografico: diagnosis?.L0?.bacinoIdrografico ?? undefined,
    tutelaDLgs: diagnosis?.L0?.tutelaDLgs ?? undefined,
    provvedimentiDiTutela: diagnosis?.L0?.provvedimentiDiTutela ?? undefined,
    autoreDellaProgettazione:
      diagnosis?.L0?.autoreDellaProgettazione ?? undefined,
    inserimentoNeiPianiPaesaggistici:
      diagnosis?.L0?.inserimentoNeiPianiPaesaggistici ?? undefined,
    necessitaIspezioniSpecialiRischioFrane:
      diagnosis?.L0?.necessitaIspezioniSpecialiRischioFrane ?? undefined,
    risultatiIspezioniSpecialiFrane:
      diagnosis?.L0?.risultatiIspezioniSpecialiFrane ?? undefined,
    pericolositaPAIPSAIFrane:
      diagnosis?.L0?.pericolositaPAIPSAIFrane ?? undefined,
    necessitaIspezioniSpecialiRischioIdraulico:
      diagnosis?.L0?.necessitaIspezioniSpecialiRischioIdraulico ?? undefined,
    risultatiIspezioniSpecialiRischioIdraulico:
      diagnosis?.L0?.risultatiIspezioniSpecialiRischioIdraulico ?? undefined,
    pericolositaPAIPSAIRischioIdraulico:
      diagnosis?.L0?.pericolositaPAIPSAIRischioIdraulico ?? undefined,
    progettista: diagnosis?.L0?.progettista ?? undefined,
    annoProgettazione: diagnosis?.L0?.annoProgettazione ?? undefined,
    dataEffettivaAnnoDiProgettazione:
      diagnosis?.L0?.dataEffettivaAnnoDiProgettazione ?? undefined,
    normaProgetto: diagnosis?.L0?.normaProgetto ?? undefined,
    annoCostruzione: diagnosis?.L0?.annoCostruzione ?? undefined,
    dataEffettivaAnnoDiCostruzione:
      diagnosis?.L0?.dataEffettivaAnnoDiCostruzione ?? undefined,
    annoDiEntrataInEsercizio:
      diagnosis?.L0?.annoDiEntrataInEsercizio ?? undefined,
    dataDiEntrataInEsercizio:
      diagnosis?.L0?.dataDiEntrataInEsercizio ?? undefined,
    interventiSignificativiManutenzione:
      diagnosis?.L0?.interventiSignificativiManutenzione ?? undefined,
    annoInterventiSignificativiRicostruzione:
      diagnosis?.L0?.annoInterventiSignificativiRicostruzione ?? undefined,
    interventiSignificativiDataEffettiva:
      diagnosis?.L0?.interventiSignificativiDataEffettiva ?? undefined,
    dataPubblicazioneNorma: diagnosis?.L0?.dataPubblicazioneNorma ?? undefined,
    enteApprovatore: diagnosis?.L0?.enteApprovatore ?? undefined,
    ispezioniPregresse: diagnosis?.L0?.ispezioniPregresse ?? undefined,
    segnalazioni: diagnosis?.L0?.segnalazioni ?? undefined,
    interventiEseguiti: diagnosis?.L0?.interventiEseguiti ?? undefined,
    attivitaMonitoraggioPregresseInCorso:
      diagnosis?.L0?.attivitaMonitoraggioPregresseInCorso ?? undefined,
    interventiManutenzione: diagnosis?.L0?.interventiManutenzione ?? undefined,
    progettoDiIncrementoDelGradoDiSicurezza:
      diagnosis?.L0?.progettoDiIncrementoDelGradoDiSicurezza ?? undefined,
    interventiDiIncrementoDelGradoDiSicurezza:
      diagnosis?.L0?.interventiDiIncrementoDelGradoDiSicurezza ?? undefined,
    classificazioneUsoStradale:
      diagnosis?.L0?.classificazioneUsoStradale ?? undefined,
    competenzaInfrastruttura:
      diagnosis?.L0?.competenzaInfrastruttura ?? undefined,
    itinerarioInternazionale:
      diagnosis?.L0?.itinerarioInternazionale ?? undefined,
    reteTEN: diagnosis?.L0?.reteTEN ?? undefined,
    itinerarioEmergenza: diagnosis?.L0?.itinerarioEmergenza ?? undefined,
    trasportiEccezionali: diagnosis?.L0?.trasportiEccezionali ?? undefined,
    disponibilitaStudiTrasportistici:
      diagnosis?.L0?.disponibilitaStudiTrasportistici ?? undefined,
    nCarreggiate: diagnosis?.L0?.nCarreggiate ?? undefined,
    nCorsieCarreggiata: diagnosis?.L0?.nCorsieCarreggiata ?? undefined,
    larghezzaCarreggiata: diagnosis?.L0?.larghezzaCarreggiata ?? undefined,
    presenzaCurve: diagnosis?.L0?.presenzaCurve ?? undefined,
    veicoliGiornoTGM: diagnosis?.L0?.veicoliGiornoTGM ?? undefined,
    valoreEffettivoTGM: diagnosis?.L0?.valoreEffettivoTGM ?? undefined,
    veicoliCommercialiGiornoTGM:
      diagnosis?.L0?.veicoliCommercialiGiornoTGM ?? undefined,
    valoreEffettivoCommercialiTGM:
      diagnosis?.L0?.valoreEffettivoCommercialiTGM ?? undefined,
    veicoliCommercialITGMSingolaCorsia:
      diagnosis?.L0?.veicoliCommercialITGMSingolaCorsia ?? undefined,
    valoreEffettivoCommercialiCorsiaTGM:
      diagnosis?.L0?.valoreEffettivoCommercialiCorsiaTGM ?? undefined,
    durataDeviazioneKm: diagnosis?.L0?.durataDeviazioneKm ?? undefined,
    durataDeviazioneMin: diagnosis?.L0?.durataDeviazioneMin ?? undefined,
    categoriaPercorsoAlternativoIndividuato:
      diagnosis?.L0?.categoriaPercorsoAlternativoIndividuato ?? undefined,
    fotoCopertina: diagnosis?.L0?.fotoCopertina ?? undefined,
    unitaFisiografica: diagnosis?.L0?.unitaFisiografica ?? undefined,
    aerosolMarini: diagnosis?.L0?.aerosolMarini ?? undefined,
    maggioreDegrado: diagnosis?.L0?.maggioreDegrado ?? undefined,
    fotoInquadramento: diagnosis?.L0?.fotoInquadramento ?? undefined,
    documentiContesto: diagnosis?.L0?.documentiContesto ?? undefined,
    siglaStradaAppartenenza:
      diagnosis?.L0?.siglaStradaAppartenenza ?? undefined,
    sismicitaArea: diagnosis?.L0?.sismicitaArea ?? undefined,
    sismicitaValoreEffettivo:
      diagnosis?.L0?.sismicitaValoreEffettivo ?? undefined,
    coordinateCTR: diagnosis?.L0?.coordinateCTR ?? undefined,
    scala: diagnosis?.L0?.scala ?? undefined,
    numeroToponimo: diagnosis?.L0?.numeroToponimo ?? undefined,
    localita: diagnosis?.L0?.localita ?? undefined,
    sistemaDiRiferimento: diagnosis?.L0?.sistemaDiRiferimento ?? undefined,
    sistemaDiRiferimentoAltro:
      diagnosis?.L0?.sistemaDiRiferimentoAltro ?? undefined,
    ellissoideDiRiferimento:
      diagnosis?.L0?.ellissoideDiRiferimento ?? undefined,
    quotaCentro: diagnosis?.L0?.quotaCentro ?? undefined,
    quotaIniziale: diagnosis?.L0?.quotaIniziale ?? undefined,
    latitudineIniziale: diagnosis?.L0?.latitudineIniziale ?? undefined,
    longitudineIniziale: diagnosis?.L0?.longitudineIniziale ?? undefined,
    quotaFinale: diagnosis?.L0?.quotaFinale ?? undefined,
    latitudineFinale: diagnosis?.L0?.latitudineFinale ?? undefined,
    longitudineFinale: diagnosis?.L0?.longitudineFinale ?? undefined,
    documentiAmministrativi:
      diagnosis?.L0?.documentiAmministrativi ?? undefined,
    corrispondenzaEntiTerzi:
      diagnosis?.L0?.corrispondenzaEntiTerzi ?? undefined,
    documentazioneIspezioniSpeciali:
      diagnosis?.L0?.documentazioneIspezioniSpeciali ?? undefined,
    documentiInerentiCondizioniRischioFrane:
      diagnosis?.L0?.documentiInerentiCondizioniRischioFrane ?? undefined,
    fotoInquadramentoFrana: diagnosis?.L0?.fotoInquadramentoFrana ?? undefined,
    stralcioMappaPericolositàFranePAI:
      diagnosis?.L0?.stralcioMappaPericolositàFranePAI ?? undefined,
    stralcioCartografico: diagnosis?.L0?.stralcioCartografico ?? undefined,
    satellitareFotoAereeStoriche:
      diagnosis?.L0?.satellitareFotoAereeStoriche ?? undefined,
    satellitareFotoAereeAttuale:
      diagnosis?.L0?.satellitareFotoAereeAttuale ?? undefined,
    ripreseFotografiche: diagnosis?.L0?.ripreseFotografiche ?? undefined,
    documentazioneIspezioniSpecialiIdraulica:
      diagnosis?.L0?.documentazioneIspezioniSpecialiIdraulica ?? undefined,
    stralcioMappaPericolositàIdraulicaPAI:
      diagnosis?.L0?.stralcioMappaPericolositàIdraulicaPAI ?? undefined,
    documentiInerentiCondizioniRischioIdraulico:
      diagnosis?.L0?.documentiInerentiCondizioniRischioIdraulico ?? undefined,
    cartografiaTematicaAutoritaDistretto:
      diagnosis?.L0?.cartografiaTematicaAutoritaDistretto ?? undefined,
    fotoInquadramentoIdraulica:
      diagnosis?.L0?.fotoInquadramentoIdraulica ?? undefined,
    strumentiFinanziamento: diagnosis?.L0?.strumentiFinanziamento ?? undefined,
    pianiStrumentiProgrammazione:
      diagnosis?.L0?.pianiStrumentiProgrammazione ?? undefined,
    documentiDescrittivi: diagnosis?.L0?.documentiDescrittivi ?? undefined,
    documentiGrafici: diagnosis?.L0?.documentiGrafici ?? undefined,
    documentiIterApprovazione:
      diagnosis?.L0?.documentiIterApprovazione ?? undefined,
    documentiDescrittiviDefinitivo:
      diagnosis?.L0?.documentiDescrittiviDefinitivo ?? undefined,
    documentiGraficiDefinitivo:
      diagnosis?.L0?.documentiGraficiDefinitivo ?? undefined,
    documentiIterApprovazioneDefinitivo:
      diagnosis?.L0?.documentiIterApprovazioneDefinitivo ?? undefined,
    documentiDescrittiviCantinerabile:
      diagnosis?.L0?.documentiDescrittiviCantinerabile ?? undefined,
    documentiGraficiCantinerabile:
      diagnosis?.L0?.documentiGraficiCantinerabile ?? undefined,
    documentiIterApprovazioneCantinerabile:
      diagnosis?.L0?.documentiIterApprovazioneCantinerabile ?? undefined,
    documentiContabili: diagnosis?.L0?.documentiContabili ?? undefined,
    graficiAllegatiContabilitaAppalto:
      diagnosis?.L0?.graficiAllegatiContabilitaAppalto ?? undefined,
    documentiContrattuali: diagnosis?.L0?.documentiContrattuali ?? undefined,
    documentiContabiliRealizzazione:
      diagnosis?.L0?.documentiContabiliRealizzazione ?? undefined,
    documentiDescrittiviVarianti:
      diagnosis?.L0?.documentiDescrittiviVarianti ?? undefined,
    documentiGraficiVarianti:
      diagnosis?.L0?.documentiGraficiVarianti ?? undefined,
    documentiContrattualiContabili:
      diagnosis?.L0?.documentiContrattualiContabili ?? undefined,
    relazioneCollaudo: diagnosis?.L0?.relazioneCollaudo ?? undefined,
    allegatiRelazioneCollaudo:
      diagnosis?.L0?.allegatiRelazioneCollaudo ?? undefined,
    documentiIspezione: diagnosis?.L0?.documentiIspezione ?? undefined,
    documentiSegnalazione: diagnosis?.L0?.documentiSegnalazione ?? undefined,
    documentiInterventoEseguito:
      diagnosis?.L0?.documentiInterventoEseguito ?? undefined,
    documentiAttivitaMonitoraggio:
      diagnosis?.L0?.documentiAttivitaMonitoraggio ?? undefined,
    documentiInterventoManutenzione:
      diagnosis?.L0?.documentiInterventoManutenzione ?? undefined,
    documentiDelProgettoDiIncrementoDelGradoDiSicurezza:
      diagnosis?.L0?.documentiDelProgettoDiIncrementoDelGradoDiSicurezza ??
      undefined,
    documentiDegliInterventiDiIncrementoDelGradoDiSicurezza:
      diagnosis?.L0?.documentiDegliInterventiDiIncrementoDelGradoDiSicurezza ??
      undefined,
    richiestePermessiTrasportiEccezionali:
      diagnosis?.L0?.richiestePermessiTrasportiEccezionali ?? undefined,
    documentazioneStudiTrasportistici:
      diagnosis?.L0?.documentazioneStudiTrasportistici ?? undefined,
    documentazioneEffettiCarichiDaTraffico:
      diagnosis?.L0?.documentazioneEffettiCarichiDaTraffico ?? undefined,
    necessitaIspezioniSpecialiRischioStruttura:
      diagnosis?.L0?.necessitaIspezioniSpecialiRischioStruttura ?? undefined,
    risultatiIspezioniSpeciali:
      diagnosis?.L0?.risultatiIspezioniSpeciali ?? undefined,
    documentiIspezioniSpecialiRischioStruttura:
      diagnosis?.L0?.documentiIspezioniSpecialiRischioStruttura ?? undefined,
    sezioneTrasversale: diagnosis?.L0?.sezioneTrasversale ?? undefined,
    sezioneLongitudinale: diagnosis?.L0?.sezioneLongitudinale ?? undefined,
    pianta: diagnosis?.L0?.pianta ?? undefined,
    elaboratiGrafici: diagnosis?.L0?.elaboratiGrafici ?? undefined,
    rilievo3D: diagnosis?.L0?.rilievo3D ?? undefined,
    normaProgettoEffettivo: diagnosis?.L0?.normaProgettoEffettivo ?? undefined,
    normaProgettoPresunto: diagnosis?.L0?.normaProgettoPresunto ?? undefined,
    // Start Effettivo Presunto
    aerosolMarinoEffettivo: diagnosis?.L0?.maggioreDegradoAltro
      ? diagnosis?.L0?.maggioreDegradoAltro === EffettivoPresunto.Effettivo
      : undefined,
    aerosolMarinoPresunto: diagnosis?.L0?.maggioreDegradoAltro
      ? diagnosis?.L0?.maggioreDegradoAltro === EffettivoPresunto.Presunto
      : undefined,
    maggioreDegradoAltro: diagnosis?.L0?.maggioreDegradoAltro ?? undefined,
    // End Effettivo Presunto
    descrizioneOpera: diagnosis?.L0?.descrizioneOpera ?? undefined,
    fotoDescrizioneOpera: diagnosis?.L0?.fotoDescrizioneOpera ?? undefined,
    descrizioneContestoAmbientale:
      diagnosis?.L0?.descrizioneContestoAmbientale ?? undefined,
    fotoContestoAmbientale: diagnosis?.L0?.fotoContestoAmbientale ?? undefined,
    descrizioneRischioFrane:
      diagnosis?.L0?.descrizioneRischioFrane ?? undefined,
    fotoRischioFrane: diagnosis?.L0?.fotoRischioFrane ?? undefined,
    descrizioneRischioIdraulico:
      diagnosis?.L0?.descrizioneRischioIdraulico ?? undefined,
    fotoRischioIdraulico: diagnosis?.L0?.fotoRischioIdraulico ?? undefined,
    descrizioneDocumentazione:
      diagnosis?.L0?.descrizioneDocumentazione ?? undefined,
    fotoDocumentazione: diagnosis?.L0?.fotoDocumentazione ?? undefined,
    descrizioneAttivitaPregresse:
      diagnosis?.L0?.descrizioneAttivitaPregresse ?? undefined,
    fotoAttivitaPregresse: diagnosis?.L0?.fotoAttivitaPregresse ?? undefined,
    descrizioneStruttura: diagnosis?.L0?.descrizioneStruttura ?? undefined,
    fotoDescrizioneStruttura:
      diagnosis?.L0?.fotoDescrizioneStruttura ?? undefined,
    descrizioneSovrastruttura:
      diagnosis?.L0?.descrizioneSovrastruttura ?? undefined,
    fotoDescrizioneSovrastruttura:
      diagnosis?.L0?.fotoDescrizioneSovrastruttura ?? undefined,
    descrizioneServizi: diagnosis?.L0?.descrizioneServizi ?? undefined,
    fotoDescrizioneServizi: diagnosis?.L0?.fotoDescrizioneServizi ?? undefined,
    documentiVerificaStatica:
      diagnosis?.L0?.documentiVerificaStatica ?? undefined,
    documentiVerificaSismica:
      diagnosis?.L0?.documentiVerificaSismica ?? undefined,

    //CAMPI DEFAULT PRIMA ISPEZIONE
    comune: {
      label: diagnosis?.L0?.comune,
      value: { id: diagnosis?.L0?.idComune },
    },
    capCode: {
      label: diagnosis?.L0?.capCode,
      value: {
        id: diagnosis?.L0?.idCap,
        classificazioneSismica: diagnosis?.L0?.classificazioneSismica,
      },
    },
    indirizzo: diagnosis?.L0?.indirizzo,
    provincia: {
      label: diagnosis?.L0?.provincia,
      value: { id: diagnosis?.L0?.idProvincia },
    },
    regione: {
      label: diagnosis?.L0?.regione,
      value: { id: diagnosis?.L0?.idRegione, code: '', cadastralCode: '' },
    },
    latitudineCentro: diagnosis?.L0?.latitudineCentro,
    longitudineCentro: diagnosis?.L0?.longitudineCentro,
    difettiElevazioni: diagnosis?.common?.difettiElevazioni,
    // END L0

    // START L1

    rilevatoreRischioStrutturale:
      diagnosis?.L1?.rilevatoreRischioStrutturale ?? undefined,
    dataIspezioneRischioStrutturale:
      diagnosis?.L1?.dataIspezioneRischioStrutturale ?? undefined,
    accessoSottoPonte: diagnosis?.L1?.accessoSottoPonte ?? undefined,
    accessoSottoPonteParziale:
      diagnosis?.L1?.accessoSottoPonteParziale ?? undefined,
    prospettiIspezionati: diagnosis?.L1?.prospettiIspezionati ?? undefined,
    rilevatoreRischioFrane: diagnosis?.L1?.rilevatoreRischioFrane ?? undefined,
    dataIspezioneRischioFrane:
      diagnosis?.L1?.dataIspezioneRischioFrane ?? undefined,

    affidabilitaComplessivaValutazioneRischioIdraulico: diagnosis?.L1
      ?.affidabilitaComplessivaValutazioneRischioIdraulico
      ? patchAffidabilitaComplessivaValutazioneRischioIdraulicoValue(
          diagnosis?.L1?.affidabilitaComplessivaValutazioneRischioIdraulico
        )
      : undefined,
    rilevatoreRischioIdraulico:
      diagnosis?.L1?.rilevatoreRischioIdraulico ?? undefined,
    dataIspezioneRischioIdraulico:
      diagnosis?.L1?.dataIspezioneRischioIdraulico ?? undefined,
    descrizioneAttivitaIspezione:
      diagnosis?.L1?.descrizioneAttivitaIspezione ?? undefined,
    documentazioneMisureMitigazione:
      diagnosis?.L1?.documentazioneMisureMitigazione ?? undefined,
    fenomeno: diagnosis?.L1?.fenomeno ?? undefined,
    tipologiaFenomeno: diagnosis?.L1?.tipologiaFenomeno ?? undefined,
    distribuzioneAttivita: diagnosis?.L1?.distribuzioneAttivita ?? undefined,
    usoSuoloAreaPotezialmenteCoinvolta:
      diagnosis?.L1?.usoSuoloAreaPotezialmenteCoinvolta ?? undefined,
    usoSuoloAreaPotezialmenteCoinvoltaAltro:
      diagnosis?.L1?.usoSuoloAreaPotezialmenteCoinvoltaAltro ?? undefined,
    posizioneInstabilitaVersante:
      diagnosis?.L1?.posizioneInstabilitaVersante ?? undefined,
    formazioniFrana: diagnosis?.L1?.formazioniFrana ?? undefined,
    elementoRiferimentoFrana:
      diagnosis?.L1?.elementoRiferimentoFrana ?? undefined,
    quotaOrloSuperiore: diagnosis?.L1?.quotaOrloSuperiore ?? undefined,
    quotaPonteViadotto: diagnosis?.L1?.quotaPonteViadotto ?? undefined,
    profonditaSuperficieDistacco:
      diagnosis?.L1?.profonditaSuperficieDistacco ?? undefined,
    areaTotaleFrana: diagnosis?.L1?.areaTotaleFrana ?? undefined,
    volumeMassaFrana: diagnosis?.L1?.volumeMassaFrana ?? undefined,
    schemaRilievoFrana: diagnosis?.L1?.schemaRilievoFrana ?? undefined,
    elaboratoGraficoFrana: diagnosis?.L1?.elaboratoGraficoFrana ?? undefined,
    franeAttive: diagnosis?.L1?.franeAttive ?? undefined,
    annotazioniRischioFrane:
      diagnosis?.L1?.annotazioniRischioFrane ?? undefined,
    fotoAnnotazioniRischioFrane:
      diagnosis?.L1?.fotoAnnotazioniRischioFrane ?? undefined,
    descrizioneInformazioniDiIspezioneAccordion6:
      diagnosis?.L1?.descrizioneInformazioniDiIspezioneAccordion6 ?? undefined,
    fotoInformazioniDiIspezioneAccordion6:
      diagnosis?.L1?.fotoInformazioniDiIspezioneAccordion6 ?? undefined,
    descrizioneRischioFraneAccordion6:
      diagnosis?.L1?.descrizioneRischioFraneAccordion6 ?? undefined,
    fotoRischioFraneAccordion6:
      diagnosis?.L1?.fotoRischioFraneAccordion6 ?? undefined,
    annotazioniRischioFraneAccordion6:
      diagnosis?.L1?.annotazioniRischioFraneAccordion6 ?? undefined,
    fotoAnnotazioniRischioFraneAccordion6:
      diagnosis?.L1?.fotoAnnotazioniRischioFraneAccordion6 ?? undefined,
    commentiConclusiviCdAFrane:
      diagnosis?.L1?.commentiConclusiviCdAFrane ?? undefined,
    descrizioneDatiGeneraliAccordion6:
      diagnosis?.L1?.descrizioneDatiGeneraliAccordion6 ?? undefined,
    fotoDatiGeneraliAccordion6:
      diagnosis?.L1?.fotoDatiGeneraliAccordion6 ?? undefined,
    descrizioneCaratteristicheAlveoAccordion6:
      diagnosis?.L1?.descrizioneCaratteristicheAlveoAccordion6 ?? undefined,
    fotoCaratteristicheAlveoAccordion6:
      diagnosis?.L1?.fotoCaratteristicheAlveoAccordion6 ?? undefined,
    descrizioneSormontoAccordion6:
      diagnosis?.L1?.descrizioneSormontoAccordion6 ?? undefined,
    fotoSormontoAccordion6: diagnosis?.L1?.fotoSormontoAccordion6 ?? undefined,
    descrizioneErosioneAccordion6:
      diagnosis?.L1?.descrizioneErosioneAccordion6 ?? undefined,
    fotoErosioneAccordion6: diagnosis?.L1?.fotoErosioneAccordion6 ?? undefined,
    descrizioneOpereDiProtezioneAccordion6:
      diagnosis?.L1?.descrizioneOpereDiProtezioneAccordion6 ?? undefined,
    fotoOpereDiProtezioneAccordion6:
      diagnosis?.L1?.fotoOpereDiProtezioneAccordion6 ?? undefined,
    descrizioneAnnotazioniRischioIdraulicoAccordion6:
      diagnosis?.L1?.descrizioneAnnotazioniRischioIdraulicoAccordion6 ??
      undefined,
    fotoAnnotazioniRischioIdraulicoAccordion6:
      diagnosis?.L1?.fotoAnnotazioniRischioIdraulicoAccordion6 ?? undefined,
    descrizioneCommentiConclusiviCdAIdraulicaAccordion6:
      diagnosis?.L1?.descrizioneCommentiConclusiviCdAIdraulicaAccordion6 ??
      undefined,
    possibilitaVerificaScalzamentoFondazioni:
      diagnosis?.L1?.possibilitaVerificaScalzamentoFondazioni ?? undefined,
    strutturaAlveoRischioIdraulico:
      diagnosis?.L1?.strutturaAlveoRischioIdraulico ?? undefined,
    fenomenoSormontoRischioIdraulico:
      diagnosis?.L1?.fenomenoSormontoRischioIdraulico ?? undefined,
    fenomenoErosioneRischioIdraulico:
      diagnosis?.L1?.fenomenoErosioneRischioIdraulico ?? undefined,
    scenariPericolositaRischioIdraulico:
      diagnosis?.L1?.scenariPericolositaRischioIdraulico ?? undefined,
    areaPericolosaRischioIdraulico:
      diagnosis?.L1?.areaPericolosaRischioIdraulico ?? undefined,
    presenzaRilieviRischioIdraulico:
      diagnosis?.L1?.presenzaRilieviRischioIdraulico ?? undefined,
    bacinoIdrograficoRischioIdraulico:
      diagnosis?.L1?.bacinoIdrograficoRischioIdraulico ?? undefined,
    bacinoIdrograficoEffettivoRischioIdraulico:
      diagnosis?.L1?.bacinoIdrograficoEffettivoRischioIdraulico ?? undefined,
    tipologiaReticoloRischioIdraulico:
      diagnosis?.L1?.tipologiaReticoloRischioIdraulico ?? undefined,
    ricadenteAreaAlluvioniRischioIdraulico:
      diagnosis?.L1?.ricadenteAreaAlluvioniRischioIdraulico ?? undefined,
    confinamentoAlveoRischioIdraulico:
      diagnosis?.L1?.confinamentoAlveoRischioIdraulico ?? undefined,
    morfologiaAlveoRischioIdraulico:
      diagnosis?.L1?.morfologiaAlveoRischioIdraulico ?? undefined,
    morfologiaAlveoCanaleSingoloRischioIdraulico:
      diagnosis?.L1?.morfologiaAlveoCanaleSingoloRischioIdraulico ?? undefined,
    tipologiaFondoAlveoRischioIdraulico:
      diagnosis?.L1?.tipologiaFondoAlveoRischioIdraulico ?? undefined,
    materialeAlveoRischioIdraulico:
      diagnosis?.L1?.materialeAlveoRischioIdraulico ?? undefined,
    accumuloDetritiRischioIdraulico:
      diagnosis?.L1?.accumuloDetritiRischioIdraulico,
    fotoNaturaMaterialeAlveo:
      diagnosis?.L1?.fotoNaturaMaterialeAlveo ?? undefined,
    evoluzioneAlveoRischioIdraulico:
      diagnosis?.L1?.evoluzioneAlveoRischioIdraulico ?? undefined,
    fotoDivagazionePlanimetricaIdraulico:
      diagnosis?.L1?.fotoDivagazionePlanimetricaIdraulico ?? undefined,
    tipologiaCorsoAcquaRischioIdraulico:
      diagnosis?.L1?.tipologiaCorsoAcquaRischioIdraulico ?? undefined,
    rilievoPlanimetrico: diagnosis?.L1?.rilievoPlanimetrico ?? undefined,
    rilevoSezioneTrasversale:
      diagnosis?.L1?.rilevoSezioneTrasversale ?? undefined,
    rilievoSezioneLongitudinaleAlveo:
      diagnosis?.L1?.rilievoSezioneLongitudinaleAlveo ?? undefined,
    elaboratoGraficoSezioneFluviale:
      diagnosis?.L1?.elaboratoGraficoSezioneFluviale ?? undefined,
    riferimentoZeroMetriRischioIdraulico:
      diagnosis?.L1?.riferimentoZeroMetriRischioIdraulico ?? undefined,
    quotaMinimaIntradossoRischioIdraulico:
      diagnosis?.L1?.quotaMinimaIntradossoRischioIdraulico ?? undefined,

    quotaPeloLiberoP2RischioIdraulico:
      diagnosis?.L1?.quotaPeloLiberoP2RischioIdraulico ?? undefined,
    quotaPeloLiberoP3RischioIdraulico:
      diagnosis?.L1?.quotaPeloLiberoP3RischioIdraulico ?? undefined,
    quotaArgineMinRischioIdraulico:
      diagnosis?.L1?.quotaArgineMinRischioIdraulico ?? undefined,
    quotaSpondaMaxRischioIdraulico:
      diagnosis?.L1?.quotaSpondaMaxRischioIdraulico ?? undefined,
    quotaFondoAlveoRischioIdraulico:
      diagnosis?.L1?.quotaFondoAlveoRischioIdraulico ?? undefined,
    quotaPeloLiberoStimaRischioIdraulico:
      diagnosis?.L1?.quotaPeloLiberoStimaRischioIdraulico ?? undefined,
    fotoDepositoSedimenti: diagnosis?.L1?.fotoDepositoSedimenti ?? undefined,
    fotoTrasportoMateriale: diagnosis?.L1?.fotoTrasportoMateriale ?? undefined,
    larghezzaAlveoPileSpalle:
      diagnosis?.L1?.larghezzaAlveoPileSpalle ?? undefined,
    // Start Rilevabile Non Rilevabile
    larghezzaAlveoPileSpalleFlag:
      diagnosis?.L1?.larghezzaAlveoPileSpalleFlag ?? undefined,
    larghezzaAlveoPileSpalleRilevabile: diagnosis?.L1
      ?.larghezzaAlveoPileSpalleFlag
      ? diagnosis?.L1?.larghezzaAlveoPileSpalleFlag ===
        RilevabileNonRilevabile.Rilevabile
      : undefined,
    larghezzaAlveoPileSpalleNonRilevabile: diagnosis?.L1
      ?.larghezzaAlveoPileSpalleFlag
      ? diagnosis?.L1?.larghezzaAlveoPileSpalleFlag ===
        RilevabileNonRilevabile.NonRilevabile
      : undefined,
    quotaPeloLiberoStimataRilevabile: diagnosis?.L1
      ?.quotaPeloLiberoStimaFlagRischioIdraulico
      ? diagnosis?.L1?.quotaPeloLiberoStimaFlagRischioIdraulico ===
        RilevabileNonRilevabile.Rilevabile
      : undefined,
    quotaPeloLiberoStimataNonRilevabile: diagnosis?.L1
      ?.quotaPeloLiberoStimaFlagRischioIdraulico
      ? diagnosis?.L1?.quotaPeloLiberoStimaFlagRischioIdraulico ===
        RilevabileNonRilevabile.NonRilevabile
      : undefined,
    quotaPeloLiberoStimaFlagRischioIdraulico:
      diagnosis?.L1?.quotaPeloLiberoStimaFlagRischioIdraulico ?? undefined,

    larghezzaComplessivaGoleneFlag:
      diagnosis?.L1?.larghezzaComplessivaGoleneFlag ?? undefined,
    larghezzaGoleneRilevabile: diagnosis?.L1?.larghezzaComplessivaGoleneFlag
      ? diagnosis?.L1?.larghezzaComplessivaGoleneFlag ===
        RilevabileNonRilevabile.Rilevabile
      : undefined,
    larghezzaGoleneNonRilevabile: diagnosis?.L1?.larghezzaComplessivaGoleneFlag
      ? diagnosis?.L1?.larghezzaComplessivaGoleneFlag ===
        RilevabileNonRilevabile.NonRilevabile
      : undefined,

    // End Rilevabile Non Rilevabile

    geometriaSezTrasversalePile:
      diagnosis?.L1?.geometriaSezTrasversalePile ?? undefined,
    dimensioneSezTrasversalePile:
      diagnosis?.L1?.dimensioneSezTrasversalePile ?? undefined,
    statoConservazionePile: diagnosis?.L1?.statoConservazionePile ?? undefined,
    geometriaSezTrasversaleSpalle:
      diagnosis?.L1?.geometriaSezTrasversaleSpalle ?? undefined,
    dimensioneSezTrasversaleSpalle:
      diagnosis?.L1?.dimensioneSezTrasversaleSpalle ?? undefined,
    statoConservazioneSpalle:
      diagnosis?.L1?.statoConservazioneSpalle ?? undefined,

    // Start Effettiva Presunta
    larghezzaPilaOAggettoSpallaFlag:
      diagnosis?.L1?.larghezzaPilaOAggettoSpallaFlag ?? undefined,
    larghezzaPilaOAggettoSpallaEffettiva: diagnosis?.L1
      ?.larghezzaPilaOAggettoSpallaFlag
      ? diagnosis?.L1?.larghezzaPilaOAggettoSpallaFlag ===
        EffettivoPresunto.Effettivo
      : undefined,
    larghezzaPilaOAggettoSpallaPresunta: diagnosis?.L1
      ?.larghezzaPilaOAggettoSpallaFlag
      ? diagnosis?.L1?.larghezzaPilaOAggettoSpallaFlag ===
        EffettivoPresunto.Presunto
      : undefined,

    profonditaFondazioneRispettoAlveoFlag:
      diagnosis?.L1?.profonditaFondazioneRispettoAlveoFlag ?? undefined,
    profonditaFondazioneRispettoAlveoEffettiva: diagnosis?.L1
      ?.profonditaFondazioneRispettoAlveoFlag
      ? diagnosis?.L1?.profonditaFondazioneRispettoAlveoFlag ===
        EffettivoPresunto.Effettivo
      : undefined,
    profonditaFondazioneRispettoAlveoPresunta: diagnosis?.L1
      ?.profonditaFondazioneRispettoAlveoFlag
      ? diagnosis?.L1?.profonditaFondazioneRispettoAlveoFlag ===
        EffettivoPresunto.Presunto
      : undefined,

    quotaMinimaIntradossoFlagRilevabileRischioIdraulico:
      diagnosis?.L1?.quotaMinimaIntradossoFlagRilevabileRischioIdraulico ??
      undefined,
    quotaMinimaIntradossoEffettivo: diagnosis?.L1
      ?.quotaMinimaIntradossoFlagRilevabileRischioIdraulico
      ? diagnosis?.L1?.quotaMinimaIntradossoFlagRilevabileRischioIdraulico ===
        EffettivoPresunto.Effettivo
      : undefined,
    quotaMinimaIntradossoPresunto: diagnosis?.L1
      ?.quotaMinimaIntradossoFlagRilevabileRischioIdraulico
      ? diagnosis?.L1?.quotaMinimaIntradossoFlagRilevabileRischioIdraulico ===
        EffettivoPresunto.Presunto
      : undefined,
    // End Effettiva Presunta
    larghezzaAlveoPonte: diagnosis?.L1?.larghezzaAlveoPonte ?? undefined,
    areeGolenaliAssenti: diagnosis?.L1?.areeGolenaliAssenti ?? undefined,
    larghezzaComplessivaGolene:
      diagnosis?.L1?.larghezzaComplessivaGolene ?? undefined,
    larghezzaComplessivaGolenePonte:
      diagnosis?.L1?.larghezzaComplessivaGolenePonte,
    larghezzaPilaOAggettoSpalla:
      diagnosis?.L1?.larghezzaPilaOAggettoSpalla ?? undefined,
    profonditaScavoMax: diagnosis?.L1?.profonditaScavoMax ?? undefined,
    profonditaFondazioneRispettoAlveo:
      diagnosis?.L1?.profonditaFondazioneRispettoAlveo ?? undefined,
    evoluzioneEventualeDelFondo:
      diagnosis?.L1?.evoluzioneEventualeDelFondo ?? undefined,
    fotoProfonditaFondazioni:
      diagnosis?.L1?.fotoProfonditaFondazioni ?? undefined,
    abbassamentoAccentuatoAlleFondazioni:
      diagnosis?.L1?.abbassamentoAccentuatoAlleFondazioni ?? undefined,
    fotoAbassamentoAlveo: diagnosis?.L1?.fotoAbassamentoAlveo ?? undefined,
    fotoAccumuliMonteDellaPila:
      diagnosis?.L1?.fotoAccumuliMonteDellaPila ?? undefined,
    opereProtezioneSpondale:
      diagnosis?.L1?.opereProtezioneSpondale ?? undefined,
    opereProtezioneSpondaleAltro:
      diagnosis?.L1?.opereProtezioneSpondaleAltro ?? undefined,
    statoConservazioneAdeguatezzaOpera:
      diagnosis?.L1?.statoConservazioneAdeguatezzaOpera ?? undefined,
    fotoProtezioneSpondale: diagnosis?.L1?.fotoProtezioneSpondale ?? undefined,
    statoConservazioneProtezionePiede:
      diagnosis?.L1?.statoConservazioneProtezionePiede ?? undefined,
    fotoProtezionePileSpalle:
      diagnosis?.L1?.fotoProtezionePileSpalle ?? undefined,
    statoConservazioneAdeguatezzaOperaBriglia:
      diagnosis?.L1?.statoConservazioneAdeguatezzaOperaBriglia ?? undefined,
    fotoBrigliaDiProtezione:
      diagnosis?.L1?.fotoBrigliaDiProtezione ?? undefined,
    opereArginaturaDiAlterazionePortate:
      diagnosis?.L1?.opereArginaturaDiAlterazionePortate ?? undefined,
    statoConservazioneAdeguatezzaOpereArginatura:
      diagnosis?.L1?.statoConservazioneAdeguatezzaOpereArginatura ?? undefined,
    tipologiaOpereArginatura:
      diagnosis?.L1?.tipologiaOpereArginatura ?? undefined,
    tipologiaAreePossibileAllagamento:
      diagnosis?.L1?.tipologiaAreePossibileAllagamento ?? undefined,
    misureLaminazioneMitigazione:
      diagnosis?.L1?.misureLaminazioneMitigazione ?? undefined,
    statoConservazioneAdeguatezzaOpereLaminazione:
      diagnosis?.L1?.statoConservazioneAdeguatezzaOpereLaminazione ?? undefined,
    tipologiaMisureLaminazioneMitigazione:
      diagnosis?.L1?.tipologiaMisureLaminazioneMitigazione ?? undefined,
    sistemaMonitoraggioRischioIdraulico:
      diagnosis?.L1?.sistemaMonitoraggioRischioIdraulico ?? undefined,
    tipologiaSistemaMonitoraggioRischioIdraulico:
      diagnosis?.L1?.tipologiaSistemaMonitoraggioRischioIdraulico ?? undefined,
    statoConservazioneSistemaMonitoraggioRischioIdraulico:
      diagnosis?.L1?.statoConservazioneSistemaMonitoraggioRischioIdraulico ??
      undefined,
    annotazioniRischioIdraulico:
      diagnosis?.L1?.annotazioniRischioIdraulico ?? undefined,
    fotoAnnotazioneRischioIdraulico:
      diagnosis?.L1?.fotoAnnotazioneRischioIdraulico ?? undefined,
    elCriticiVulnerabilitaStrutturaleCriticiStatoDegrado:
      diagnosis?.L1?.elCriticiVulnerabilitaStrutturaleCriticiStatoDegrado,
    elCriticiVulnerabilitaStrutturaleTipologiaElemento:
      diagnosis?.L1?.elCriticiVulnerabilitaStrutturaleTipologiaElemento ??
      undefined,
    elCriticiVulnerabilitaStrutturaleDescrizioneSintetica:
      diagnosis?.L1?.elCriticiVulnerabilitaStrutturaleDescrizioneSintetica ??
      undefined,
    elCriticiVulnerabilitaStrutturaleFoto:
      diagnosis?.L1?.elCriticiVulnerabilitaStrutturaleFoto ?? undefined,
    elCriticiVulnerabilitaStrutturaleStatoDegrado:
      diagnosis?.L1?.elCriticiVulnerabilitaStrutturaleStatoDegrado ?? undefined,
    elCriticiVulnerabilitaSismicaStatoDegrado:
      diagnosis?.L1?.elCriticiVulnerabilitaSismicaStatoDegrado ?? undefined,
    elCriticiVulnerabilitaSismicaTipologiaElemento:
      diagnosis?.L1?.elCriticiVulnerabilitaSismicaTipologiaElemento ??
      undefined,
    elCriticiVulnerabilitaSismicaDescrizioneSintetica:
      diagnosis?.L1?.elCriticiVulnerabilitaSismicaDescrizioneSintetica ??
      undefined,
    elCriticiVulnerabilitaSismicaFoto:
      diagnosis?.L1?.elCriticiVulnerabilitaSismicaFoto ?? undefined,
    condizioniCriticheVulnerabilitaStrutturaTipologiaEl:
      convertfromInttoArray(
        diagnosis?.L1?.condizioniCriticheVulnerabilitaStrutturaTipologiaEl
      ) || [],
    condizioniCriticheVulnerabilitaStrutturaAltro:
      diagnosis?.L1?.condizioniCriticheVulnerabilitaStrutturaAltro ?? undefined,
    condizioniCriticheVulnerabilitaStrutturaFoto:
      diagnosis?.L1?.condizioniCriticheVulnerabilitaStrutturaFoto ?? undefined,
    condizioniCriticheVulnerabilitaSismicaTipologiaEl:
      convertfromInttoArray(
        diagnosis?.L1?.condizioniCriticheVulnerabilitaSismicaTipologiaEl
      ) || [],
    condizioniCriticheVulnerabilitaSismicaTipologiaAltro:
      diagnosis?.L1?.condizioniCriticheVulnerabilitaSismicaTipologiaAltro ??
      undefined,
    condizioniCriticheVulnerabilitaSismicaFoto:
      diagnosis?.L1?.condizioniCriticheVulnerabilitaSismicaFoto ?? undefined,
    annotazioniRischioStrutturaleESismico:
      diagnosis?.L1?.annotazioniRischioStrutturaleESismico ?? undefined,
    fotoDiAnnotazioneRischioStrutturaleESismico:
      diagnosis?.L1?.fotoDiAnnotazioneRischioStrutturaleESismico ?? undefined,
    elementiVulnerabilitaSismicaDescrizione:
      diagnosis?.L1?.elementiVulnerabilitaSismicaDescrizione ?? undefined,
    elementiVulnerabilitaSismicaFoto:
      diagnosis?.L1?.elementiVulnerabilitaSismicaFoto ?? undefined,
    descrizioneStrutturaElevazione:
      diagnosis?.L1?.descrizioneStrutturaElevazione ?? undefined,
    fotoDescrizioneStrutturaElevazione:
      diagnosis?.L1?.fotoDescrizioneStrutturaElevazione ?? undefined,
    descrizioneStrutturaCollegamenti:
      diagnosis?.L1?.descrizioneStrutturaCollegamenti ?? undefined,
    fotoStrutturaCollegamenti:
      diagnosis?.L1?.fotoStrutturaCollegamenti ?? undefined,
    descrizioneStrutturaImpalcato:
      diagnosis?.L1?.descrizioneStrutturaImpalcato ?? undefined,
    fotoStrutturaImpalcato: diagnosis?.L1?.fotoStrutturaImpalcato ?? undefined,
    descrizioneStrutturaAspettiCritici:
      diagnosis?.L1?.descrizioneStrutturaAspettiCritici ?? undefined,
    fotoStrutturaAspettiCritici:
      diagnosis?.L1?.fotoStrutturaAspettiCritici ?? undefined,
    descrizioneRischioStrutturaleFondazionale:
      diagnosis?.L1?.descrizioneRischioStrutturaleFondazionale ?? undefined,
    fotoRischioStruttraleFondazionale:
      diagnosis?.L1?.fotoRischioStruttraleFondazionale ?? undefined,
    descrizioneStrutturaRischioSismico:
      diagnosis?.L1?.descrizioneStrutturaRischioSismico ?? undefined,
    fotoStrutturaRischioSismico:
      diagnosis?.L1?.fotoStrutturaRischioSismico ?? undefined,
    commentiConclusiviCdAStrutturaleFondazionale:
      diagnosis?.L1?.commentiConclusiviCdAStrutturaleFondazionale ?? undefined,
    commentiConclusiviCdASismica:
      diagnosis?.L1?.commentiConclusiviCdASismica ?? undefined,
    descrizioneSovrastrutturaImpalcato:
      diagnosis?.L1?.descrizioneSovrastrutturaImpalcato ?? undefined,
    fotoSovrastrutturaImpalcato:
      diagnosis?.L1?.fotoSovrastrutturaImpalcato ?? undefined,
    descrizioneOsservazioniSovrastrutture:
      diagnosis?.L1?.descrizioneOsservazioniSovrastrutture ?? undefined,
    descrizioneServiziPali: diagnosis?.L1?.descrizioneServiziPali ?? undefined,
    fotoServiziPali: diagnosis?.L1?.fotoServiziPali ?? undefined,
    descrizioneServiziAcque:
      diagnosis?.L1?.descrizioneServiziAcque ?? undefined,
    fotoServiziAcque: diagnosis?.L1?.fotoServiziAcque ?? undefined,
    descrizioneServiziSottoservizi:
      diagnosis?.L1?.descrizioneServiziSottoservizi ?? undefined,
    fotoServiziSottoservizi:
      diagnosis?.L1?.fotoServiziSottoservizi ?? undefined,
    commentiConclusiviServizi:
      diagnosis?.L1?.commentiConclusiviServizi ?? undefined,
    // END L1
  };
  return data;
};
