import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import {
  CDAOptions,
  tipologiaControventoOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import ControventiForm from './ControventiForm';
const ControventiSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const mensoleTamponiCampateImpalcatoTableControventiTableWatch = watch(
    `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable`
  );

  const mensoleTamponiCampateImpalcatoTable = watch(
    `mensoleTamponiCampateImpalcatoTable`
  );
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const mensoleTamponiCampateToCopy = mensoleTamponiCampateImpalcatoTable?.map(
    el => el.controventiTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsControventi = item => {
    setValue('controventiTableValue', [item]);
    setValue('tipologiaControventoTableValue', item.tipologiaControvento);
  };

  const handleSelectAllClickControventi = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable`
      );
      setSelected(selectedValue);
      setValue('controventiTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('controventiTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a4_tecnicoRilevatoreControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a4_dataIspezioneControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a4_elementoCriticoStrutturaControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_elementoCriticoSismicaControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaStrutturaControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a4_condizioneCriticaSismicaControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a4_noteGeneraliControventi',
      getValues(
        `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a4_tecnicoRilevatoreControventi');
    const dataIspezione = getValues('l1t2a4_dataIspezioneControventi');
    const elementoCriticoStruttura = getValues(
      'l1t2a4_elementoCriticoStrutturaControventi'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a4_elementoCriticoSismicaControventi'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a4_condizioneCriticaStrutturaControventi'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a4_condizioneCriticaSismicaControventi'
    );
    const noteGenerali = getValues('l1t2a4_noteGeneraliControventi');
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a4_tecnicoRilevatoreControventi`, '');
    setValue(`l1t2a4_dataIspezioneControventi`, null);
    setValue(`l1t2a4_elementoCriticoStrutturaControventi`, '');
    setValue(`l1t2a4_elementoCriticoSismicaControventi`, '');
    setValue(`l1t2a4_condizioneCriticaStrutturaControventi`, '');
    setValue(`l1t2a4_condizioneCriticaSismicaControventi`, '');
    setValue(`l1t2a4_noteGeneraliControventi`, '');
  };

  const dataControventiConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaControvento: {
        label: 'Tipologia',
        format: tipologiaControventoOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaControvento: {
        label: 'Tipologia',
        format: tipologiaControventoOptions,
      },
    },
  };

  const mensoleTamponiCampateImpalcatoSelezione = watch(
    'mensoleTamponiCampateImpalcatoSelezione'
  );

  useEffect(() => {
    if (mensoleTamponiCampateImpalcatoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [mensoleTamponiCampateImpalcatoSelezione]);

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'controventi'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoControventi.drawerTitle}
        drawerText={INFO.impalcatoControventi.drawerText}
        singleElementTitle={'controvento'}
        tableWatch={mensoleTamponiCampateImpalcatoTableControventiTableWatch}
        tableWatchName={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable`}
        elementForm={
          <ControventiForm
            controventiFields={
              mensoleTamponiCampateImpalcatoTableControventiTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            controventiTable={`mensoleTamponiCampateImpalcatoTable[${selectedIdx}].controventiTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a4_tecnicoRilevatoreControventi'}
            dateName={'l1t2a4_dataIspezioneControventi'}
            elementoCriticoStrutturaName={
              'l1t2a4_elementoCriticoStrutturaControventi'
            }
            elementoCriticoSismicaName={
              'l1t2a4_elementoCriticoSismicaControventi'
            }
            condizioneCriticaStrutturaName={
              'l1t2a4_condizioneCriticaStrutturaControventi'
            }
            condizioneCriticaSismicaName={
              'l1t2a4_condizioneCriticaSismicaControventi'
            }
            noteGeneraliName={'l1t2a4_noteGeneraliControventi'}
          />
        }
        tableConfig={dataControventiConfig}
        editRows={editRowsControventi}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickControventi}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaControvento'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
ControventiSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default ControventiSection;
