import { UserType } from 'constants/users';

export const ExportOptions = {
  SchedeCensimento: 1,
  TabelleSintesiL0: 2,
  CsvL0: 3,
  SchedeIspezioniComplete: 4,
  TabelleSintesiL1: 5,
  CsvL1: 6,
  StrutturaSismica: 7,
  SchedeValutazioneDifetti: 8,
  DocFotograficaDifetti: 9,
  RischioFrane: 10,
  DocFotograficaFrane: 11,
  RischioIdraulico: 12,
  DocFotograficaIdraulica: 13,
  SintesiCdAComplessiva: 14,
  RelazioneTecnicaCdA: 15,
  CsvL2: 16,
  SintesiCdAStruttura: 17,
  SintesiCdASismica: 18,
  RelazioneTecnicaCdAStrutturaleSismica: 19,
  SintesiCdAFrane: 20,
  RelazioneTecnicaCdAFrane: 21,
  SintesiCdAIdraulica: 22,
  RelazioneTecnicaCdAIdraulica: 23,
  CsvAnagraficaBase: 24,
  CsvPontiViadotti: 25,
  RelazioneTecnicaL0: 26,
  CsvPersonalizzati: 27,
  CsvManutenzione: 28,
  CsvAinopL1L2: 29,
};

export const selectOptions = [
  {
    label: 'csv',
    value: 0,
    visible: [UserType.Ente],
  },
  {
    label: 'Personalizzata - campi selezionabili (.csv)',
    value: ExportOptions.CsvPersonalizzati,
    visible: [UserType.Ente],
    disabledIfNoSubscription: true,
  },
  {
    label: 'csv Livello 0',
    value: ExportOptions.CsvL0,
    visible: [UserType.Ente],
  },
  {
    label: 'csv Livello 1',
    value: ExportOptions.CsvL1,
    visible: [UserType.Ente],
  },
  {
    label: 'csv Livello 2',
    value: ExportOptions.CsvL2,
    visible: [UserType.Ente],
  },
  {
    label: 'csv Manutenzione',
    value: ExportOptions.CsvManutenzione,
    visible: [UserType.Ente],
  },

  {
    label: 'AINOP',
    value: 0,
    visible: [UserType.Ente],
  },
  {
    label: 'AINOP - csv Anagrafica base',
    value: ExportOptions.CsvAnagraficaBase,
    visible: [UserType.Ente],
    disabledIfNoSubscription: true,
  },
  {
    label: 'AINOP - csv Ponti e Viadotti stradali',
    value: ExportOptions.CsvPontiViadotti,
    visible: [UserType.Ente],
    disabledIfNoSubscription: true,
  },
  {
    label: 'AINOP - csv L1,2',
    value: ExportOptions.CsvAinopL1L2,
    visible: [UserType.Ente],
    disabledIfNoSubscription: true,
  },

  {
    label: 'Livello 0 - Censimento',
    value: 0,
  },
  {
    label: 'Schede di censimento',
    value: ExportOptions.SchedeCensimento,
  },
  {
    label: 'Tabelle di sintesi',
    value: ExportOptions.TabelleSintesiL0,
    /* disabled: process.env.REACT_APP_ENVIRONMENT === 'main', */
  },
  {
    label: 'Relazione tecnica L0',
    value: ExportOptions.RelazioneTecnicaL0,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
  },

  {
    label: 'Livello 1 - Ispezione',
    value: 0,
  },
  {
    label: 'Schede ispezioni - Complete',
    value: ExportOptions.SchedeIspezioniComplete,
  },
  {
    label: 'Tabelle di sintesi',
    value: ExportOptions.TabelleSintesiL1,
    /* disabled: process.env.REACT_APP_ENVIRONMENT === 'main', */
  },
  {
    label: 'STRUTTURA E SISMICA',
    value: null,
  },
  {
    label: 'Schede ispezioni - Struttura e Sismica',
    value: ExportOptions.StrutturaSismica,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Schede di Valutazione dei Difetti',
    value: ExportOptions.SchedeValutazioneDifetti,
  },
  {
    label: 'Documentazione fotografica difetti',
    value: ExportOptions.DocFotograficaDifetti,
  },
  {
    label: 'Relazione tecnica con CdA Strutturale e Sismica',
    value: ExportOptions.RelazioneTecnicaCdAStrutturaleSismica,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
  },

  {
    label: 'FRANE',
    value: null,
  },
  {
    label: 'Schede ispezioni - Rischio Frane',
    value: ExportOptions.RischioFrane,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Documentazione fotografica',
    value: ExportOptions.DocFotograficaFrane,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Relazione tecnica con CdA Frane',
    value: ExportOptions.RelazioneTecnicaCdAFrane,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
  },
  {
    label: 'IDRAULICA',
    value: null,
  },
  {
    label: 'Schede ispezioni - Rischio Idraulico',
    value: ExportOptions.RischioIdraulico,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Documentazione fotografica',
    value: ExportOptions.DocFotograficaIdraulica,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Relazione tecnica con CdA Idraulica',
    value: ExportOptions.RelazioneTecnicaCdAIdraulica,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
  },
  {
    label: 'Livello 2 - Classe di attenzione',
    value: 0,
  },
  {
    label: 'Sintesi CdA Complessiva',
    value: ExportOptions.SintesiCdAComplessiva,
  },
  {
    label: 'Relazione tecnica con CdA Complessiva',
    value: ExportOptions.RelazioneTecnicaCdA,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
  },
  {
    label: 'RISCHIO STRUTTURALE, FONDAZIONALE E SISMICO',
    value: null,
    visible: [UserType.Professionista],
  },
  {
    label: 'Sintesi CdA Struttura',
    value: ExportOptions.SintesiCdAStruttura,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
  {
    label: 'Sintesi CdA Sismica',
    value: ExportOptions.SintesiCdASismica,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },

  {
    label: 'RISCHIO FRANE',
    value: null,
    visible: [UserType.Professionista],
  },
  {
    label: 'Sintesi CdA Frane',
    value: ExportOptions.SintesiCdAFrane,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },

  {
    label: 'RISCHIO IDRAULICO',
    value: null,
    visible: [UserType.Professionista],
  },
  {
    label: 'Sintesi CdA Idraulica',
    value: ExportOptions.SintesiCdAIdraulica,
    visible: [UserType.Professionista],
    disabledIfNoSubscription: true,
    //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
  },
];

// OLD SELECTOPTIONS
// export const selectOptions = [
//   {
//     label: 'csv personalizzati',
//     value: 0,
//     visible: [UserType.Ente],
//   },
//   {
//     label: 'Personalizzata - campi selezionabili (.csv)',
//     value: ExportOptions.CsvPersonalizzati,
//     visible: [UserType.Ente],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'Livello 0 - Censimento',
//     value: 0,
//   },
//   {
//     label: 'Schede di censimento',
//     value: ExportOptions.SchedeCensimento,
//   },
//   {
//     label: 'Tabelle di sintesi',
//     value: ExportOptions.TabelleSintesiL0,
//     /* disabled: process.env.REACT_APP_ENVIRONMENT === 'main', */
//   },
//   {
//     label: 'Relazione tecnica L0',
//     value: ExportOptions.RelazioneTecnicaL0,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'csv Livello 0',
//     value: ExportOptions.CsvL0,
//     visible: [UserType.Ente],
//   },
//   {
//     label: 'Livello 1 - Ispezione',
//     value: 0,
//   },
//   {
//     label: 'Schede ispezioni - Complete',
//     value: ExportOptions.SchedeIspezioniComplete,
//   },
//   {
//     label: 'Tabelle di sintesi',
//     value: ExportOptions.TabelleSintesiL1,
//     /* disabled: process.env.REACT_APP_ENVIRONMENT === 'main', */
//   },
//   {
//     label: 'csv Livello 1',
//     value: ExportOptions.CsvL1,
//     visible: [UserType.Ente],
//   },
//   {
//     label: 'STRUTTURA E SISMICA',
//     value: null,
//   },
//   {
//     label: 'Schede ispezioni - Struttura e Sismica',
//     value: ExportOptions.StrutturaSismica,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Schede di Valutazione dei Difetti',
//     value: ExportOptions.SchedeValutazioneDifetti,
//   },
//   {
//     label: 'Documentazione fotografica difetti',
//     value: ExportOptions.DocFotograficaDifetti,
//   },

//   {
//     label: 'FRANE',
//     value: null,
//   },
//   {
//     label: 'Schede ispezioni - Rischio Frane',
//     value: ExportOptions.RischioFrane,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Documentazione fotografica',
//     value: ExportOptions.DocFotograficaFrane,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'IDRAULICA',
//     value: null,
//   },
//   {
//     label: 'Schede ispezioni - Rischio Idraulico',
//     value: ExportOptions.RischioIdraulico,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Documentazione fotografica',
//     value: ExportOptions.DocFotograficaIdraulica,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Livello 2 - Classe di attenzione',
//     value: 0,
//   },
//   {
//     label: 'Sintesi CdA Complessiva',
//     value: ExportOptions.SintesiCdAComplessiva,
//   },
//   {
//     label: 'Relazione tecnica con CdA Complessiva',
//     value: ExportOptions.RelazioneTecnicaCdA,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'csv Livello 2',
//     value: ExportOptions.CsvL2,
//     visible: [UserType.Ente],
//   },
//   {
//     label: 'RISCHIO STRUTTURALE, FONDAZIONALE E SISMICO',
//     value: null,
//     visible: [UserType.Professionista],
//   },
//   {
//     label: 'Sintesi CdA Struttura',
//     value: ExportOptions.SintesiCdAStruttura,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Sintesi CdA Sismica',
//     value: ExportOptions.SintesiCdASismica,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Relazione tecnica con CdA Strutturale e Sismica',
//     value: ExportOptions.RelazioneTecnicaCdAStrutturaleSismica,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'RISCHIO FRANE',
//     value: null,
//     visible: [UserType.Professionista],
//   },
//   {
//     label: 'Sintesi CdA Frane',
//     value: ExportOptions.SintesiCdAFrane,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Relazione tecnica con CdA Frane',
//     value: ExportOptions.RelazioneTecnicaCdAFrane,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'RISCHIO IDRAULICO',
//     value: null,
//     visible: [UserType.Professionista],
//   },
//   {
//     label: 'Sintesi CdA Idraulica',
//     value: ExportOptions.SintesiCdAIdraulica,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//     //disabled: process.env.REACT_APP_ENVIRONMENT === 'main',
//   },
//   {
//     label: 'Relazione tecnica con CdA Idraulica',
//     value: ExportOptions.RelazioneTecnicaCdAIdraulica,
//     visible: [UserType.Professionista],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'SCHEDE AINOP',
//     value: 0,
//     visible: [UserType.Ente],
//   },
//   {
//     label: 'csv Anagrafica base',
//     value: ExportOptions.CsvAnagraficaBase,
//     visible: [UserType.Ente],
//     disabledIfNoSubscription: true,
//   },
//   {
//     label: 'csv Ponti e Viadotti stradali',
//     value: ExportOptions.CsvPontiViadotti,
//     visible: [UserType.Ente],
//     disabledIfNoSubscription: true,
//   },
// ];

export const LambdaTypeMap = {
  [ExportOptions.SchedeCensimento]: 18,
  [ExportOptions.TabelleSintesiL0]: 19,
  [ExportOptions.RelazioneTecnicaL0]: 20,
  [ExportOptions.TabelleSintesiL1]: 21,
  [ExportOptions.StrutturaSismica]: 22,
  [ExportOptions.RischioFrane]: 23,
  [ExportOptions.DocFotograficaFrane]: 24,
  [ExportOptions.RischioIdraulico]: 25,
  [ExportOptions.DocFotograficaIdraulica]: 26,
  [ExportOptions.RelazioneTecnicaCdAStrutturaleSismica]: 30,
  [ExportOptions.RelazioneTecnicaCdAFrane]: 32,
  [ExportOptions.RelazioneTecnicaCdAIdraulica]: 34,
  [ExportOptions.CsvPersonalizzati]: 35,
  [ExportOptions.CsvL0]: 36,
  [ExportOptions.CsvL1]: 37,
  [ExportOptions.CsvL2]: 38,
  [ExportOptions.CsvAnagraficaBase]: 39,
  [ExportOptions.CsvPontiViadotti]: 40,
  [ExportOptions.DocFotograficaDifetti]: 6,
  [ExportOptions.SchedeIspezioniComplete]: 8,
  [ExportOptions.SchedeValutazioneDifetti]: 9,
  [ExportOptions.RelazioneTecnicaCdA]: 10,
  [ExportOptions.CsvManutenzione]: 106,
  [ExportOptions.CsvAinopL1L2]: 41,
};

export const ClusterExportType = {
  [ExportOptions.SchedeValutazioneDifetti]: 1,
};

export const ExportResponseStatus = {
  OK: 1,
  ERROR: 2,
};

export const ExportErrorMessages = {
  MUST_ARCHIVE_OR_CONFIRM_INSPECTION: 1,
  NO_CONFIRMED_OR_ARCHIVE_INSPECTIONS:
    'This bridge has no Confirmed or Archived inspections',
  NO_CONFIRMED_OR_ARCHIVE_INSPECTIONS_:
    'Error: 400 - Bad Request - This bridge has no Confirmed or Archived inspections',
};

export const ExportErrorMessageMap = {
  [ExportErrorMessages.MUST_ARCHIVE_OR_CONFIRM_INSPECTION]: `Impossibile effettuare l'esportazione: l'attività non è confermata o non è archiviata`,
  [ExportErrorMessages.NO_CONFIRMED_OR_ARCHIVE_INSPECTIONS]: `Impossibile effettuare l'esportazione: l'attività non è confermata o non è archiviata`,
  [ExportErrorMessages.NO_CONFIRMED_OR_ARCHIVE_INSPECTIONS_]: `Impossibile effettuare l'esportazione: l'attività non è confermata o non è archiviata`,
};
