import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  schemaPonteCommonOptions,
  tipologiaGuardaviaOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import GuardavieForm from './GuardavieForm';
const GuardavieSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const sovrastrutturaImpalcatoTableGuardavieTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable`
  );

  const sovrastrutturaImpalcatoTable = watch(`sovrastrutturaImpalcatoTable`);
  //console.log(sovrastrutturaImpalcatoTable, 'sovrastrutturaImpalcatoTable');

  const mensoleTamponiCampateToCopy = sovrastrutturaImpalcatoTable?.map(
    el => el.guardavieTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsGuardavie = item => {
    setValue('guardavieTableValue', [item]);
    setValue('tipologiaGuardaviaTableValue', item.tipologiaGuardavia);
  };

  const handleSelectAllClickGuardavie = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable`
      );
      setSelected(selectedValue);
      setValue('guardavieTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('guardavieTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t3a2_tecnicoRilevatoreGuardavie',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t3a2_dataIspezioneGuardavie',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t3a2_noteGeneraliGuardavie',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t3a2_tecnicoRilevatoreGuardavie');
    const dataIspezione = getValues('l1t3a2_dataIspezioneGuardavie');

    const noteGenerali = getValues('l1t3a2_noteGeneraliGuardavie');
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
  };

  const handleResetInspection = () => {
    setValue(`l1t3a2_tecnicoRilevatoreGuardavie`, '');
    setValue(`l1t3a2_dataIspezioneGuardavie`, null);
    setValue(`l1t3a2_noteGeneraliGuardavie`, '');
  };

  const dataGuardavieConfig = {
    columns: {
      codice: { label: 'Cod.' },
      presenza: { label: 'Guardavia', format: schemaPonteCommonOptions },
      tipologiaGuardavia: {
        label: 'Tipologia',
        format: tipologiaGuardaviaOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaGuardavia: {
        label: 'Tipologia',
        format: tipologiaGuardaviaOptions,
      },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'guardavie'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoGuardavie.drawerTitle}
        drawerText={INFO.impalcatoGuardavie.drawerText}
        singleElementTitle={'guardavia'}
        tableWatch={sovrastrutturaImpalcatoTableGuardavieTableWatch}
        tableWatchName={`sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable`}
        elementForm={
          <GuardavieForm
            guardavieFields={
              sovrastrutturaImpalcatoTableGuardavieTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            guardavieTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].guardavieTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t3a2_tecnicoRilevatoreGuardavie'}
            dateName={'l1t3a2_dataIspezioneGuardavie'}
            noteGeneraliName={'l1t3a2_noteGeneraliGuardavie'}
          />
        }
        tableConfig={dataGuardavieConfig}
        editRows={editRowsGuardavie}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickGuardavie}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaGuardavia'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
GuardavieSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default GuardavieSection;
