import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiStrutturaliL1SectionWrapper,
  InspectionInfoForm,
} from 'components';
import { CDAOptions, tipologiaAntennaOptions } from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import AntenneForm from './AntenneForm';
const AntenneSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const spallePileCollegamentiTableAntenneTableWatch = watch(
    `spallePileCollegamentiTable[${selectedIdx}].antenneTable`
  );

  const spallePileCollegamentiTable = watch(`spallePileCollegamentiTable`);
  //console.log(spallePileCollegamentiTable, 'spallePileCollegamentiTable');

  const spallePileToCopy = spallePileCollegamentiTable?.map(
    el => el.antenneTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsAntenne = item => {
    setValue('antenneTableValue', [item]);
    setValue('tipologiaAntennaTableValue', item.tipologiaAntenna);
  };

  const handleSelectAllClickAntenne = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable`
      );
      setSelected(selectedValue);
      setValue('antenneTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('antenneTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t2a3_tecnicoRilevatoreAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t2a3_dataIspezioneAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t2a3_elementoCriticoStrutturaAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].elementoCriticoStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_elementoCriticoSismicaAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].elementoCriticoSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaStrutturaAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].condizioneCriticaStruttura`
      ) || ''
    );
    setValue(
      'l1t2a3_condizioneCriticaSismicaAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].condizioneCriticaSismica`
      ) || ''
    );
    setValue(
      'l1t2a3_noteGeneraliAntenne',
      getValues(
        `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues('l1t2a3_tecnicoRilevatoreAntenne');
    const dataIspezione = getValues('l1t2a3_dataIspezioneAntenne');
    const elementoCriticoStruttura = getValues(
      'l1t2a3_elementoCriticoStrutturaAntenne'
    );
    const elementoCriticoSismica = getValues(
      'l1t2a3_elementoCriticoSismicaAntenne'
    );
    const condizioneCriticaStruttura = getValues(
      'l1t2a3_condizioneCriticaStrutturaAntenne'
    );
    const condizioneCriticaSismica = getValues(
      'l1t2a3_condizioneCriticaSismicaAntenne'
    );
    const noteGenerali = getValues('l1t2a3_noteGeneraliAntenne');
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].elementoCriticoStruttura`,
      elementoCriticoStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].elementoCriticoSismica`,
      elementoCriticoSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].condizioneCriticaStruttura`,
      condizioneCriticaStruttura
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].condizioneCriticaSismica`,
      condizioneCriticaSismica
    );
    setValue(
      `spallePileCollegamentiTable[${selectedIdx}].antenneTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
    setValue('l1t2a5_trigger', Math.random() * 1000);
  };

  const handleResetInspection = () => {
    setValue(`l1t2a3_tecnicoRilevatoreAntenne`, '');
    setValue(`l1t2a3_dataIspezioneAntenne`, null);
    setValue(`l1t2a3_elementoCriticoStrutturaAntenne`, '');
    setValue(`l1t2a3_elementoCriticoSismicaAntenne`, '');
    setValue(`l1t2a3_condizioneCriticaStrutturaAntenne`, '');
    setValue(`l1t2a3_condizioneCriticaSismicaAntenne`, '');
    setValue(`l1t2a3_noteGeneraliAntenne`, '');
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  const spallePileCollegamentoSelezione = watch(
    'spallePileCollegamentoSelezione'
  );

  useEffect(() => {
    if (spallePileCollegamentoSelezione) {
      setSchedaDifettiIndex(undefined);
      setOpenSchedaDifetti(false);
    }
  }, [spallePileCollegamentoSelezione]);

  const dataAntenneConfig = {
    columns: {
      codice: { label: 'Cod.' },
      tipologiaAntenna: { label: 'Tipologia', format: tipologiaAntennaOptions },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      indiceCondizione: { label: 'Indice di condizione' },
      CDAstrutturale: { label: 'Difettosità Str.', format: CDAOptions },
      CDAsismica: { label: 'Difettosità Sis.', format: CDAOptions },
      nrtotali: { label: 'NR' },
      g5totali: { label: 'G=5' },
      pstotali: { label: 'PS' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaAntenna: {
        label: 'Tipologia',
        format: tipologiaAntennaOptions,
      },
    },
  };

  return (
    <>
      <ElementiStrutturaliL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'antenne'}
        infoDrawer={true}
        drawerTitle={INFO.collegamentiAntenne.drawerTitle}
        drawerText={INFO.collegamentiAntenne.drawerText}
        singleElementTitle={'antenna'}
        tableWatch={spallePileCollegamentiTableAntenneTableWatch}
        tableWatchName={`spallePileCollegamentiTable[${selectedIdx}].antenneTable`}
        elementForm={
          <AntenneForm
            antenneFields={spallePileCollegamentiTableAntenneTableWatch || []}
            selected={selected}
            setSelected={setSelected}
            antenneTable={`spallePileCollegamentiTable[${selectedIdx}].antenneTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoForm
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t2a3_tecnicoRilevatoreAntenne'}
            dateName={'l1t2a3_dataIspezioneAntenne'}
            elementoCriticoStrutturaName={
              'l1t2a3_elementoCriticoStrutturaAntenne'
            }
            elementoCriticoSismicaName={'l1t2a3_elementoCriticoSismicaAntenne'}
            condizioneCriticaStrutturaName={
              'l1t2a3_condizioneCriticaStrutturaAntenne'
            }
            condizioneCriticaSismicaName={
              'l1t2a3_condizioneCriticaSismicaAntenne'
            }
            noteGeneraliName={'l1t2a3_noteGeneraliAntenne'}
          />
        }
        tableConfig={dataAntenneConfig}
        editRows={editRowsAntenne}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickAntenne}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaAntenna'}
        tableForCopy={spallePileToCopy}
      />
    </>
  );
};
AntenneSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default AntenneSection;
