import { Clear } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DrawerMUI, GridWrap, Table } from 'components';

const ConfirmDialog = ({ message, open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} onConfirm={onConfirm}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Clear />
        </IconButton>
      ) : null}
      <DialogTitle>
        <Typography variant="h1">Attenzione</Typography>
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button variant="contained" onClick={onConfirm}>
          Continua a copiare
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DrawerCopy = ({
  title,
  subtitle,
  open,
  setOpen,
  tableConfig,
  copyTableData,
  pasteTableData,
  selectedCopy,
  selectedPaste,
  handleSelectAll,
  setSelectedPaste,
  checkCompatibility,
  handleCopy,
}) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  //console.log(copyTableData, '-- copyTableData');
  return (
    <>
      <DrawerMUI
        title={title}
        subtitle={subtitle}
        open={open}
        setOpen={setOpen}
      >
        <GridWrap container>
          <GridWrap item xs={12}>
            <Table
              data={copyTableData}
              config={tableConfig}
              selected={[selectedCopy]}
              hasCheckbox={false}
              compatibilityActions={[
                {
                  compatibilityHeader: true,
                },
              ]}
            />
          </GridWrap>
          <GridWrap item xs={12}>
            <Table
              data={pasteTableData}
              config={tableConfig}
              hasCheckbox={true}
              selected={selectedPaste}
              onSelectAllClick={handleSelectAll}
              setSelected={setSelectedPaste}
              compatibilityActions={[
                {
                  checkCompatibility: row => checkCompatibility(row),
                },
              ]}
            />
          </GridWrap>
          <GridWrap item xs={12} textAlign="right">
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: 5 }}
              onClick={() => setOpen(false)}
            >
              Annulla
            </Button>
            <Button
              variant={'containedBlack'}
              //onClick={handleCopy}
              onClick={() => setOpenConfirmationDialog(true)}
              disabled={selectedPaste.length == 0}
            >
              Copia
            </Button>
          </GridWrap>
        </GridWrap>
      </DrawerMUI>
      <ConfirmDialog
        message={subtitle}
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={() => {
          handleCopy();
          setOpenConfirmationDialog(false);
        }}
      />
    </>
  );
};
DrawerCopy.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  tableConfig: PropTypes.object,
  copyTableData: PropTypes.array,
  pasteTableData: PropTypes.array,
  selectedCopy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  selectedPaste: PropTypes.array,
  handleSelectAll: PropTypes.func,
  setSelectedPaste: PropTypes.func,
  checkCompatibility: PropTypes.func,
  handleCopy: PropTypes.func,
};

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
export { DrawerCopy };
