import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ElementiAccessoriL1SectionWrapper,
  InspectionInfoScheda20Form,
} from 'components';
import {
  schemaPontePavimentazioneOptions,
  tipologiaPavimentazioneOptions,
} from 'constants/selectOptions';
import { INFO } from 'pages/Inspections/config/info';
import PavimentazioneForm from './PavimentazioneForm';
const PavimentazioneSection = ({ selectedIdx }) => {
  const { watch, setValue, getValues } = useFormContext();

  const sovrastrutturaImpalcatoTablePavimentazioneTableWatch = watch(
    `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`
  );

  const sovrastrutturaImpalcatoTable = watch(`sovrastrutturaImpalcatoTable`);
  //  console.log(sovrastrutturaImpalcatoTable, 'sovrastrutturaImpalcatoTable');

  const mensoleTamponiCampateToCopy = sovrastrutturaImpalcatoTable?.map(
    el => el.pavimentazioneTable
  );

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerMediaIndex, setDrawerMediaIndex] = useState();
  const [openSchedaDifetti, setOpenSchedaDifetti] = useState(false);
  const [schedaDifettiIndex, setSchedaDifettiIndex] = useState();

  const editRowsPavimentazione = item => {
    setValue('pavimentazioneTableValue', [item]);
    setValue('tipologiaPavimentazioneTableValue', item.tipologiaPavimentazione);
  };

  const handleSelectAllClickPavimentazione = event => {
    if (event.target.checked) {
      const selectedValue = getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`
      );
      setSelected(selectedValue);
      setValue('pavimentazioneTableValue', selectedValue);
    } else {
      setSelected([]);
      setValue('pavimentazioneTableValue', []);
    }
  };

  useEffect(() => {
    setValue(
      'l1t3a2_tecnicoRilevatorePavimentazione',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].tecnicoRilevatore`
      ) ||
        getValues('rilevatoreRischioStrutturale') ||
        ''
    );
    setValue(
      'l1t3a2_dataIspezionePavimentazione',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].dataIspezione`
      ) ||
        getValues('dataIspezioneRischioStrutturale') ||
        null
    );
    setValue(
      'l1t3a2_noteGeneraliPavimentazione',
      getValues(
        `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].noteGenerali`
      ) || ''
    );
  }, [schedaDifettiIndex]);

  const handleSaveInspection = () => {
    const tecnicoRilevatore = getValues(
      'l1t3a2_tecnicoRilevatorePavimentazione'
    );
    const dataIspezione = getValues('l1t3a2_dataIspezionePavimentazione');

    const noteGenerali = getValues('l1t3a2_noteGeneraliPavimentazione');
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].tecnicoRilevatore`,
      tecnicoRilevatore
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].dataIspezione`,
      dataIspezione
    );
    setValue(
      `sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable[${schedaDifettiIndex}].noteGenerali`,
      noteGenerali
    );
  };

  const handleResetInspection = () => {
    setValue(`l1t3a2_tecnicoRilevatorePavimentazione`, '');
    setValue(`l1t3a2_dataIspezionePavimentazione`, null);
    setValue(`l1t3a2_noteGeneraliPavimentazione`, '');
  };

  const dataPavimentazioneConfig = {
    columns: {
      codice: { label: 'Cod.' },
      presenza: {
        label: 'Pavimentazione',
        format: schemaPontePavimentazioneOptions,
      },
      tipologiaPavimentazione: {
        label: 'Tipologia',
        format: tipologiaPavimentazioneOptions,
      },
      completamentoSchedaDifetti: { label: 'Completamento Scheda' },
      nrtotali: { label: 'NR' },
    },
  };

  const copyTableConfig = {
    columns: {
      codice: { label: 'Codice' },
      tipologiaPavimentazione: {
        label: 'Tipologia',
        format: tipologiaPavimentazioneOptions,
      },
    },
  };

  useEffect(() => {
    if (schedaDifettiIndex !== undefined) {
      setOpenSchedaDifetti(true);
    }
  }, [schedaDifettiIndex]);

  return (
    <>
      <ElementiAccessoriL1SectionWrapper
        selectedIdx={selectedIdx}
        elementTitle={'pavimentazione'}
        infoDrawer={true}
        drawerTitle={INFO.impalcatoPavimento.drawerTitle}
        drawerText={INFO.impalcatoPavimento.drawerText}
        singleElementTitle={'pavimentazione'}
        tableWatch={sovrastrutturaImpalcatoTablePavimentazioneTableWatch}
        tableWatchName={`sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`}
        elementForm={
          <PavimentazioneForm
            pavimentazioneFields={
              sovrastrutturaImpalcatoTablePavimentazioneTableWatch || []
            }
            selected={selected}
            setSelected={setSelected}
            pavimentazioneTable={`sovrastrutturaImpalcatoTable[${selectedIdx}].pavimentazioneTable`}
            selectedIdx={selectedIdx}
          />
        }
        inspectionInfoForm={
          <InspectionInfoScheda20Form
            onSubmit={handleSaveInspection}
            onReset={handleResetInspection}
            tecnicoRilevatoreName={'l1t3a2_tecnicoRilevatorePavimentazione'}
            dateName={'l1t3a2_dataIspezionePavimentazione'}
            noteGeneraliName={'l1t3a2_noteGeneraliPavimentazione'}
          />
        }
        tableConfig={dataPavimentazioneConfig}
        editRows={editRowsPavimentazione}
        selected={selected}
        setSelected={setSelected}
        handleSelectAllClick={handleSelectAllClickPavimentazione}
        drawerMediaIndex={drawerMediaIndex}
        setDrawerMediaIndex={setDrawerMediaIndex}
        schedaDifettiIndex={schedaDifettiIndex}
        setSchedaDifettiIndex={setSchedaDifettiIndex}
        open={open}
        setOpen={setOpen}
        openSchedaDifetti={openSchedaDifetti}
        setOpenSchedaDifetti={setOpenSchedaDifetti}
        copyTableConfig={copyTableConfig}
        copyType={'tipologiaPavimentazione'}
        tableForCopy={mensoleTamponiCampateToCopy}
      />
    </>
  );
};
PavimentazioneSection.propTypes = {
  selectedIdx: PropTypes.number,
};
export default PavimentazioneSection;
